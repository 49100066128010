import { useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import { getLogger } from 'loglevel';
import type { UndefinedInitialDataOptions } from '@tanstack/react-query';
import type { AnyRouter, RegisteredRouter } from '@tanstack/react-router';

import { REMIND_ME_LATER_LOCAL_STORAGE_KEY } from '@apple/features/announcements/consts';
import { healthQueryOptions } from '@apple/features/health/queries';

import * as AuthService from '../api/authentication';
import { xsrfQueryOptions } from '../queries/xsrf';
import * as AuthUtils from '../utils/requirements';
import type { RoleName } from '../models/roles';
import type { ChangePasswordRequest, Permission, ProfileDto } from '../models/user';
import type { AuthRequirements } from '../utils/requirements';

const log = getLogger('auth');

export function useAuth<TProfile extends ProfileDto, TRouter extends AnyRouter = RegisteredRouter>({
	router,
	profileQueryOptions,
}: {
	profileQueryOptions: (
		enable?: boolean,
	) => UndefinedInitialDataOptions<TProfile | null, Error, TProfile | null, string[]>;
	router: TRouter;
}) {
	const client = useQueryClient();
	const [token, health] = useQueries({
		queries: [xsrfQueryOptions(), healthQueryOptions()],
	});
	const profileQuery = useQuery(
		profileQueryOptions(!token.isLoading && !token.isError && !health.isError),
	);

	async function login(
		username: string,
		password: string,
		rememberMe: boolean,
		returnUrl: string,
	) {
		log.debug('Logging in', { username, returnUrl });
		const response = await AuthService.login({
			userName: username,
			password,
			rememberMe,
			returnUrl,
			termsAndConditionsContentId: 0,
		});

		if (!response.result) {
			log.error('Login failed:', { response });
			return;
		}

		localStorage.removeItem(REMIND_ME_LATER_LOCAL_STORAGE_KEY);

		// Retrieve xsrf token for new user
		await token.refetch();
		const profileResponse = await profileQuery.refetch();

		if (profileResponse.isError) {
			log.error('Loading profile failed:', { profileResponse });
			return;
		}

		log.debug('Login successful. Invalidating query caches and fetching profile');
		await client.invalidateQueries();

		// Update the profile query since we invalidated the caches
		client.setQueryData(profileQueryOptions().queryKey, profileResponse.data, {
			updatedAt: Date.now(),
		});

		// Invalidate the router cache after setting the profile data to ensure that the new data is used
		await router.invalidate();

		// Calling `router.navigate` seems to be working fine, however the
		// docs say that `router.history.push` is better suited. Might need
		// to revisit this in the future.
		// https://tanstack.com/router/latest/docs/framework/react/guide/authenticated-routes#redirecting
		await router.navigate({
			to: returnUrl,
		});
	}

	async function logout() {
		log.debug('Logging out');
		await AuthService.logout();
		await client.resetQueries();
		// Get xsrf token for anonymous user
		await token.refetch();
	}

	async function reset() {
		log.debug('Resetting profile query.');
		await client.resetQueries({ queryKey: profileQueryOptions(true).queryKey });
	}

	async function changePassword(request: ChangePasswordRequest) {
		await AuthService.changePassword(request);
	}

	function filterByPermissions<T>(items: AuthRequirements<T>[]) {
		return AuthUtils.filterByPermissions<T>(profileQuery.data, items);
	}

	function hasRole(role: RoleName) {
		return hasRoles([role]);
	}

	function hasRoles(roles: RoleName[]) {
		return AuthUtils.hasRoles(profileQuery.data, roles);
	}

	function hasPermission(permission: Permission) {
		return hasPermissions([permission]);
	}

	function hasPermissions(permissions: Permission[]) {
		return AuthUtils.hasPermissions(profileQuery.data, permissions);
	}

	return {
		loading: token.isLoading || health.isLoading || profileQuery.isLoading,
		error: health.isError || token.isError,
		authenticated: !!profileQuery.data,
		profile: profileQuery.data ?? null,
		login,
		logout,
		reset,
		resetPassword: AuthService.resetPassword,
		changePassword,
		hasRole,
		hasRoles,
		hasPermission,
		hasPermissions,
		filterByPermissions,
	};
}
