import { isAxiosError } from 'axios';
import { getLogger } from 'loglevel';

import {
	isApiErrorResponse,
	isGenericErrorResponse,
	NotAuthenticatedError,
	NotAuthorizedError,
	ServerError,
	ServerValidationError,
} from '@apple/utils/api';
import type { ApiErrorResponse } from '@apple/utils/api';

import { isAdditionalDataErrorResponse, isModelStateErrorResponse } from './models';
import type { FieldErrors, FormErrors } from './models';

const log = getLogger('validation:server');

/**
 * @see Manzanita.Web\Infrastructure\Http\ApiControllerExtensions.cs
 * @see Arvato.eCoSystem.Web\Http\Extensions\ControllerExtensions.cs
 * @remark Known Error Responses:
 * ```
 * 400: Bad Request:                additionalData = undefined | ModelValidationResponse
 * 404: Not Found:                  additionalData = undefined | ApiErrorResponse<FieldErrors>
 * 406: Not Acceptable:             additionalData = undefined | ModelValidationResponse
 * 409: Conflict:                   additionalData = undefined | ApiErrorResponse<FieldErrors>
 * 410: Gone:                       additionalData = undefined
 * 417: Expectation Failed:         additionalData = undefined | ApiErrorResponse<FieldErrors>
 * 422: Unprocessable Entity:       additionalData = undefined | ModelValidationResponse
 * 500: Internal Server Error:      additionalData = undefined | ApiErrorResponse<ErrorMessages>
 * ```
 */
export function parseServerError<T>(error: unknown): ServerError | ServerValidationError<T> {
	if (error instanceof ServerError || error instanceof ServerValidationError) {
		return error;
	}

	if (!(isAxiosError<unknown, unknown>(error) && error.response)) {
		log.error('An unknown error occurred while making the request:', error);
		return new ServerError('An unknown error occurred while making the request.', {
			innerError: error,
		});
	}

	if (error.response.status === 401) {
		log.warn('User is not authenticated (401)', error);
		return new NotAuthenticatedError(error);
	}

	if (error.response.status === 403) {
		log.warn('User is not authorized (403)', error);
		return new NotAuthorizedError(error);
	}

	if (typeof error.response.data === 'string') {
		// For plain text responses
		return new ServerError(error.response.data, error);
	}

	if (isModelStateErrorResponse(error.response.data)) {
		return new ServerValidationError(error, {
			// see tag uris: https://datatracker.ietf.org/doc/html/rfc4151
			type: 'tag:apple.arvato.com:validation',
			title: 'Validation Error',
			detail: 'The request was invalid.',
			status: error.response.status,
			instance: error.response.config.url,
			formErrors: undefined,
			fieldErrors: error.response.data.modelState,
		});
	}

	if (isAdditionalDataErrorResponse(error.response.data)) {
		return new ServerValidationError(error, {
			// see tag uris: https://datatracker.ietf.org/doc/html/rfc4151
			type: 'tag:apple.arvato.com:validation',
			title: 'Validation Error',
			detail: 'The request was invalid.',
			status: error.response.status,
			instance: error.response.config.url,
			formErrors: undefined,
			fieldErrors: error.response.data.additionalData,
		});
	}

	if (isApiErrorResponse(error.response.data)) {
		const apiErrorResponse = error.response.data as ApiErrorResponse<
			FormErrors | FieldErrors<T>
		>;

		return new ServerValidationError(error, {
			// see tag uris: https://datatracker.ietf.org/doc/html/rfc4151
			type: 'tag:apple.arvato.com:validation',
			title: apiErrorResponse.titleKey,
			detail: apiErrorResponse.contentKey,
			status: error.response.status,
			instance: error.response.config.url,
			formErrors: Array.isArray(apiErrorResponse.additionalData)
				? apiErrorResponse.additionalData
				: undefined,
			fieldErrors: !Array.isArray(apiErrorResponse.additionalData)
				? (apiErrorResponse.additionalData ?? undefined)
				: undefined,
		});
	}

	if (isGenericErrorResponse(error.response.data)) {
		return new ServerError(error.response.data.message, error);
	}

	return new ServerError('An unknown error occurred.', error);
}
