import { Stack } from '@mantine/core';
import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { requireAuth } from '@apple/features/auth';
import { getLocationById } from '@apple/features/report/location-feed/api/management';
import { LocationDetail } from '@apple/features/report/location-feed/components/LocationDetail';
import { useTranslation } from '@apple/lib/i18next';
import { DetailsLayout } from '@apple/ui/layouts';

export const Route = createFileRoute('/_authed/_admin/reports/location-feed_/$locationId')({
	parseParams: ({ locationId }) => ({
		locationId: z.string().parse(locationId),
	}),
	beforeLoad: ({ params: { locationId }, ...args }) => {
		requireAuth(args, {
			requirePermission:
				'AppleBrandedPrograms.Security.Features.AppleIdFeedLocationsManagement.Read',
		});

		return {
			locationQueryOptions: queryOptions({
				queryKey: ['location-feed', locationId],
				queryFn: () => getLocationById(locationId),
				staleTime: 5 * 60 * 1000, // 5 minutes
			}),
		};
	},
	loader: ({ context: { queryClient, locationQueryOptions } }) => {
		void queryClient.prefetchQuery(locationQueryOptions);
	},
	wrapInSuspense: true,
	component: LocationRoute,
});

function LocationRoute() {
	const { t } = useTranslation('reports');
	const { locationQueryOptions } = Route.useRouteContext();
	const locationQuery = useSuspenseQuery(locationQueryOptions);

	return (
		<DetailsLayout title={t('locationFeed.details')}>
			<Stack>
				<LocationDetail location={locationQuery.data} />
			</Stack>
		</DetailsLayout>
	);
}
