import { Container } from '@mantine/core';
import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { ConfirmationStep } from '@apple/channel/routes/_authed/-steps/ConfirmationStep';
import { cartQueryKeys } from '@apple/features/cart-channel/queries';

const schema = z.object({
	cartId: z.string(),
});

export const Route = createFileRoute('/_authed/cart/$cartId/confirmation')({
	params: {
		parse: x => schema.parse(x),
		stringify: ({ cartId }) => ({ cartId }),
	},
	beforeLoad: args => {
		return {
			cartId: args.params.cartId,
		};
	},
	loader: async args => {
		await args.context.queryClient.invalidateQueries({
			queryKey: cartQueryKeys.cartId,
			exact: true,
		});
	},
	component: ConfirmationRoute,
});

function ConfirmationRoute() {
	const { cartId } = Route.useRouteContext();

	return (
		<Container size='xl' p='md' mt={'var(--app-shell-header-height)'}>
			<ConfirmationStep cartId={cartId} />
		</Container>
	);
}
