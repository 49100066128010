import { useCallback } from 'react';
import { notifications } from '@mantine/notifications';
import { queryOptions, useMutation, useSuspenseQueries } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { getLogger } from 'loglevel';
import { z } from 'zod';

import { ErrorIcon, Success } from '@apple/assets/icons';
import { requireAuth } from '@apple/features/auth';
import {
	deleteUser,
	getUser,
	saveUser,
	unlockUser,
} from '@apple/features/user-channel/api/management';
import { UserForm } from '@apple/features/user-channel/components/UserForm';
import { useTranslation } from '@apple/lib/i18next';
import { DetailsLayout } from '@apple/ui/layouts';
import { ServerValidationError } from '@apple/utils/api';

const log = getLogger('manage-user');

export const Route = createFileRoute('/_authed/_admin/manage/users_/$userName')({
	parseParams: ({ userName }) => ({
		userName: z.string().parse(userName),
	}),
	beforeLoad: ({ params: { userName }, ...args }) => {
		requireAuth(args, {
			requirePermission: 'Manzanita.Security.Features.UserManagement.Write',
		});
		return {
			userQueryOptions: queryOptions({
				queryKey: ['user', userName],
				queryFn: () => getUser(userName),
				staleTime: 5 * 60 * 1000, // 5 minutes
			}),
		};
	},
	loader: ({ context: { queryClient, userQueryOptions } }) => {
		void queryClient.prefetchQuery(userQueryOptions);
	},
	wrapInSuspense: true,
	component: EditUserRoute,
});

function EditUserRoute() {
	const { t } = useTranslation('user');
	const { userQueryOptions, queryClient } = Route.useRouteContext();

	const [userQuery] = useSuspenseQueries({
		queries: [userQueryOptions],
	});

	const navigate = Route.useNavigate();

	const onSuccessfulChange = useCallback(
		async (message: string) => {
			await queryClient.invalidateQueries({ queryKey: ['users-odata'] });
			await queryClient.invalidateQueries({
				queryKey: ['user', userQuery.data.username],
			});
			notifications.show({
				message: message,
				icon: <Success />,
				color: 'green',
				autoClose: 5000,
			});
			await navigate({
				to: '/manage/users',
			});
		},
		[navigate, queryClient, userQuery.data.username],
	);

	const onFailedChange = useCallback(
		(error: Error) => {
			if (!(error instanceof ServerValidationError)) {
				log.error(error);
				notifications.show({
					message: t('common:error.generic'),
					icon: <ErrorIcon />,
					color: 'red',
					autoClose: 5000,
				});
			}
		},
		[t],
	);

	const updateUserMutation = useMutation({
		mutationFn: saveUser,
		onSuccess: async () => {
			await onSuccessfulChange(t('notifications.updateSuccess'));
		},
		onError: onFailedChange,
	});

	const deleteUserMutation = useMutation({
		mutationFn: deleteUser,
		onSuccess: async () => {
			await onSuccessfulChange(t('notifications.deleteSuccess'));
		},
		onError: onFailedChange,
	});

	const unlockUserMutation = useMutation({
		mutationFn: unlockUser,
		onSuccess: async () => {
			await onSuccessfulChange(t('notifications.unlockSuccess'));
		},
		onError: onFailedChange,
	});

	return (
		<DetailsLayout title={t('editUser.title', { userName: userQuery.data.username })}>
			<UserForm
				isEdit
				saveUserMutation={updateUserMutation}
				deleteUserMutation={deleteUserMutation}
				unlockUserMutation={unlockUserMutation}
				user={userQuery.data}
			/>
		</DetailsLayout>
	);
}
