import { notifications } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { getLogger } from 'loglevel';

import { ErrorIcon, Success } from '@apple/assets/icons';
import { requireAuth } from '@apple/features/auth';
import { addMerchandiser, MerchandiserDetails } from '@apple/features/merchandiser';
import { useTranslation } from '@apple/lib/i18next';
import { DetailsLayout } from '@apple/ui/layouts';
import { NotFoundPage } from '@apple/ui/shell/pages/NotFoundPage';
import { NotAuthorizedError, ServerValidationError } from '@apple/utils/api';
import type { Merchandiser } from '@apple/features/merchandiser';

const log = getLogger('manage-merchandiser');

export const Route = createFileRoute('/_authed/_admin/manage/merchandisers_/add')({
	component: MerchandiserRoute,
	beforeLoad: args => {
		requireAuth(args, {
			requirePermissions: [
				'AppleBrandedPrograms.Security.Features.AppleMerchandisersManagement.Read',
				'AppleBrandedPrograms.Security.Features.AppleMerchandisersManagement.Write',
			],
		});
	},
	errorComponent: ({ error }) => {
		if (error instanceof NotAuthorizedError) {
			return <NotFoundPage />;
		} else {
			throw error;
		}
	},
});

function MerchandiserRoute() {
	const navigate = Route.useNavigate();
	const { t } = useTranslation('merchandiser');
	const { queryClient } = Route.useRouteContext();

	async function handleSuccessfulChange(title: string, message: string) {
		await queryClient.invalidateQueries({ queryKey: ['merchandisers-odata'] });

		notifications.show({
			title: title,
			message: message,
			icon: <Success />,
			color: 'green',
			autoClose: 5000,
		});

		await navigate({ to: '/manage/merchandisers' });
	}

	function showErrorNotification(error: Error) {
		if (!(error instanceof ServerValidationError)) {
			log.error(error);
			notifications.show({
				message: t('common:error.generic'),
				icon: <ErrorIcon />,
				color: 'red',
				autoClose: 5000,
			});
		}
	}

	const addMerchandiserMutation = useMutation({
		mutationFn: async (merchandiser: Merchandiser) => await addMerchandiser(merchandiser),
		onSuccess: async () => {
			await handleSuccessfulChange(
				t('notifications.successTitle'),
				t('notifications.createSuccess'),
			);
		},
		onError: showErrorNotification,
	});

	return (
		<DetailsLayout title={t('title.add')}>
			<MerchandiserDetails onSave={addMerchandiserMutation.mutateAsync} />
		</DetailsLayout>
	);
}
