import { keepPreviousData, queryOptions, useQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import {
	getCoreRowModel,
	getFacetedMinMaxValues,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table';
import type { SortingState } from '@tanstack/react-table';

import { requireAuth, useUserRoles } from '@apple/features/auth';
import { getLocationApprovalQueue, locationApprovalQueryKeys } from '@apple/features/location';
import { useTranslation } from '@apple/lib/i18next';
import { DataTable, useTableState } from '@apple/ui/data-table';
import { TableLayout } from '@apple/ui/layouts';
import { Link } from '@apple/ui/link';
import { FormattedDate } from '@apple/utils/globalization';
import type { LocationApprovalFilters, LocationApprovalRequest } from '@apple/features/location';

const defaultFilters: LocationApprovalFilters = {};
const defaultSorting: SortingState = [{ id: 'date', desc: true }];

export const Route = createFileRoute('/_authed/_admin/approvals/location')({
	component: LocationApprovals,
	beforeLoad: args => {
		requireAuth(args, {
			requirePermission: 'AppleBrandedPrograms.Security.Features.LocationApprovalManagement',
		});

		return {
			approvalLocationsQueryOptions: queryOptions({
				queryKey: locationApprovalQueryKeys.queue,
				queryFn: () => getLocationApprovalQueue(),
				placeholderData: keepPreviousData,
				throwOnError: true,
				initialData: [],
			}),
		};
	},
	loader: ({ context: { queryClient, approvalLocationsQueryOptions } }) => {
		void queryClient.prefetchQuery(approvalLocationsQueryOptions);
	},
});

function LocationApprovals() {
	const { t } = useTranslation('location-approval', {
		nsMode: 'fallback',
	});
	const { approvalLocationsQueryOptions } = Route.useRouteContext();

	const locationsQuery = useQuery(approvalLocationsQueryOptions);
	const userRoles = useUserRoles();
	const search = Route.useSearch();
	const navigate = Route.useNavigate();

	const tableState = useTableState<LocationApprovalRequest, LocationApprovalFilters>({
		search,
		navigate,
		defaultFilters: defaultFilters,
		defaultSorting: defaultSorting,
		fieldMap: [],
	});

	const table = useReactTable<LocationApprovalRequest>({
		data: locationsQuery.data,
		enableFilters: true,
		...tableState,
		enableColumnFilters: true,
		enableSorting: true,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: (table, columnId) => {
			switch (columnId) {
				case 'role':
					return () =>
						new Map(
							!userRoles.loading && !userRoles.error
								? userRoles.roles?.map(x => [x.name, 1])
								: [],
						);
				default:
					return getFacetedUniqueValues<LocationApprovalRequest>()(table, columnId);
			}
		},
		getFacetedMinMaxValues: getFacetedMinMaxValues(),
		columnResizeMode: 'onChange',
		columns: [
			{
				enableColumnFilter: false,
				enableGlobalFilter: false,
				accessorKey: 'userId',
				header: t('headers.requests'),
				cell: ({ row }) => (
					<Link
						to='/approvals/location/$userId'
						params={{ userId: row?.original.userId.toString() }}
						title={t('table.viewRequest')}
						children={t('table.viewRequest')}
					/>
				),
			},
			{
				accessorKey: 'username',
				header: t('headers.username'),
				filter: {
					group: t('filters.labels.locationInfo'),
					variant: 'text',
				},
			},
			{
				enableColumnFilter: false,
				enableGlobalFilter: false,
				accessorKey: 'firstName',
				header: t('headers.firstName'),
			},
			{
				enableColumnFilter: false,
				enableGlobalFilter: false,
				accessorKey: 'lastName',
				header: t('headers.lastName'),
			},
			{
				id: 'role',
				accessorKey: 'role',
				header: t('headers.role'),
				filter: {
					group: t('filters.labels.locationInfo'),
					variant: 'select',
				},
			},
			{
				enableColumnFilter: false,
				enableGlobalFilter: false,
				accessorKey: 'requests',
				header: t('headers.locations'),
			},
			{
				accessorKey: 'date',
				header: t('headers.date'),
				enableSorting: true,
				sortingFn: 'datetime',
				filter: {
					group: t('filters.labels.locationInfo'),
					variant: 'date-range',
				},
				cell: ({ row }) => <FormattedDate value={row.original.date} humanize />,
			},
		],
	});

	return (
		<TableLayout title={t('title.list')} table={table}>
			<DataTable table={table} loading={locationsQuery.isFetching} />
		</TableLayout>
	);
}
