import { memo } from 'react';
import { Box, rem, Stack, Text, useMantineTheme } from '@mantine/core';
import type { MantineBreakpoint } from '@mantine/core';
import type { ToOptions } from '@tanstack/react-router';
import type { PropsWithChildren } from 'react';

import { NavLink } from '@apple/ui/link';
import type { IconElement } from '@apple/assets/icons';
import type { MenuItemGroup } from '@apple/ui/layouts/components/menu-item';

type MenuSize = 'sm' | 'md' | 'lg';

export const SidebarMenu = memo(
	({ size = 'md', menuItems = [] }: { size?: MenuSize; menuItems: MenuItemGroup[] }) => {
		return (
			<Stack gap={rem(16)} align='stretch' justify='flex-start'>
				{menuItems.map((group, groupIndex) =>
					group.items.length <= 0 ? null : (
						<SidebarMenuSection key={groupIndex} title={group.label}>
							{group.items
								.filter(x => !x.hidden)
								.map(({ icon, label, disabled, ...toProps }, linkIndex) => (
									<SidebarMenuLink
										key={linkIndex}
										size={size}
										icon={icon}
										label={label}
										disabled={disabled}
										{...toProps}
									/>
								))}
						</SidebarMenuSection>
					),
				)}
			</Stack>
		);
	},
);

SidebarMenu.displayName = 'SidebarMenu';

const SidebarMenuSection = memo(
	({
		title,
		children,
		...props
	}: PropsWithChildren<{
		title?: string;
		visibleFrom?: MantineBreakpoint;
		hiddenFrom?: MantineBreakpoint;
	}>) => {
		return (
			<Stack gap={0} {...props}>
				<Text size='sm' fw={700} c='dimmed' pl='sm' hidden={!title}>
					{title}
				</Text>
				<Box>{children}</Box>
			</Stack>
		);
	},
);

SidebarMenuSection.displayName = 'SidebarMenuSection';

type SidebarMenuLinkProps = {
	size: MenuSize;
	label: string;
	icon: IconElement;
	disabled?: boolean;
} & ToOptions;

const SidebarMenuLink = memo(
	({ size, label, icon: IconComponent, disabled, ...toProps }: SidebarMenuLinkProps) => {
		const {
			other: { primarySidebar, controlAccentColor },
		} = useMantineTheme();

		return (
			<NavLink
				{...toProps}
				component='a'
				label={label}
				variant='filled'
				defaultOpened={true}
				style={{ borderRadius: rem(6) }}
				fz={primarySidebar.textSize[size]}
				fw='500'
				h={primarySidebar.rowHeight[size]}
				preload={false}
				disabled={disabled}
				leftSection={<IconComponent c={controlAccentColor} />}
				activeProps={{
					leftSection: <IconComponent c='var(--mantine-primary-color-contrast)' />,
				}}
			/>
		);
	},
);

SidebarMenuLink.displayName = 'SidebarMenuLink';
