import { Text } from '@mantine/core';
import type { MantineStyleProps, TextProps } from '@mantine/core';
import dayjs from 'dayjs';
import { memo } from 'react';

type FormattedDateProps = MantineStyleProps & {
	value: dayjs.ConfigType | null;
	timezone?: string;
	humanize?: boolean;
	humanizeWithHoursAndMinutes?: boolean;
	showTime?: boolean;
	showTimezone?: boolean;
	span?: boolean;
	humanizeLimitInDays?: number;
	size?: TextProps['size'];
	format?: string;
};

export const FormattedDate = memo(_FormattedDate);
FormattedDate.displayName = 'FormattedDate';

function _FormattedDate({
	value,
	timezone,
	humanize = false,
	humanizeWithHoursAndMinutes = false,
	showTime = false,
	showTimezone = false,
	span = false,
	humanizeLimitInDays = 7,
	format,
	...textProps
}: FormattedDateProps) {
	if (!value) {
		return (
			<Text {...textProps} span={span}>
				{'--'}
			</Text>
		);
	}

	let date = dayjs(value);
	if (!date.isValid()) {
		return (
			<Text {...textProps} span={span}>
				Invalid date
			</Text>
		);
	}

	if (timezone) {
		date = date.tz(timezone);
	}

	const currentDate = dayjs();
	const diffInDays = date.diff(currentDate, 'days');

	if (humanizeWithHoursAndMinutes && Math.abs(diffInDays) < humanizeLimitInDays) {
		const diffInHours = date.diff(currentDate, 'hours');
		const diffInMinutes = date.diff(currentDate, 'minutes');
		const isInTheFuture = diffInMinutes >= 0;

		const daysLeft = dayjs.duration(diffInDays, 'days');
		const remainingHours = diffInHours % 24;
		const hoursLeft = dayjs.duration(remainingHours, 'hours');

		// If the difference is less than 1 day, show hours and minutes
		// Suppress hours if the remainder is 0 because it will show "in a second"
		if (diffInDays === 0) {
			const remainingMinutes = diffInMinutes % 60;
			const minutesLeft = dayjs.duration(remainingMinutes, 'minutes');

			let timeRemaining =
				remainingHours !== 0
					? hoursLeft.humanize(remainingMinutes === 0 || isInTheFuture)
					: '';
			timeRemaining =
				remainingMinutes !== 0
					? `${timeRemaining}${remainingHours !== 0 ? ', ' : ''}${minutesLeft.humanize(remainingHours === 0 || !isInTheFuture)}`
					: timeRemaining;
			return (
				<Text title={date.toISOString()} {...textProps} span={span}>
					{timeRemaining}
				</Text>
			);
		}

		// If the difference is less than 2 days, show days and hours, otherwise show days
		// Suppress hours if the remainder is 0 because it will show "in a second"
		let timeRemaining = daysLeft.humanize(remainingHours === 0 || isInTheFuture);
		timeRemaining =
			remainingHours !== 0
				? `${timeRemaining}${Math.abs(diffInDays) === 1 ? `, ${hoursLeft.humanize(!isInTheFuture)}` : ''}`
				: timeRemaining;
		return (
			<Text title={date.toISOString()} {...textProps} span={span}>
				{timeRemaining}
			</Text>
		);
	}

	if (humanize && Math.abs(diffInDays) < humanizeLimitInDays) {
		return (
			<Text title={date.toISOString()} {...textProps} span={span}>
				{date.fromNow()}
			</Text>
		);
	}

	const finalFormat = format || (showTime ? 'lll' : 'll');

	return (
		<Text title={date.toISOString()} {...textProps} span={span}>
			{date.format(finalFormat)}
			{showTimezone && ` ${date.format('z')}`}
		</Text>
	);
}
