import { createFileRoute } from '@tanstack/react-router';

import { requireAuth } from '@apple/features/auth';
import { NotImplementedPage } from '@apple/ui/shell/pages/NotImplementedPage';

export const Route = createFileRoute('/_authed/_admin/manage/locations')({
	beforeLoad: args =>
		requireAuth(args, {
			requirePermission:
				'AppleBrandedPrograms.Security.Features.AppleIdFeedLocationsManagement.Read',
		}),
	component: LocationsManagementRoute,
});

function LocationsManagementRoute() {
	return <NotImplementedPage name='Locations Management' />;
}
