import { createFileRoute } from '@tanstack/react-router';

import { AddIcon } from '@apple/assets/icons';
import { requireAuth } from '@apple/features/auth';
import { useCurrencyConversionListingTable } from '@apple/features/currencies/hooks/useCurrencyConversionListingTable';
import { useTranslation } from '@apple/lib/i18next';
import { DataTable } from '@apple/ui/data-table';
import { ToolbarButton } from '@apple/ui/data-table/controls/Toolbar';
import { TableLayout } from '@apple/ui/layouts';

export const Route = createFileRoute('/_authed/_admin/manage/currencies')({
	beforeLoad: args =>
		requireAuth(args, {
			requirePermission: 'Manzanita.Security.Features.CurrencyConversionManagement.Read',
		}),
	component: CurrencyManagementRoute,
});

function CurrencyManagementRoute() {
	const { t } = useTranslation('manage');
	const search = Route.useSearch();
	const navigate = Route.useNavigate();

	const { table, currencyConversionListingQuery } = useCurrencyConversionListingTable({
		search,
		navigate,
	});
	return (
		<TableLayout
			title={t('currencies.title')}
			table={table}
			toolbarButtons={[
				<ToolbarButton
					key='add'
					tooltip={t('currencies.buttons.add.tooltip')}
					icon={AddIcon}
					onClick={() =>
						void navigate({
							to: '/manage/currencies/add',
							viewTransition: false,
						})
					}
				/>,
			]}
		>
			<DataTable table={table} loading={currencyConversionListingQuery.isFetching} />
		</TableLayout>
	);
}
