import { createFileRoute } from '@tanstack/react-router';

import { ForgotPasswordPage } from '@apple/features/auth';
import { getEnv } from '@apple/utils/config/env';

const { APPLE_APP_TITLE } = getEnv();

export const Route = createFileRoute('/(public)/forgot-password')({
	component: ForgotPasswordRoute,
});

function ForgotPasswordRoute() {
	return <ForgotPasswordPage appName={APPLE_APP_TITLE} />;
}
