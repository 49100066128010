import { queryOptions, useSuspenseQueries } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';

import { requireAuth } from '@apple/features/auth';
import { getEmail } from '@apple/features/report/email/api/management';
import { EmailDetail } from '@apple/features/report/email/components/EmailDetail';
import { DetailsLayout } from '@apple/ui/layouts';

export const Route = createFileRoute('/_authed/_admin/reports/supportRequests_/$emailId')({
	parseParams: ({ emailId }) => ({
		emailId: emailId,
	}),
	beforeLoad: ({ context, location, params: { emailId }, cause }) => {
		requireAuth(
			{ context, location, cause },
			{
				requirePermission: 'Manzanita.Security.Features.Reports',
			},
		);

		return {
			supportRequestEmailQueryOptions: queryOptions({
				queryKey: ['emails', emailId],
				queryFn: () => getEmail(emailId),
			}),
		};
	},
	loader: ({ context: { queryClient, supportRequestEmailQueryOptions } }) => {
		void queryClient.prefetchQuery(supportRequestEmailQueryOptions);
	},
	wrapInSuspense: true,
	component: ProgramRoute,
});

function ProgramRoute() {
	const { supportRequestEmailQueryOptions } = Route.useRouteContext();

	const [supportRequestEmailQuery] = useSuspenseQueries({
		queries: [supportRequestEmailQueryOptions],
	});

	return (
		<DetailsLayout title={supportRequestEmailQuery.data.subject}>
			<EmailDetail email={supportRequestEmailQuery.data} />
		</DetailsLayout>
	);
}
