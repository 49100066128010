import { z } from 'zod';

import { dateTimeSchema } from '@apple/utils/globalization';

export interface Comment {
	id: string;
	message: string;
	createdOn: string | Date;
	createdBy: Commenter;
}
export interface Message {
	message: string;
}

export interface Commenter {
	userId: number;
	name: string;
}

export interface ItemQuantity {
	itemId: string;
	quantity: number;
}

export const messageSchema = z.object({
	message: z.string(),
}) satisfies z.ZodType<Message>;

export const commenterSchema = z.object({
	userId: z.number(),
	name: z.string(),
}) satisfies z.ZodType<Commenter>;

export const commentSchema = z.object({
	id: z.string(),
	message: z.string(),
	createdOn: dateTimeSchema,
	createdBy: commenterSchema,
}) satisfies z.ZodType<Comment>;
