import { Center, Stack, Title } from '@mantine/core';
import { keepPreviousData, queryOptions, useQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { useReactTable } from '@tanstack/react-table';
import {
	getCoreRowModel,
	getFacetedMinMaxValues,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
} from '@tanstack/table-core';
import type { SortingState } from '@tanstack/table-core';

import { Upload } from '@apple/assets/icons';
import { requireAuth } from '@apple/features/auth';
import { getUserFiles } from '@apple/features/file-manager/api/user-files';
import { FileActions, FileInfo } from '@apple/features/file-manager/components';
import { fileManagerQueryKeys } from '@apple/features/file-manager/queries/queries';
import { useTranslation } from '@apple/lib/i18next';
import { DataTable, useTableState } from '@apple/ui/data-table';
import { ToolbarButton } from '@apple/ui/data-table/controls/Toolbar';
import { TableLayout } from '@apple/ui/layouts';
import { Link } from '@apple/ui/link';
import type { UserFileModel } from '@apple/features/file-manager/models/models';

const defaultFilters: { name?: string } = {};
const defaultSorting: SortingState = [{ id: 'name', desc: false }];

export const Route = createFileRoute('/_authed/_admin/content/file-manager')({
	component: FileManagerRoute,
	beforeLoad: args => {
		requireAuth(args, {
			requirePermission: 'AppleBranded.Security.UserFileRepository.View',
		});

		return {
			userFilesQueryOptions: queryOptions({
				queryKey: fileManagerQueryKeys.queue,
				queryFn: () => getUserFiles(),
				placeholderData: keepPreviousData,
				throwOnError: true,
				initialData: [],
			}),
		};
	},
	loader: ({ context: { queryClient, userFilesQueryOptions } }) => {
		void queryClient.prefetchQuery(userFilesQueryOptions);
	},
});

function FileManagerRoute() {
	const { t } = useTranslation('content', {
		nsMode: 'fallback',
	});

	const { userFilesQueryOptions } = Route.useRouteContext();
	const search = Route.useSearch();
	const navigate = Route.useNavigate();
	const userFilesQuery = useQuery(userFilesQueryOptions);

	const tableState = useTableState<UserFileModel, { name?: string }>({
		search,
		navigate,
		defaultFilters: defaultFilters,
		defaultSorting: defaultSorting,
		fieldMap: [],
	});

	const table = useReactTable<UserFileModel>({
		data: userFilesQuery.data,
		...tableState,
		enableFilters: true,
		enableColumnFilters: true,
		enableSorting: true,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
		getFacetedMinMaxValues: getFacetedMinMaxValues(),
		columnResizeMode: 'onChange',
		columns: [
			{
				accessorKey: 'name',
				header: t('fileManager.labels.fileName'),
				cell: ({ row }) => <FileInfo userFile={row?.original} />,
				filter: {
					group: t('fileManager.filter.label'),
					variant: 'text',
				},
			},
			{
				enableColumnFilter: false,
				enableGlobalFilter: false,
				accessorKey: 'description',
				header: t('fileManager.labels.fileDescription'),
			},
			{
				enableColumnFilter: false,
				enableGlobalFilter: false,
				header: '',
				id: 'id',
				cell: ({ row }) => row && <FileActions documentId={row?.original.id} />,
			},
		],
	});

	function renderEmpty() {
		if (userFilesQuery.data?.length === 0 && !userFilesQuery.isLoading) {
			return (
				<Stack>
					<Title>{t('fileManager.emptyFileManagerMessage')}</Title>
					<Center>
						<Link to='/content/file-manager/upload'>
							<Title order={3}>{t('fileManager.buttons.uploadFile')}</Title>
						</Link>
					</Center>
				</Stack>
			);
		}
	}

	return (
		<TableLayout
			table={table}
			title={t('fileManager.title')}
			toolbarButtons={[
				<ToolbarButton
					key='reject'
					tooltip={t('fileManager.buttons.uploadFile')}
					icon={Upload}
					onClick={() => {
						void navigate({ to: '/content/file-manager/upload' });
					}}
				/>,
			]}
		>
			<DataTable
				table={table}
				loading={userFilesQuery.isFetching}
				renderEmpty={renderEmpty}
			/>
		</TableLayout>
	);
}
