import { keepPreviousData, queryOptions, useSuspenseQueries } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { requireAuth } from '@apple/features/auth';
import { getLocationAccessRequest, locationApprovalQueryKeys } from '@apple/features/location';
import { LocationApprovalDetail } from '@apple/features/location/components/LocationApprovalDetail';
import { useTranslation } from '@apple/lib/i18next';
import { DetailsLayout } from '@apple/ui/layouts';

export const Route = createFileRoute('/_authed/_admin/approvals/location_/$userId')({
	component: LocationRequest,
	parseParams: ({ userId }) => ({
		userId: z.string().parse(userId),
	}),
	beforeLoad: ({ params: { userId }, ...args }) => {
		requireAuth(args, {
			requirePermission: 'AppleBrandedPrograms.Security.Features.LocationApprovalManagement',
		});

		return {
			locationRequestQueryOptions: queryOptions({
				queryKey: locationApprovalQueryKeys.userAccess(Number(userId)),
				queryFn: () => getLocationAccessRequest(Number(userId)),
				placeholderData: keepPreviousData,
				throwOnError: true,
			}),
		};
	},
	loader: ({ context: { queryClient, locationRequestQueryOptions } }) => {
		void queryClient.prefetchQuery(locationRequestQueryOptions);
	},
	wrapInSuspense: true,
});

function LocationRequest() {
	const { t } = useTranslation('location-approval');
	const { locationRequestQueryOptions } = Route.useRouteContext();
	const [locationQuery] = useSuspenseQueries({
		queries: [locationRequestQueryOptions],
	});

	return (
		<DetailsLayout title={t('title.detail')}>
			<LocationApprovalDetail locationRequest={locationQuery.data} />
		</DetailsLayout>
	);
}
