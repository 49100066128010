import type { TFunction } from 'i18next';
import { z } from 'zod';

import type { RoleName } from '../models/roles';

export type UserId = number;

export interface User {
	username: string;
	email: string;
	firstName: string;
	lastName: string;
	language: string;
	timezone: string;
	locale: string;
	currency: string | null;
	isFirstLogin: boolean;
	hasAcceptedTerms: boolean;
	memberSince: string | null;
	lastLogin: string | null;
	roles: string[];
	permissions: string[];
	/** The number of locations the user has access to. */
	locationCount: number;
}

export type ProfileDto = ProfileWritableDtoFields &
	ProfileReadonlyDtoFields &
	Readonly<{
		isFirstLogin: boolean;
		hasAcceptedTerms: boolean;
		memberSince: string;
		lastLogin: string;
		roles: RoleName[];
		permissions: Permission[];
	}>;

export type ProfileWritableDtoFields = {
	firstName: string;
	lastName: string;
	emailAddress: string;
	language: string;
	timeZone: string;
	numberFormatCountry: string;
	currencyIsoCode: string;
};

export type ProfileReadonlyDtoFields = Readonly<{
	userName: string;
}>;

export type Permission = z.infer<typeof permissionSchema>;

export const permissionSchema = z.enum([
	'Manzanita.Security.Customers.CanAccessAllCustomers',
	'Manzanita.Security.Customers.CanAccessDeletedCustomers',
	'Manzanita.Security.Features.AdminOnly',
	'Manzanita.Security.Features.ContentManagement',
	'Manzanita.Security.Features.CountryManagement.Read',
	'Manzanita.Security.Features.CountryManagement.Write',
	'Manzanita.Security.Features.CurrencyConversionManagement.Read',
	'Manzanita.Security.Features.CurrencyConversionManagement.Write',
	'Manzanita.Security.Features.CustomerGroupCountryManagement.Read',
	'Manzanita.Security.Features.CustomerGroupCountryManagement.Write',
	'Manzanita.Security.Features.CustomerGroupManagement.Read',
	'Manzanita.Security.Features.CustomerGroupManagement.Write',
	'Manzanita.Security.Features.CustomerManagement',
	'Manzanita.Security.Features.DistroOrderHistory',
	'Manzanita.Security.Features.FulfillmentRuleManagement.Read',
	'Manzanita.Security.Features.FulfillmentRuleManagement.Write',
	'Manzanita.Security.Features.OrderManagement.Read',
	'Manzanita.Security.Features.OrderManagement.Write',
	'Manzanita.Security.Features.PlantManagement.Read',
	'Manzanita.Security.Features.PlantManagement.Write',
	'Manzanita.Security.Features.ProductCategoryManagement.Read',
	'Manzanita.Security.Features.ProductCategoryManagement.Write',
	'Manzanita.Security.Features.ProductManagement.Read',
	'Manzanita.Security.Features.ProductManagement.Write',
	'Manzanita.Security.Features.Reports',
	'Manzanita.Security.Features.Snapshot',
	'Manzanita.Security.Features.UserManagement.Read',
	'Manzanita.Security.Features.UserManagement.Write',
	'Manzanita.Security.Orders.CanAccessConfidentialOrders',
	'Manzanita.Security.Orders.CanAccessGhostOrders',
	'Manzanita.Security.Orders.CanCancelDistroOrders',
	'Manzanita.Security.Orders.CanChangeShippingOption',
	'Manzanita.Security.Orders.CanGiveOrderName',
	'Manzanita.Security.Orders.CanModifyExistingOrders',
	'Manzanita.Security.Orders.CanPlaceAdminOrders',
	'Manzanita.Security.Orders.CanPlaceDistroOrders',
	'Manzanita.Security.Orders.CanPlaceFutureOrders',
	'Manzanita.Security.Orders.CanPlaceGhostOrders',
	'Manzanita.Security.Orders.CanPlaceOrderWithoutApproval',
	'Manzanita.Security.Orders.CanViewAllOrders',
	'Manzanita.Security.Products.CanAccessAdminProducts',
	// Apple Channel
	'AppleBranded.Security.Orders.CanPlaceOrderWithoutLimit',
	'AppleBranded.Security.ShipToAddress.CanDeleteOtherUsersAddresses',
	'AppleBranded.Security.ShipToAddress.CanEditOtherUsersAddresses',
	'AppleBranded.Security.ShipToAddress.CanSeeAllCountries',
	'AppleBranded.Security.ShipToAddress.CanSetUser',
	'AppleBranded.Security.UserFileRepository.Upload',
	'AppleBranded.Security.UserFileRepository.View',
	'AppleBrandedPrograms.Security.Features.AppleIdFeedLocationsManagement.Read',
	'AppleBrandedPrograms.Security.Features.AppleMerchandisersManagement.Read',
	'AppleBrandedPrograms.Security.Features.AppleMerchandisersManagement.Write',
	'AppleBrandedPrograms.Security.Features.LocationApprovalManagement',
	'AppleBrandedPrograms.Security.Features.Returns',
	// Apple Retail
	'AppleRetail.Security.Features.ActivityLog',
	'AppleRetail.Security.Features.CalendarManagement',
	'AppleRetail.Security.Features.RecurringOrders.StoreView',
	'AppleRetail.Security.Features.RecurringOrders',
	'AppleRetail.Security.Features.RecurringOrdersManagement',
	'AppleRetail.Security.Features.StoreEventManagement.PropagateRuleChanges',
	'AppleRetail.Security.Features.StoreEventManagement.Read',
	'AppleRetail.Security.Features.StoreEventManagement.ReadCategories',
	'AppleRetail.Security.Features.StoreEventManagement.Write',
	'AppleRetail.Security.UserFileRepository.Upload',
	'AppleRetail.Security.UserFileRepository.View',
]);

export type UpdateProfileRequest = ProfileWritableDtoFields & ProfileReadonlyDtoFields;

/** @see Database.AppleBrandedPrograms\SeedData\ValidationField.sql */
export const updateProfileSchema = z.object({
	username: z.string().max(300),
	firstname: z.string().max(35),
	lastname: z.string().max(25),
	email: z.string().email().max(241),
});

export type ChangePasswordRequest = {
	currentPassword: string;
	newPassword: string;
	confirmNewPassword: string;
};

export function createChangePasswordSchema(t: TFunction<'zod', undefined>) {
	return z.object({
		password: z.string().min(1),
		newPassword: z
			.string()
			.min(8, { message: t('auth:errors.invalidLength') })
			.max(35, { message: t('auth:errors.invalidLength') }),
		confirmPassword: z.string(),
	});
}

export interface ResetPasswordResponse {
	username: string;
	token: string;
}

export interface SetPasswordRequest {
	newPassword: string;
	confirmNewPassword: string;
	authenticateUser: boolean;
	token: string;
}

export const resetPasswordResponseSchema = z.object({
	username: z.string(),
	token: z.string(),
}) satisfies z.ZodType<ResetPasswordResponse>;

export function resetPasswordValidationSchema(t: TFunction<'zod', undefined>) {
	return z
		.object({
			newPassword: z
				.string()
				.min(8, { message: t('auth:errors.invalidLength') })
				.regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/, {
					message: t('auth:errors.missingRequiredCharacters'),
				}),
			confirmNewPassword: z.string(),
			authenticateUser: z.boolean(),
			token: z.string(),
		})
		.refine(data => data.newPassword === data.confirmNewPassword, {
			message: t('profile:changePassword.passwordsNotEqual'),
			path: ['confirmNewPassword'],
		}) satisfies z.ZodType<SetPasswordRequest>;
}

export enum PasswordValidationErrorType {
	cannotMatchUsername = 'cannotMatchUsername',
	invalidLength = 'invalidLength',
	invalidCharacter = 'invalidCharacter',
	missingRequiredCharacters = 'missingRequiredCharacters',
}
