import { Text } from '@mantine/core';
import { useQueries } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { useReactTable } from '@tanstack/react-table';
import {
	getCoreRowModel,
	getExpandedRowModel,
	getFacetedMinMaxValues,
	getFacetedRowModel,
	getFilteredRowModel,
	getSortedRowModel,
} from '@tanstack/table-core';

import { requireAuth } from '@apple/features/auth';
import { getPlantsDataTableQueryOptions } from '@apple/features/plants/queries/odata';
import { useTranslation } from '@apple/lib/i18next';
import { DataTable, useTableState } from '@apple/ui/data-table';
import { TableLayout } from '@apple/ui/layouts';
import { Link } from '@apple/ui/link';
import type { PlantListing, PlantsFilters } from '@apple/features/plants/models/listing';

export const Route = createFileRoute('/_authed/_admin/manage/plants')({
	beforeLoad: args =>
		requireAuth(args, {
			requirePermission: 'Manzanita.Security.Features.PlantManagement.Read',
		}),
	component: PlantManagementRoute,
});

function PlantManagementRoute() {
	const { t } = useTranslation('manage');
	const search = Route.useSearch();
	const navigate = Route.useNavigate();

	const tableState = useTableState<PlantListing, PlantsFilters>({
		search,
		navigate,
		defaultSorting: [{ id: 'name', desc: true }],
		fieldMap: [],
	});

	const [plantsQuery] = useQueries({
		queries: [
			getPlantsDataTableQueryOptions({
				filters: tableState.currentFilterData,
				pagination: tableState.state.pagination,
				commonFilters: {},
			}),
		],
	});

	const table = useReactTable<PlantListing>({
		data: plantsQuery.data.rows,
		rowCount: plantsQuery.data.totalRowCount,
		...tableState,
		enableFilters: true,
		enableColumnFilters: true,
		enableSorting: true,
		manualFiltering: true,
		manualSorting: true,
		manualPagination: true,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		getFacetedMinMaxValues: getFacetedMinMaxValues(),
		getFacetedRowModel: getFacetedRowModel(),
		columnResizeMode: 'onChange',
		columns: [
			{
				id: 'name',
				accessorKey: 'name',
				header: t('plants.fields.plantName'),
				filter: {
					group: t('plants.filterGroups.details'),
				},
				cell: ({ row }) => (
					<Link
						to='/manage/plants/$warehouseLocationId'
						params={{ warehouseLocationId: row.original.warehouseLocationId }}
						title={t('plants.links.view')}
					>
						<Text size='sm'>{row.original.name}</Text>
					</Link>
				),
			},
			{
				id: 'code',
				accessorKey: 'code',
				header: t('plants.fields.plantCode'),
				size: 100,
				filter: {
					group: t('plants.filterGroups.details'),
				},
			},
			{
				id: 'abbreviation',
				accessorKey: 'abbreviation',
				header: t('plants.fields.plantAbbreviation'),
				size: 100,
				filter: {
					group: t('plants.filterGroups.details'),
				},
			},
			{
				id: 'salesOrganization',
				accessorKey: 'salesOrganization',
				header: t('plants.fields.salesOrganization'),
				size: 100,
				filter: {
					group: t('plants.filterGroups.details'),
				},
			},
			{
				id: 'currency',
				accessorKey: 'currency',
				header: t('plants.fields.currency'),
				size: 100,
				filter: {
					group: t('plants.filterGroups.details'),
				},
			},
		],
	});

	return (
		<TableLayout table={table} title={t('plants.title')}>
			<DataTable table={table} loading={plantsQuery.isFetching} />
		</TableLayout>
	);
}
