import { useCallback, useMemo } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';

import { AnnouncementForm } from '@apple/features/announcements/components/AnnouncementForm';
import { useAnnouncementManagement } from '@apple/features/announcements/hooks/useAnnouncementManagement';
import { announcementQueries } from '@apple/features/announcements/queries/management';
import { requireAuth } from '@apple/features/auth';
import { useTranslation } from '@apple/lib/i18next';
import { DetailsLayout } from '@apple/ui/layouts';
import type { Announcement } from '@apple/features/announcements/models/management';

export const Route = createFileRoute('/_authed/_admin/content/announcements_/add')({
	component: ManageAnnouncementRoute,
	beforeLoad: args => {
		requireAuth(args);

		return {
			announcementsQueryOptions: announcementQueries.getAllAnnouncements(),
		};
	},
	loader: ({ context: { queryClient, announcementsQueryOptions } }) => {
		void queryClient.prefetchQuery(announcementsQueryOptions);
	},
	wrapInSuspense: true,
});

function ManageAnnouncementRoute() {
	const navigate = Route.useNavigate();
	const { announcementsQueryOptions } = Route.useRouteContext();
	const { t } = useTranslation('content');
	const announcementsQuery = useSuspenseQuery(announcementsQueryOptions);
	const { add } = useAnnouncementManagement();

	const activeAllowed = useMemo(() => {
		const activeAnnouncement = announcementsQuery.data.find(x => x.active);
		return !activeAnnouncement;
	}, [announcementsQuery.data]);

	const handleSave = useCallback(
		async (announcement: Announcement) => {
			await add.mutateAsync(announcement, {
				onSuccess: () => {
					void navigate({
						to: '/content/announcements',
					});
				},
			});
		},
		[add, navigate],
	);

	return (
		<DetailsLayout title={t('announcements.add.title')}>
			<AnnouncementForm
				activeAllowed={activeAllowed}
				onSave={handleSave}
				loading={add.isPending}
				submitted={add.isSuccess}
			/>
		</DetailsLayout>
	);
}
