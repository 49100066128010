import { useSuspenseQueries } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { requireAuth } from '@apple/features/auth';
import { OrderApprovalDetail } from '@apple/features/order-channel/components/OrderDetails/OrderApprovalDetail';
import {
	commentsQueryOptions,
	purchaseOrderLinesQueryOptions,
	purchaseOrderQueryOptions,
} from '@apple/features/order-channel/queries/details';
import { useTranslation } from '@apple/lib/i18next';
import { DetailsLayout } from '@apple/ui/layouts';

export const Route = createFileRoute('/_authed/_admin/approvals/orders_/$poNumber')({
	wrapInSuspense: true,
	component: OrderRoute,
	parseParams: ({ poNumber }) => ({
		poNumber: z.string().parse(poNumber),
	}),

	beforeLoad: ({ params: { poNumber }, ...args }) => {
		requireAuth(args, {
			requirePermission: 'Manzanita.Security.Features.OrderManagement.Write',
		});
		return {
			purchaseOrderQueryOptions: purchaseOrderQueryOptions(poNumber),
			purchaseOrderLinesQueryOptions: purchaseOrderLinesQueryOptions(poNumber),
			commentsQueryOptions: commentsQueryOptions(poNumber),
		};
	},
	loader: ({
		context: {
			queryClient,
			purchaseOrderQueryOptions,
			purchaseOrderLinesQueryOptions,
			commentsQueryOptions,
		},
	}) => {
		void queryClient.prefetchQuery(purchaseOrderQueryOptions);
		void queryClient.prefetchQuery(purchaseOrderLinesQueryOptions);
		void queryClient.prefetchQuery(commentsQueryOptions);
	},
});

function OrderRoute() {
	const { t } = useTranslation('order');
	const { purchaseOrderQueryOptions, purchaseOrderLinesQueryOptions, commentsQueryOptions } =
		Route.useRouteContext();
	const [orderQuery, linesQuery, commentsQuery] = useSuspenseQueries({
		queries: [purchaseOrderQueryOptions, purchaseOrderLinesQueryOptions, commentsQueryOptions],
	});

	return (
		<DetailsLayout
			title={t('orderDetails.title', {
				orderNumber: orderQuery.data.purchaseOrderNumber,
			})}
		>
			<OrderApprovalDetail
				order={orderQuery.data}
				address={orderQuery.data}
				internationalAddress={
					orderQuery.data.hasInternationalAddress ? orderQuery.data : undefined
				}
				lines={linesQuery.data}
				comments={commentsQuery.data}
			/>
		</DetailsLayout>
	);
}
