import { Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { queryOptions, useMutation, useSuspenseQueries } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { getLogger } from 'loglevel';
import { z } from 'zod';

import { ErrorIcon, Success } from '@apple/assets/icons';
import { requireAuth } from '@apple/features/auth';
import {
	deleteMerchandiser,
	getMerchandiser,
	MerchandiserDetails,
	updateMerchandiser,
} from '@apple/features/merchandiser';
import { useTranslation } from '@apple/lib/i18next';
import { DetailsLayout } from '@apple/ui/layouts';
import { ServerValidationError } from '@apple/utils/api';
import type { Merchandiser } from '@apple/features/merchandiser';

const log = getLogger('manage-merchandiser');

export const Route = createFileRoute('/_authed/_admin/manage/merchandisers_/$locationId')({
	parseParams: ({ locationId }) => ({
		locationId: z.string().parse(locationId),
	}),
	beforeLoad: ({ params: { locationId }, ...args }) => {
		requireAuth(args, {
			requirePermissions: [
				'AppleBrandedPrograms.Security.Features.AppleMerchandisersManagement.Read',
				'AppleBrandedPrograms.Security.Features.AppleMerchandisersManagement.Write',
			],
		});

		return {
			merchandiserQueryOptions: queryOptions({
				queryKey: ['merchandiser', locationId],
				queryFn: () => getMerchandiser(locationId),
			}),
		};
	},
	loader: async ({ context: { queryClient, merchandiserQueryOptions } }) => {
		await queryClient.fetchQuery(merchandiserQueryOptions);
	},
	wrapInSuspense: true,
	component: MerchandiserRoute,
});

function MerchandiserRoute() {
	const { merchandiserQueryOptions, queryClient } = Route.useRouteContext();
	const [merchandiserDetailsQuery] = useSuspenseQueries({
		queries: [merchandiserQueryOptions],
	});

	const navigate = Route.useNavigate();
	const merchandiser = merchandiserDetailsQuery.data;
	const { t } = useTranslation('merchandiser');

	async function handleSuccessfulChange(title: string, message: string) {
		await queryClient.invalidateQueries({
			queryKey: merchandiserQueryOptions.queryKey,
		});
		await queryClient.invalidateQueries({ queryKey: ['merchandisers-odata'] });

		notifications.show({
			title: title,
			message: message,
			icon: <Success />,
			color: 'green',
			autoClose: 5000,
		});
		await navigate({ to: '/manage/merchandisers' });
	}

	function showErrorNotification(error: Error) {
		if (!(error instanceof ServerValidationError)) {
			log.error(error);
			notifications.show({
				message: t('common:error.generic'),
				icon: <ErrorIcon />,
				color: 'red',
				autoClose: 5000,
			});
		}
	}

	const updateMerchandiserMutation = useMutation({
		mutationFn: async (merchandiser: Merchandiser) => await updateMerchandiser(merchandiser),
		onSuccess: async () => {
			await handleSuccessfulChange(
				t('notifications.successTitle'),
				t('notifications.updateSuccess'),
			);
		},
		onError: showErrorNotification,
	});

	function handleDeleteMerchandiser(): void {
		modals.openConfirmModal({
			title: t('details.deleteModal.title'),
			children: (
				<Text size='lg'>
					{merchandiser.hasUsersWithAccess
						? t('details.deleteModal.messageWithAssociation')
						: t('details.deleteModal.message', {
								merchandiserName: merchandiser.merchandiserName,
							})}
				</Text>
			),
			labels: { confirm: 'Confirm', cancel: 'Cancel' },
			onConfirm: () => {
				deleteMerchandiserMutation.mutate(merchandiser.id ?? '');
			},
		});
	}

	const deleteMerchandiserMutation = useMutation({
		mutationFn: deleteMerchandiser,
		onSuccess: async () => {
			await handleSuccessfulChange(
				t('notifications.successTitle'),
				t('notifications.deleteSuccess'),
			);
		},
		onError: showErrorNotification,
	});

	return (
		<DetailsLayout title={t('title.edit')}>
			<MerchandiserDetails
				isEdit={true}
				merchandiser={merchandiser}
				onDelete={handleDeleteMerchandiser}
				onSave={updateMerchandiserMutation.mutateAsync}
			/>
		</DetailsLayout>
	);
}
