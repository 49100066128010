import { keepPreviousData, queryOptions } from '@tanstack/react-query';

import { getProfile } from '../api/authentication';

export const profileQueryKeys = {
	profile: ['profile'],
};

export const profileQueryOptions = (enabled = true) =>
	queryOptions({
		queryKey: profileQueryKeys.profile,
		queryFn: () => getProfile(),
		refetchOnMount: true,
		// Prevents page flicker when navigating since this is used in `beforeLoad`
		placeholderData: keepPreviousData,
		enabled: enabled,
	});
