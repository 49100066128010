import { notifications } from '@mantine/notifications';
import { queryOptions, useMutation, useSuspenseQueries } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';

import { ErrorIcon, Success } from '@apple/assets/icons';
import { requireAuth } from '@apple/features/auth';
import { getCountries, saveCountry } from '@apple/features/country/api';
import { CountryForm } from '@apple/features/country/components/CountryForm';
import { countryKeys } from '@apple/features/country/models';
import { plantQueryOptions } from '@apple/features/plants/queries/queries';
import { useTranslation } from '@apple/lib/i18next';
import { useRouterHistory } from '@apple/lib/tanstack-router/hooks/use-router-history';
import { DetailsLayout } from '@apple/ui/layouts';
import { ServerValidationError } from '@apple/utils/api';
import { globalizationQueryOptions } from '@apple/utils/globalization';

export const Route = createFileRoute('/_authed/_admin/manage/countries_/add')({
	beforeLoad: ({ context, location, cause }) => {
		requireAuth(
			{ context, location, cause },
			{
				requirePermission: 'Manzanita.Security.Features.CountryManagement.Write',
			},
		);

		return {
			plantsQueryOptions: plantQueryOptions.plants,
			languageOptions: globalizationQueryOptions.allSupportedLanguages,
			countriesQueryOptions: queryOptions({
				queryKey: countryKeys.supported,
				queryFn: () => getCountries(),
			}),
		};
	},
	loader: ({
		context: { queryClient, plantsQueryOptions, languageOptions, countriesQueryOptions },
	}) => {
		void queryClient.prefetchQuery(plantsQueryOptions);
		void queryClient.prefetchQuery(languageOptions);
		void queryClient.prefetchQuery(countriesQueryOptions);
	},
	wrapInSuspense: true,
	component: CountryRoute,
});

function CountryRoute() {
	const { t } = useTranslation('country');
	const { countriesQueryOptions, plantsQueryOptions, languageOptions, queryClient } =
		Route.useRouteContext();

	const [countriesQuery, plantsQuery, languageQuery] = useSuspenseQueries({
		queries: [countriesQueryOptions, plantsQueryOptions, languageOptions],
	});

	const navigate = Route.useNavigate();
	const history = useRouterHistory();

	const addCountry = useMutation({
		mutationFn: saveCountry,
		onSuccess: async () => {
			notifications.show({
				color: 'green',
				message: t('save.success'),
				icon: <Success />,
			});
			await queryClient.invalidateQueries({
				queryKey: countryKeys.all,
			});
			history.canGoBack() ? history.back() : await navigate({ to: '/manage/countries' });
		},
		onError: (error: Error) => {
			if (!(error instanceof ServerValidationError)) {
				notifications.show({
					message: t('common:error.generic'),
					icon: <ErrorIcon />,
					color: 'red',
					autoClose: 5000,
				});
			}
		},
	});

	return (
		<DetailsLayout title={t('title.add')}>
			<CountryForm
				onSaveCountry={addCountry.mutateAsync}
				countries={countriesQuery.data}
				plants={plantsQuery.data}
				languages={languageQuery.data}
			/>
		</DetailsLayout>
	);
}
