import { memo, useCallback, useEffect, useState } from 'react';
import { Combobox, TextInput, useCombobox } from '@mantine/core';
import { useDebouncedState } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';

import { icons } from '@apple/assets';
import { useTranslation } from '@apple/lib/i18next';

import { autocomplete } from '../api/location';
import type { Location, LocationAutocompleteRequest, LocationId } from '../models/location';

export interface LocationAutocompleteProps {
	excludeLocations: LocationId[];
	selectedLocation: Location | undefined;
	onLocationSelected: (location: Location | undefined) => void;
}

export const LocationAutocomplete = memo<LocationAutocompleteProps>(
	({ excludeLocations, selectedLocation, onLocationSelected }: LocationAutocompleteProps) => {
		const { t } = useTranslation('common');
		const [autocompleteLocations, setAutocompleteLocations] = useState<Location[]>([]);
		const [searchTerm, setSearchTerm] = useDebouncedState('', 400);
		const [lastSearchTerm, setLastSearchTerm] = useState('');
		const [displayText, setDisplayText] = useState('');
		const [isDropdownOpen, setIsDropdownOpen] = useState(false);

		const selector = useCombobox({
			onDropdownClose: () => {
				setIsDropdownOpen(false);
				selector.resetSelectedOption();
			},
			onDropdownOpen: () => setIsDropdownOpen(true),
		});

		useEffect(() => {
			if (searchTerm.trim().length <= 3 || searchTerm === lastSearchTerm) return;

			setLastSearchTerm(searchTerm);

			const request = {
				searchText: searchTerm,
				locationIds: excludeLocations,
			} satisfies LocationAutocompleteRequest;

			autocomplete(request)
				.then((locations: Location[]) => {
					setAutocompleteLocations(locations);
					if (!isDropdownOpen) selector.toggleDropdown();
				})
				.catch(() =>
					notifications.show({
						color: 'red',
						message: t('error.generic'),
						icon: <icons.ErrorIcon />,
					}),
				);
		}, [isDropdownOpen, lastSearchTerm, excludeLocations, searchTerm, selector, t]);

		const locationDropdownOptions = autocompleteLocations.map(option => (
			<Combobox.Option value={option.id} key={option.id}>
				{option.appleId} - {option.retailerName}
			</Combobox.Option>
		));

		const handleOptionSubmit = useCallback(
			(value: string) => {
				if (value === '0') {
					return;
				}

				const location = autocompleteLocations.find(x => x.id.toString() === value);

				if (location === undefined) {
					return;
				}

				onLocationSelected(location);
				setDisplayText(location.storeName);
				selector.toggleDropdown();
				setIsDropdownOpen(false);
			},
			[autocompleteLocations, onLocationSelected, selector],
		);

		const handleClearSelection = useCallback(() => {
			setLastSearchTerm('');
			setSearchTerm('');
			onLocationSelected(undefined);
		}, [onLocationSelected, setSearchTerm]);

		const handleInputClick = useCallback(() => {
			if (!isDropdownOpen && autocompleteLocations.length > 0) {
				selector.toggleDropdown();
				setIsDropdownOpen(true);
			}
		}, [autocompleteLocations.length, isDropdownOpen, selector]);

		const dropdownOptions =
			autocompleteLocations.length > 0 ? (
				locationDropdownOptions
			) : (
				<Combobox.Option value='0' key='0'>
					{t('locationAutocomplete.notFound', { searchTerm })}
				</Combobox.Option>
			);

		return (
			<>
				{selectedLocation !== undefined ? (
					<TextInput
						value={displayText}
						readOnly
						leftSection={<icons.Close color='red' onClick={handleClearSelection} />}
					/>
				) : (
					<Combobox store={selector} onOptionSubmit={handleOptionSubmit}>
						<Combobox.Target>
							<TextInput
								onClick={handleInputClick}
								placeholder={t('locationAutocomplete.placeholder')}
								defaultValue=''
								onChange={event => setSearchTerm(event.currentTarget.value)}
							/>
						</Combobox.Target>
						<Combobox.Dropdown>
							<Combobox.Options>{dropdownOptions}</Combobox.Options>
						</Combobox.Dropdown>
					</Combobox>
				)}
			</>
		);
	},
);
