import { createFileRoute } from '@tanstack/react-router';

import { requireAuth } from '@apple/features/auth';
import { DistroUploadPage } from '@apple/features/order-channel/components/DistroUploadPage';
import { useTranslation } from '@apple/lib/i18next';
import { TitleLayout } from '@apple/ui/layouts/TitleLayout';

export const Route = createFileRoute('/_authed/_admin/distro/upload')({
	component: DistroUploadRoute,
	beforeLoad: args =>
		requireAuth(args, {
			requirePermission: 'Manzanita.Security.Orders.CanPlaceDistroOrders',
		}),
});

function DistroUploadRoute() {
	const { t } = useTranslation('distro-upload');
	const { auth } = Route.useRouteContext();

	const canPlaceGhostOrders = auth.hasPermissions([
		'Manzanita.Security.Orders.CanPlaceGhostOrders',
	]);
	const canPlaceFutureOrders = auth.hasPermissions([
		'Manzanita.Security.Orders.CanPlaceFutureOrders',
	]);

	return (
		<TitleLayout title={t('title')}>
			<DistroUploadPage
				showAdminOptions={canPlaceGhostOrders || canPlaceFutureOrders}
				showGhostOrderOptions={canPlaceGhostOrders}
			/>
		</TitleLayout>
	);
}
