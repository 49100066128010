import { notifications } from '@mantine/notifications';
import { useMutation, useSuspenseQueries } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { getLogger } from 'loglevel';

import { ErrorIcon, Success } from '@apple/assets/icons';
import { requireAuth } from '@apple/features/auth';
import { saveProduct } from '@apple/features/product-channel/api/management';
import { ProductForm } from '@apple/features/product-channel/components/ProductManagement/ProductForm';
import { channelProductQueryOptions } from '@apple/features/product-channel/queries/management';
import { PRODUCTS_ODATA_QUERY_KEY } from '@apple/features/product-channel/queries/odata';
import { productQueryKeys, productQueryOptions } from '@apple/features/product/queries/management';
import { useTranslation } from '@apple/lib/i18next';
import { DetailsLayout } from '@apple/ui/layouts';
import { ServerValidationError } from '@apple/utils/api';
import type { AppleBrandedProduct } from '@apple/features/product-channel/models/management';

const log = getLogger('manage-product');

export const Route = createFileRoute('/_authed/_admin/manage/products_/add')({
	beforeLoad: args => {
		requireAuth(args, {
			requirePermission: 'Manzanita.Security.Features.ProductManagement.Write',
		});

		return {
			keywordsQueryOptions: productQueryOptions.keywords,
			productVisibilityQueryOptions: channelProductQueryOptions.visibility,
			productLinesQueryOptions: productQueryOptions.categories,
		};
	},
	loader: ({
		context: {
			queryClient,
			keywordsQueryOptions,
			productVisibilityQueryOptions,
			productLinesQueryOptions,
		},
	}) => {
		void queryClient.prefetchQuery(keywordsQueryOptions);
		void queryClient.prefetchQuery(productVisibilityQueryOptions);
		void queryClient.prefetchQuery(productLinesQueryOptions);
	},
	wrapInSuspense: true,
	component: AddProductRoute,
});

function AddProductRoute() {
	const { t } = useTranslation('product');
	const {
		productLinesQueryOptions,
		productVisibilityQueryOptions,
		keywordsQueryOptions,
		queryClient,
	} = Route.useRouteContext();

	const [productLinesQuery, productVisibilityQuery, keywordsQuery] = useSuspenseQueries({
		queries: [productLinesQueryOptions, productVisibilityQueryOptions, keywordsQueryOptions],
	});

	const navigate = Route.useNavigate();

	function showErrorNotification(error: Error) {
		if (!(error instanceof ServerValidationError)) {
			log.error(error);
			notifications.show({
				message: t('common:error.generic'),
				icon: <ErrorIcon />,
				color: 'red',
				autoClose: 5000,
			});
		}
	}

	const addProductMutation = useMutation({
		mutationFn: async (product: AppleBrandedProduct) => await saveProduct(product, 'N/A'),
		onSuccess: async () => {
			void queryClient.invalidateQueries({
				queryKey: productQueryKeys.unassignedSkus,
				exact: true,
			});
			void queryClient.invalidateQueries({
				queryKey: [PRODUCTS_ODATA_QUERY_KEY],
			});

			notifications.show({
				message: t('notifications.created'),
				icon: <Success />,
				color: 'green',
				autoClose: 5000,
			});
			await navigate({
				to: '/manage/products',
			});
		},
		onError: showErrorNotification,
	});

	return (
		<DetailsLayout title={t('titles.addProduct')}>
			<ProductForm
				productLinesQuery={productLinesQuery}
				productVisibilityQuery={productVisibilityQuery}
				keywordsQuery={keywordsQuery}
				saveProductMutation={addProductMutation}
			/>
		</DetailsLayout>
	);
}
