import { z } from 'zod';

import { countryCodeSchema, localDateTimeSchema } from '@apple/utils/globalization';
import type { CountryCode } from '@apple/utils/globalization';

export interface BaseOrderSearchListing {
	purchaseOrderNumber: string;
	salesOrderNumber: string;
	orderDate: string | Date;
	/** The status display value, not the OrderStatus enum value */
	status: string;
	line1: string;
	line2: string | null;
	line3: string | null;
	city: string;
	stateOrProvince: string | null;
	postalCode: string | null;
	countryCode: CountryCode;
	countryDisplayName: string;
	productCount: string;
	isOrderMarkedForCancellation: boolean;
}

export const baseOrderSearchListingSchema = z.object({
	purchaseOrderNumber: z.string(),
	salesOrderNumber: z.string(),
	orderDate: localDateTimeSchema,
	status: z.string(), // can't use orderStatusSchema because this is a display value
	line1: z.string(),
	line2: z.string().nullable(),
	line3: z.string().nullable(),
	city: z.string(),
	stateOrProvince: z.string().nullable(),
	postalCode: z.string().nullable(),
	countryCode: countryCodeSchema,
	countryDisplayName: z.string(),
	productCount: z.string(),
	isOrderMarkedForCancellation: z.boolean(),
});
