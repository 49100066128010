import { z } from 'zod';

/** @see Manzanita.Web.Models.UserRole */
export type Role = z.infer<typeof roleSchema>;
export type RoleName = string;

/** @see AppleBrandedPrograms.Web.Features.Profiles.Controllers.UserRoleListingApiController.ProductVisibilityUserRoles */
export type ProductVisibilityRoles = z.infer<typeof productVisibilityRolesSchema>;

/** @see Manzanita.Web.Models.UserRole */
export const roleSchema = z.object({
	id: z.number(),
	name: z.string(),
});

/** @see AppleBrandedPrograms.Web.Features.Profiles.Controllers.UserRoleListingApiController.ProductVisibilityUserRoles */
export const productVisibilityRolesSchema = z.object({
	adminUserRoles: z.array(roleSchema),
	callCenterUserRoles: z.array(roleSchema),
});
