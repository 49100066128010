import { createFileRoute } from '@tanstack/react-router';

import { requireAuth } from '@apple/features/auth';
import { FileDetail } from '@apple/features/file-manager/components/index';
import { userFileModelSchema } from '@apple/features/file-manager/models/models';
import { useTranslation } from '@apple/lib/i18next';
import { DetailsLayout } from '@apple/ui/layouts';

export const Route = createFileRoute('/_authed/_admin/content/file-manager_/detail')({
	validateSearch: userFileModelSchema,
	wrapInSuspense: true,
	component: FileDetailRoute,
	beforeLoad: args => {
		requireAuth(args, {
			requirePermission: 'AppleBranded.Security.UserFileRepository.View',
		});
	},
});

function FileDetailRoute() {
	const search = Route.useSearch();
	const { t } = useTranslation('content');
	return (
		<DetailsLayout title={t('fileManager.detail.title')}>
			<FileDetail userFile={search} />
		</DetailsLayout>
	);
}
