import { createFileRoute } from '@tanstack/react-router';

import { requireAuth } from '@apple/features/auth';
import { NotImplementedPage } from '@apple/ui/shell/pages/NotImplementedPage';

export const Route = createFileRoute('/_authed/_admin/manage/line-of-business')({
	beforeLoad: args =>
		requireAuth(args, {
			requirePermission: 'Manzanita.Security.Features.ProductCategoryManagement.Read',
		}),
	component: LineOfBusinessManagementRoute,
});

function LineOfBusinessManagementRoute() {
	return <NotImplementedPage name='Line of Business Management' />;
}
