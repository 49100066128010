import { Chip, Loader } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { getLogger } from 'loglevel';
import { useCallback } from 'react';

import { Download, Warning } from '@apple/assets/icons';
import { useTranslation } from '@apple/lib/i18next';
import { useFileDownload } from '@apple/utils/files/hooks/useFileDownload';

const log = getLogger('excel-upload');

export type ErrorFileDownloadProps = {
	getErrorReportUrl: () => string;
	getErrorReportName: (fileId: number) => Promise<string>;
	fileId: number;
	fallbackFilename: string;
	label?: string;
};

export function ErrorFileDownload({
	getErrorReportUrl,
	getErrorReportName,
	fileId,
	fallbackFilename,
	label,
}: ErrorFileDownloadProps) {
	const { t } = useTranslation('manage');
	const errorTemplateDownload = useFileDownload<{ fileName?: string }>({
		method: 'get',
		url: getErrorReportUrl(),
		fallbackFilename: fallbackFilename,
		errorTitle: t('common:error.title'),
		errorMessage: t('common:error.fileDownload'),
	});

	const onDownloadErrorReport = useCallback(() => {
		async function downloadErrorReport() {
			try {
				const errorFile = await getErrorReportName(fileId);
				await errorTemplateDownload.download({
					params: {
						fileName: errorFile,
					},
				});
			} catch (error) {
				notifications.show({
					message: t('common:error.generic'),
					icon: <Warning />,
					color: 'red',
					autoClose: 5000,
				});
				log.error('Error downloading error report', error);
			}
		}

		void downloadErrorReport();
	}, [errorTemplateDownload, fileId, getErrorReportName, t]);

	return (
		<Chip
			disabled={errorTemplateDownload.downloading}
			checked
			variant='outline'
			color='blue'
			icon={errorTemplateDownload.downloading ? <Loader size={10} /> : <Download size={12} />}
			onClick={onDownloadErrorReport}
			size='xs'
		>
			{label ?? t('excelUpload.buttons.downloadErrors')}
		</Chip>
	);
}
