import { Text } from '@mantine/core';
import { useQueries } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import {
	getCoreRowModel,
	getExpandedRowModel,
	getFacetedMinMaxValues,
	getFacetedRowModel,
	getFilteredRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table';

import { AddIcon } from '@apple/assets/icons';
import { requireAuth } from '@apple/features/auth';
import { countryOdataQueryOptions } from '@apple/features/country/odata.queries';
import { useTranslation } from '@apple/lib/i18next';
import { DataTable, useTableState } from '@apple/ui/data-table';
import { ToolbarButton } from '@apple/ui/data-table/controls/Toolbar';
import { TableLayout } from '@apple/ui/layouts';
import { Link } from '@apple/ui/link';
import type { Country, CountryFilters } from '@apple/features/country/models';

export const Route = createFileRoute('/_authed/_admin/manage/countries')({
	component: CountryManagementRoute,
	beforeLoad: args =>
		requireAuth(args, {
			requirePermission: 'Manzanita.Security.Features.CountryManagement.Read',
		}),
});

function CountryManagementRoute() {
	const { t } = useTranslation('manage');
	const navigate = Route.useNavigate();
	const search = Route.useSearch();
	const { auth } = Route.useRouteContext();

	const tableState = useTableState<Country, CountryFilters>({
		search,
		navigate,
		defaultSorting: [{ id: 'name', desc: true }],
		fieldMap: [],
	});

	const [customerSearchQuery] = useQueries({
		queries: [
			countryOdataQueryOptions({
				pagination: tableState.state.pagination,
				filters: tableState.currentFilterData,
			}),
		],
	});

	const table = useReactTable<Country>({
		data: customerSearchQuery.data.rows,
		rowCount: customerSearchQuery.data.totalRowCount,
		...tableState,
		enableFilters: true,
		enableColumnFilters: true,
		enableSorting: true,
		manualFiltering: true,
		manualSorting: true,
		manualPagination: true,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		getFacetedMinMaxValues: getFacetedMinMaxValues(),
		getFacetedRowModel: getFacetedRowModel(),
		columnResizeMode: 'onChange',
		columns: [
			{
				accessorKey: 'name',
				size: 130,
				header: t('countries.fields.countryName'),
				filter: {
					group: t('customers.filterGroups.details'),
				},
				cell: ({ row }) =>
					auth.hasPermission('Manzanita.Security.Features.CountryManagement.Write') ? (
						<Link
							to='/manage/countries/$countryCode'
							params={{ countryCode: row.original.code }}
							title={row.original.name}
							children={row.original.name}
						/>
					) : (
						<Text>{row.original.name}</Text>
					),
			},
			{
				id: 'code',
				size: 90,
				enableColumnFilter: false,
				enableSorting: false,
				accessorKey: 'code',
				header: t('countries.fields.countryCode'),
				filter: {
					group: t('countries.filterGroups.details'),
				},
				cell: ({ row }) => <Text>{row.original.code}</Text>,
			},
			{
				accessorKey: 'cultureCode',
				enableColumnFilter: false,
				enableSorting: false,
				header: t('countries.fields.cultureCode'),
				size: 80,
				cell: ({ row }) => <Text>{row.original.cultureCode}</Text>,
			},
			{
				accessorKey: 'defaultPlant',
				enableSorting: false,
				header: t('countries.fields.defaultPlant'),
				size: 200,
				cell: ({ row }) => <Text>{row.original.plant?.name}</Text>,
				enableColumnFilter: false,
			},
			{
				accessorKey: 'currencyCode',
				enableSorting: false,
				header: t('countries.fields.defaultCurrency'),
				size: 75,
				enableColumnFilter: false,
			},
			{
				accessorKey: 'isEligibleForReturnOrders',
				enableSorting: false,
				header: t('countries.fields.isEligibleForReturnOrders'),
				size: 70,
				cell: ({ row }) => (
					<Text>{row.original.isEligibleForReturnOrders ? 'Yes' : 'No'}</Text>
				),
				enableColumnFilter: false,
			},
		],
	});

	return (
		<TableLayout
			title={t('countries.title')}
			table={table}
			toolbarButtons={[
				auth.hasPermission('Manzanita.Security.Features.CountryManagement.Write') && (
					<ToolbarButton
						key='add'
						tooltip={t('countries.buttons.add.tooltip')}
						icon={AddIcon}
						onClick={() =>
							void navigate({
								to: '/manage/countries/add',
								viewTransition: false,
							})
						}
					/>
				),
			]}
		>
			<DataTable table={table} loading={customerSearchQuery.isFetching} />
		</TableLayout>
	);
}
