import { createFileRoute } from '@tanstack/react-router';

import { requireAuth } from '@apple/features/auth';
import { useTranslation } from '@apple/lib/i18next';
import { TitleLayout } from '@apple/ui/layouts/TitleLayout';
import { HelpPage } from '@apple/ui/shell/pages/HelpPage';

export const Route = createFileRoute('/_authed/help')({
	beforeLoad: args => requireAuth(args),
	component: HelpRoute,
});

function HelpRoute() {
	const { t } = useTranslation('help');
	return (
		<TitleLayout title={t('title')}>
			<HelpPage />
		</TitleLayout>
	);
}
