import { useState } from 'react';
import { Alert, LoadingOverlay } from '@mantine/core';
import { createFileRoute } from '@tanstack/react-router';

import { icons } from '@apple/assets';
import { requireAuth } from '@apple/features/auth';
import { useAuthContext } from '@apple/features/auth-channel/hooks/useAuthContext';
import { AccountSettings } from '@apple/features/user-channel/components/AccountSettings';
import { useTranslation } from '@apple/lib/i18next';
import { TitleLayout } from '@apple/ui/layouts/TitleLayout';
import { useGlobalization } from '@apple/utils/globalization/hooks/useGlobalization';

export const Route = createFileRoute('/_authed/profile')({
	beforeLoad: args => requireAuth(args),
	component: ProfileRoute,
});

export function ProfileRoute() {
	const { t } = useTranslation('profile');
	const [showSuccessMessage, setShowSuccessMessage] = useState(false);
	const user = useAuthContext();
	const globalization = useGlobalization();

	if (user.profile === null || globalization.isLoading) {
		return <LoadingOverlay visible />;
	}

	return globalization.error ? (
		<Alert variant='light' color='red' title='Error' icon={<icons.Warning />}>
			{t('loadingError')}
		</Alert>
	) : (
		<TitleLayout title={t('title')}>
			<AccountSettings
				profile={user.profile}
				updateProfile={user.updateProfile}
				changePassword={user.changePassword}
				timeZones={globalization.timeZones}
				languages={globalization.languages}
				currencies={globalization.currencies}
				countries={globalization.countries}
				showSuccessMessage={showSuccessMessage}
				setShowSuccessMessage={setShowSuccessMessage}
			/>
		</TitleLayout>
	);
}
