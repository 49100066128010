import { Center, Loader, LoadingOverlay, Progress, rem, Space, Stack, Text } from '@mantine/core';

import { useTranslation } from '@apple/lib/i18next';
import { FileImportInput } from '@apple/ui/form';

export function UploadFile({
	maxFileSize,
	allowedFileTypes,
	uploading,
	progress,
	onSelectFile,
}: {
	maxFileSize: number;
	allowedFileTypes: string[];
	uploading: boolean;
	progress: number;
	onSelectFile: (file: File) => void;
}) {
	const { t } = useTranslation('manage');
	return (
		<>
			<LoadingOverlay
				visible={uploading}
				loaderProps={{
					children: (
						<Stack align='center'>
							{progress < 100 ? (
								<>
									<Text>{t('excelUpload.progress.uploading')}</Text>
									<Progress value={progress} w={rem(240)} />
								</>
							) : (
								<>
									<Text>{t('excelUpload.progress.processing')}</Text>
									<Loader data-testid='processing-loader' />
								</>
							)}
						</Stack>
					),
				}}
			/>
			<Stack align='stretch' justify='space-around'>
				<Space />
				<Center>
					<Text>{t('excelUpload.message')}</Text>
				</Center>
				<FileImportInput
					disabled={uploading}
					allowedTypes={allowedFileTypes}
					maxFileSize={maxFileSize}
					onValidFileSelection={onSelectFile}
				/>
			</Stack>
		</>
	);
}
