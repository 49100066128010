import { createFileRoute } from '@tanstack/react-router';

import { ForgotUsernamePage } from '@apple/features/auth-channel/pages/ForgotUsernamePage';
import { getEnv } from '@apple/utils/config/env';

const { APPLE_APP_TITLE } = getEnv();

export const Route = createFileRoute('/(public)/forgot-username')({
	component: ForgotUsernameRoute,
});

function ForgotUsernameRoute() {
	return <ForgotUsernamePage appName={APPLE_APP_TITLE} />;
}
