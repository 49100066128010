import { useCallback } from 'react';
import { createFileRoute } from '@tanstack/react-router';

import { requireAuth } from '@apple/features/auth';
import { ReturnsPage } from '@apple/features/order-channel/components/ReturnsPage';
import { useTranslation } from '@apple/lib/i18next';
import { TitleLayout } from '@apple/ui/layouts/TitleLayout';

export const Route = createFileRoute('/_authed/returns')({
	beforeLoad: args =>
		requireAuth(args, {
			requirePermission: 'AppleBrandedPrograms.Security.Features.Returns',
		}),
	component: ReturnsRoute,
});

function ReturnsRoute() {
	const navigate = Route.useNavigate();
	const { t } = useTranslation('returns');

	const onSubmit = useCallback(
		async (orderNumber: string) => {
			await navigate({ to: `/returns/${orderNumber}` });
		},
		[navigate],
	);

	return (
		<TitleLayout title={t('title')}>
			<ReturnsPage onSubmit={onSubmit} />
		</TitleLayout>
	);
}
