import { createFileRoute } from '@tanstack/react-router';

import { LogoutPage } from '@apple/features/auth';
import { getEnv } from '@apple/utils/config/env';

const env = getEnv();

export const Route = createFileRoute('/(public)/logout')({
	loader: ({ context }) => {
		if (context.auth.authenticated) {
			void context.auth.logout();
		}
	},
	component: LogoutRoute,
});

function LogoutRoute() {
	return <LogoutPage appName={env.APPLE_APP_TITLE} />;
}
