import log from 'loglevel';

import { configAppInsights } from '@apple/lib/app-insights';
import { configAxios } from '@apple/lib/axios';
import { configDayjs } from '@apple/lib/dayjs';
import { configLocalization } from '@apple/lib/i18next';
import { configLogging } from '@apple/lib/loglevel';
import { getEnv } from '@apple/utils/config/env';

export async function configApp() {
	const env = getEnv();
	const isDev = env.DEV;

	log.info(`Starting ${env.APPLE_APP_TITLE}:`, env);

	const { telemetry } = configAppInsights({
		instrumentationKey: env.APPLE_APPINSIGHTS_INSTRUMENTATION_KEY,
		roleTag: env.APPLE_APPINSIGHTS_ROLE_NAME,
	});
	const logger = configLogging(isDev ? log.levels.TRACE : log.levels.WARN, {
		// Override the default log level for specific loggers
		// ⚠️ MAKE SURE THESE ARE COMMENTED OUT BEFORE MERGING TO MAIN! ⚠️
		// TODO: Find a way to set these in .env.local or .env.development
		// auth: log.levels.WARN,
		// steps: log.levels.DEBUG,
		// cart: log.levels.DEBUG,
		// shop: log.levels.DEBUG,
		// validation: log.levels.DEBUG,
		// DataTable: log.levels.WARN,
		// form: log.levels.WARN,
		// announcement: log.levels.WARN,
		// 'cart-menu': log.levels.WARN,
	});
	configDayjs();
	await configLocalization({
		debug: isDev,
	});
	configAxios();

	return {
		telemetry,
		log: logger,
		env,
	};
}
