import { useEffect, useState } from 'react';
import { notifications } from '@mantine/notifications';
import { createFileRoute, useRouter } from '@tanstack/react-router';
import { z } from 'zod';

import { Lock } from '@apple/assets/icons';
import { useAuthContext } from '@apple/features/auth-channel/hooks/useAuthContext';
import { SessionExpiredPage } from '@apple/features/auth/pages/SessionExpiredPage';
import { useTranslation } from '@apple/lib/i18next';
import { getEnv } from '@apple/utils/config/env';

const { APPLE_APP_TITLE } = getEnv();

export const Route = createFileRoute('/(public)/session-expired')({
	validateSearch: z.object({
		returnUrl: z.string().optional(),
	}),
	loader: async ({ context }) => {
		if (context.auth.authenticated) {
			await context.auth.reset();
		}
	},
	component: SessionExpired,
});

function SessionExpired() {
	const { t } = useTranslation('auth');
	const { returnUrl } = Route.useSearch();
	const [redirecting, setRedirecting] = useState(false);
	const router = useRouter();
	const auth = useAuthContext();

	useEffect(() => {
		if (redirecting) {
			return;
		}

		async function redirectToLogin() {
			if (auth.authenticated) {
				return;
			}

			await router.navigate({
				to: '/login',
				search: { returnUrl: returnUrl },
				replace: true,
			});

			setRedirecting(true);
		}

		void redirectToLogin();
		notifications.show({
			id: 'session-expired',
			message: t('sessionExpired.message'),
			title: t('sessionExpired.title'),
			icon: <Lock />,
			autoClose: false,
		});
	}, [auth, returnUrl, redirecting, router, t]);

	return <SessionExpiredPage appName={APPLE_APP_TITLE} />;
}
