import { z } from 'zod';

import type { ExcelError } from '@apple/utils/files/models/excel';

/** @see: Manzanita.Web.Areas.Admin.Models.Bulk.BulkUploadItemViewModel */
export interface BulkUploadItem {
	rowNumber: number;
	action: string;
	isValid: boolean;
	validationErrors: string[];
}

/** @see: Manzanita.Web.Areas.Admin.Models.Bulk.BulkUploadResponseModel */
export interface BulkUploadResponse<T extends BulkUploadItem> {
	uploadedRows: T[];
	invalidRows: T[];
	fileId: number;
	criticalErrors: string[];
	numberOfImportedRows: number;
	numberOfInsertedRows: number;
	numberOfUpdatedRows: number;
	numberOfDeletedRows: number;
	numberOfIgnoredRows: number;
	numberOfInvalidRows: number;
}

/** @see: Manzanita.Web.Areas.Admin.Models.Bulk.BulkUploadItemViewModel */
export const bulkUploadItemSchema = z.object({
	rowNumber: z.number().int(),
	action: z.string().min(1),
	isValid: z.boolean(),
	validationErrors: z.array(z.string()),
}) satisfies z.ZodType<BulkUploadItem>;

export function createBulkUploadResponseSchema<
	BulkUploadItemSchema extends z.ZodType<BulkUploadItem>,
>(bulkUploadItem: BulkUploadItemSchema) {
	return z
		.object({
			uploadedRows: z.array(bulkUploadItem),
			invalidRows: z.array(bulkUploadItem),
			fileId: z.number(),
			criticalErrors: z.array(z.string()),
			numberOfImportedRows: z.number(),
			numberOfInsertedRows: z.number(),
			numberOfUpdatedRows: z.number(),
			numberOfDeletedRows: z.number(),
			numberOfIgnoredRows: z.number(),
			numberOfInvalidRows: z.number(),
		})
		.readonly();
}

function isBulkUpload<
	TItem extends Record<string, unknown>,
	TKey extends keyof TItem & string,
	TError extends ExcelError<TItem, TKey>,
>(errors: TError[] | BulkUploadItem[]): errors is BulkUploadItem[] {
	return errors.length > 0 && errors[0] !== undefined && 'validationErrors' in errors[0];
}

export function normalizeErrors<
	TItem extends Record<string, unknown>,
	TKey extends keyof TItem & string,
	TError extends ExcelError<TItem, TKey>,
>(errors: TError[] | BulkUploadItem[], keyProperty: TKey) {
	return isBulkUpload(errors)
		? errors.map(item => ({
				[keyProperty]: item.rowNumber,
				errors: { validationErrors: item.validationErrors },
			}))
		: errors;
}
