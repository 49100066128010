import { createFileRoute } from '@tanstack/react-router';

import { requireAuth } from '@apple/features/auth';

export const Route = createFileRoute('/_authed/_admin')({
	beforeLoad: args =>
		requireAuth(args, {
			requirePermission: 'Manzanita.Security.Features.Snapshot',
		}),
});
