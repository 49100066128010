import { useEffect, useState } from 'react';
import { LoadingOverlay } from '@mantine/core';
import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { useAuthContext } from '@apple/features/auth-channel/hooks/useAuthContext';

export const Route = createFileRoute('/(public)/reset-password')({
	component: TokenRoute,
	validateSearch: z.object({ token: z.string().optional() }),
	beforeLoad: ({ search }) => {
		return { token: search.token };
	},
});

function TokenRoute() {
	const [redirecting, setRedirecting] = useState(false);
	const auth = useAuthContext();
	const navigate = Route.useNavigate();
	const { token } = Route.useSearch();

	useEffect(() => {
		if (redirecting) {
			return;
		}

		async function redirectToResetPassword() {
			if (auth.authenticated) {
				await auth.logout();
			}

			await navigate({
				to: '/reset-password/form',
				search: { token: token },
				mask: { search: { token: undefined } },
			});

			setRedirecting(true);
		}

		void redirectToResetPassword();
	}, [auth, navigate, redirecting, token]);

	return <LoadingOverlay />;
}
