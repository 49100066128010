import { Text } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import {
	getCoreRowModel,
	getFacetedMinMaxValues,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table';
import type { SortingState } from '@tanstack/react-table';

import { icons } from '@apple/assets';
import { requireAuth } from '@apple/features/auth';
import { faqManagementListQueryOptions } from '@apple/features/faq';
import { FaqListItemAnswer } from '@apple/features/faq/components/FaqListItemAnswer';
import { useTranslation } from '@apple/lib/i18next';
import { DataTable, useTableState } from '@apple/ui/data-table';
import { ToolbarButton } from '@apple/ui/data-table/controls/Toolbar';
import { TableLayout } from '@apple/ui/layouts';
import { Link } from '@apple/ui/link';
import type { Faq, FaqFilters } from '@apple/features/faq';

export const Route = createFileRoute('/_authed/_admin/content/faq')({
	wrapInSuspense: true,
	component: FAQManagePageRoute,
	beforeLoad: args => {
		requireAuth(args, {
			requirePermission: 'Manzanita.Security.Features.ContentManagement',
		});
	},
});

const defaultFilters: FaqFilters = {};
const defaultSorting: SortingState = [{ id: 'rank', desc: false }];

function FAQManagePageRoute() {
	const { t } = useTranslation('content');
	const search = Route.useSearch();
	const navigate = Route.useNavigate();
	const faqQuery = useQuery(faqManagementListQueryOptions);

	const tableState = useTableState<Faq, FaqFilters>({
		search,
		navigate,
		defaultFilters: defaultFilters,
		defaultSorting: defaultSorting,
		fieldMap: [],
	});

	const table = useReactTable<Faq>({
		data: faqQuery.data,
		...tableState,
		getCoreRowModel: getCoreRowModel(),
		getFacetedMinMaxValues: getFacetedMinMaxValues(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
		getFacetedRowModel: getFacetedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		enableFilters: true,
		enableColumnFilters: true,
		enableSorting: true,
		columnResizeMode: 'onChange',

		columns: [
			{
				accessorKey: 'question',
				header: t('faqs.headers.question'),
				filter: {
					group: t('projects.filter-groups.details.title'),
				},
				size: 180,
				cell: ({ row }) => (
					<Link
						to='/content/faq/$faqId'
						children={row.original.question}
						params={{ faqId: row.original.id! }}
						underline='never'
					/>
				),
			},
			{
				accessorKey: 'answer',
				header: t('faqs.headers.answer'),
				size: 300,
				filter: {
					group: t('projects.filter-groups.details.title'),
				},
				cell: ({ row }) => <FaqListItemAnswer answer={row.original.answer} />,
			},
			{
				accessorKey: 'active',
				header: t('faqs.headers.status'),
				size: 50,
				filter: {
					label: t('faqs.labels.active'),
					group: t('projects.filter-groups.details.title'),
					variant: 'switch',
					getFilterDisplayValue: value => {
						return value === true ? t('faqs.labels.active') : t('faqs.labels.inactive');
					},
				},
				cell: ({ row }) => (
					<Text>
						{row.original.active ? t('faqs.labels.active') : t('faqs.labels.inactive')}
					</Text>
				),
			},
			{
				size: 50,
				accessorKey: 'rank',
				header: t('faqs.headers.rank'),
				filter: {
					group: t('projects.filter-groups.details.title'),
					variant: 'number-range-slider',
				},
			},
		],
	});

	return (
		<TableLayout
			table={table}
			title={t('faqs.title')}
			toolbarButtons={[
				<ToolbarButton
					key='add'
					tooltip={t('faqs.add.title')}
					icon={icons.AddIcon}
					onClick={() =>
						void navigate({
							to: '/content/faq/add',
							viewTransition: false,
						})
					}
				/>,
			]}
		>
			<DataTable table={table} loading={faqQuery.isFetching} />
		</TableLayout>
	);
}
