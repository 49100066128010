import { createFileRoute } from '@tanstack/react-router';

import { useAddressBookListingTable } from '@apple/features/address-book/management/hooks/useAddressBookListingTable';
import { requireAuth } from '@apple/features/auth';
import { useTranslation } from '@apple/lib/i18next';
import { DataTable } from '@apple/ui/data-table';
import { TableLayout } from '@apple/ui/layouts';

export const Route = createFileRoute('/_authed/_admin/manage/addresses')({
	component: AddressManagementRoute,
	beforeLoad: args =>
		requireAuth(args, {
			requirePermission: 'AppleBranded.Security.ShipToAddress.CanEditOtherUsersAddresses',
		}),
});

function AddressManagementRoute() {
	const { t } = useTranslation('address-book');
	const search = Route.useSearch();
	const navigate = Route.useNavigate();

	const { table, query } = useAddressBookListingTable({ search, navigate });
	return (
		<TableLayout title={t('manage.title')} table={table}>
			<DataTable table={table} loading={query.isFetching} />
		</TableLayout>
	);
}
