import { useCallback } from 'react';
import { Stack } from '@mantine/core';
import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { useAddressManagement } from '@apple/features/address-book/management/hooks/useAddressManagement';
import { addressBookQueries } from '@apple/features/address-book/management/queries';
import { AddressForm } from '@apple/features/address/components/form';
import { requireAuth } from '@apple/features/auth';
import { useTranslation } from '@apple/lib/i18next';
import { DetailsLayout } from '@apple/ui/layouts';
import type { AddressBookId } from '@apple/features/address-book';
import type { AddressBookItem } from '@apple/features/address/models';

export const Route = createFileRoute('/_authed/_admin/manage/addresses_/$addressBookId')({
	parseParams: ({ addressBookId }) => ({
		addressBookId: z.string().parse(addressBookId),
	}),
	beforeLoad: ({ params: { addressBookId }, ...args }) => {
		requireAuth(args, {
			requirePermission: 'AppleBranded.Security.ShipToAddress.CanEditOtherUsersAddresses',
		});

		return {
			addressBookItemQueryOptions: addressBookQueries.getAddressBookItem(addressBookId),
		};
	},
	loader: ({ context: { queryClient, addressBookItemQueryOptions } }) => {
		void queryClient.prefetchQuery(addressBookItemQueryOptions);
	},
	wrapInSuspense: true,
	component: AddressBookItemRoute,
});

const newAddressBookItem: AddressBookItem = {
	addressBookId: '',
	addressName: '',
	countryTaxId: '',
	phoneNumber: '',
	isDeleted: false,
	userId: 0,
	shippingAddress: {
		addressId: '',
		addressClassification: 'Residential',
		recipientName: '',
		attentionTo: '',
		addressLine1: '',
		addressLine2: '',
		addressLine3: '',
		city: '',
		stateOrProvince: '',
		countryCode: 'US',
		postalCode: '',
		isDeleted: false,
	},
	internationalShippingAddress: {
		addressId: '',
		addressClassification: 'Residential',
		recipientName: '',
		attentionTo: '',
		addressLine1: '',
		addressLine2: '',
		addressLine3: '',
		city: '',
		stateOrProvince: '',
		countryCode: 'US',
		postalCode: '',
		isDeleted: false,
	},
};

function AddressBookItemRoute() {
	const { t } = useTranslation('address-book');
	const { addressBookItemQueryOptions } = Route.useRouteContext();
	const addressBookItemQuery = useSuspenseQuery(addressBookItemQueryOptions);
	const { save, remove } = useAddressManagement(addressBookItemQuery.data);

	const navigate = Route.useNavigate();

	const finalAddress: AddressBookItem = {
		...addressBookItemQuery.data,
		addressBookId: addressBookItemQuery.data.addressBookId,
		shippingAddress: {
			...addressBookItemQuery.data.address,
			addressLine1: addressBookItemQuery.data.address.line1 ?? '',
			addressLine2: addressBookItemQuery.data.address.line2 ?? '',
			addressLine3: addressBookItemQuery.data.address.line3 ?? '',
			attentionTo: addressBookItemQuery.data.address.recipientName2 ?? '',
			recipientName: addressBookItemQuery.data.address.recipientName1 ?? '',
		},
		internationalShippingAddress: !addressBookItemQuery.data.internationalAddress
			? newAddressBookItem.internationalShippingAddress
			: {
					...addressBookItemQuery.data.internationalAddress,
					addressLine1: addressBookItemQuery.data.internationalAddress?.line1 ?? '',
					addressLine2: addressBookItemQuery.data.internationalAddress.line2 ?? '',
					addressLine3: addressBookItemQuery.data.internationalAddress.line3 ?? '',
					attentionTo:
						addressBookItemQuery.data.internationalAddress.recipientName2 ?? '',
					recipientName:
						addressBookItemQuery.data.internationalAddress.recipientName1 ?? '',
				},
	};

	const handleSave = useCallback(
		async (addressBookItem: AddressBookItem) => {
			await save.mutateAsync(addressBookItem, {
				onSuccess: () => {
					void navigate({
						to: '/manage/addresses',
					});
				},
			});
		},
		[save, navigate],
	);

	const handleRemove = useCallback(
		async (addressBookId: AddressBookId) => {
			await remove.mutateAsync(addressBookId, {
				onSuccess: () => {
					void navigate({
						to: '/manage/addresses',
					});
				},
			});
		},
		[navigate, remove],
	);

	return (
		<DetailsLayout title={t('manage.edit')}>
			<Stack>
				<AddressForm
					addressBookItem={finalAddress}
					onSubmit={handleSave}
					onDelete={addressBookId => void handleRemove(addressBookId)}
					showAddressBookButton={false}
					showDeleteButton={true}
				/>
			</Stack>
		</DetailsLayout>
	);
}
