import { z } from 'zod';

export interface Env {
	readonly BASE_URL: string;
	readonly MODE: 'development' | 'production';
	readonly DEV: boolean;
	readonly PROD: boolean;

	readonly APPLE_API_URL: string;
	readonly APPLE_APP_TITLE: string;
	readonly APPLE_CDN_URL: string;
	readonly APPLE_APPINSIGHTS_INSTRUMENTATION_KEY: string;
	readonly APPLE_APPINSIGHTS_ROLE_NAME: string;
	readonly APPLE_REACT_STRICT_MODE: boolean;
	readonly APPLE_DEV_API_REQUEST_DELAY_MS?: number;
}

declare global {
	interface Window {
		env: Env;
	}
}

let instance: Env;

const booleanStringSchema = z
	.union([z.boolean(), z.literal('true'), z.literal('false'), z.literal('1'), z.literal('0')])
	.transform(value => value === true || value === 'true' || value === '1');

export function getEnv(): Env {
	if (!instance) {
		instance = {
			...import.meta.env,
			...window.env,
			APPLE_REACT_STRICT_MODE: booleanStringSchema.parse(
				import.meta.env?.APPLE_REACT_STRICT_MODE ??
					window.env?.APPLE_REACT_STRICT_MODE ??
					true,
			),
			APPLE_DEV_API_REQUEST_DELAY_MS: z.coerce
				.number()
				.optional()
				.parse(
					import.meta.env?.APPLE_DEV_API_REQUEST_DELAY_MS ??
						window.env?.APPLE_DEV_API_REQUEST_DELAY_MS,
				),
		};
	}

	return instance;
}
