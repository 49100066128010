import { useCallback } from 'react';
import { notifications } from '@mantine/notifications';
import { useMutation, useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { getLogger } from 'loglevel';
import { z } from 'zod';

import { ErrorIcon, Success } from '@apple/assets/icons';
import { requireAuth } from '@apple/features/auth';
import { faqManagementQueryOptions, faqQueryKeys } from '@apple/features/faq';
import { FaqForm } from '@apple/features/faq/components/FaqForm';
import { deleteFaq, saveFaq } from '@apple/features/faq/management';
import { useTranslation } from '@apple/lib/i18next';
import { DetailsLayout } from '@apple/ui/layouts';

const log = getLogger('manage-faq');

export const Route = createFileRoute('/_authed/_admin/content/faq_/$faqId')({
	component: EditFaqRoute,
	parseParams: ({ faqId }) => ({
		faqId: z.string().parse(faqId),
	}),
	beforeLoad: args => {
		requireAuth(args, {
			requirePermission: 'Manzanita.Security.Features.ContentManagement',
		});
	},
	wrapInSuspense: true,
});

function EditFaqRoute() {
	const { t } = useTranslation('content');
	const { queryClient } = Route.useRouteContext();
	const { faqId } = Route.useParams();
	const faqQuery = useSuspenseQuery(faqManagementQueryOptions(faqId));

	const navigate = Route.useNavigate();

	const onSuccessfulChange = useCallback(
		async (message: string) => {
			await queryClient.invalidateQueries({
				queryKey: faqQueryKeys.all,
			});

			notifications.show({
				message: message,
				icon: <Success />,
				color: 'green',
				autoClose: 5000,
			});
			await navigate({
				to: '/content/faq',
			});
		},
		[navigate, queryClient],
	);

	const onFailedChange = useCallback(
		(error: Error) => {
			//TODO: Have the API return model validation
			//if (!(error instanceof ServerValidationError)) {
			log.error(error);
			notifications.show({
				message: t('common:error.generic'),
				icon: <ErrorIcon />,
				color: 'red',
				autoClose: 5000,
			});
		},
		[t],
	);

	const updateFAQMutation = useMutation({
		mutationFn: saveFaq,
		onSuccess: async () => {
			await onSuccessfulChange(t('faqs.notifications.success.save'));
		},
		onError: onFailedChange,
	});

	const deleteFAQMutation = useMutation({
		mutationFn: deleteFaq,
		onSuccess: async () => await onSuccessfulChange(t('faqs.notifications.success.delete')),
		onError: onFailedChange,
	});

	return (
		<DetailsLayout title={t('faqs.edit.title')}>
			<FaqForm
				faq={faqQuery.data}
				saveMutation={updateFAQMutation}
				deleteMutation={deleteFAQMutation}
			/>
		</DetailsLayout>
	);
}
