import { createFileRoute } from '@tanstack/react-router';

import { useAppleChannelAdminDashboard } from '@apple/channel/hooks/useAppleChannelAdminDashboard';
import { AdminDashboard } from '@apple/channel/routes/_authed/-dashboard/admin';
import { UserHomePage } from '@apple/channel/routes/_authed/-dashboard/user';
import { requireAuth } from '@apple/features/auth';
import { useCartItemCount } from '@apple/features/cart-channel/hooks/useCartItemCount';
import { useTranslation } from '@apple/lib/i18next';
import { TitleLayout } from '@apple/ui/layouts/TitleLayout';
import { Link } from '@apple/ui/link';

export const Route = createFileRoute('/_authed/')({
	beforeLoad: args => requireAuth(args),
	component: DashboardRoute,
});

export function DashboardRoute() {
	const { auth } = Route.useRouteContext();
	return auth.hasPermissions(['Manzanita.Security.Features.Snapshot']) ? (
		<AdminDashboardView />
	) : (
		<UserDashboardView />
	);
}

export function UserDashboardView() {
	const { auth } = Route.useRouteContext();
	const { t } = useTranslation('user-home');
	const search = Route.useSearch();
	const navigate = Route.useNavigate();
	const cartItemCount = useCartItemCount();

	return (
		!auth.hasPermissions(['Manzanita.Security.Features.Snapshot']) && (
			<TitleLayout title={t('welcome', { name: auth.profile?.firstName })}>
				<UserHomePage
					navigate={navigate}
					search={search}
					cartItemCount={cartItemCount.data}
				/>
			</TitleLayout>
		)
	);
}

function AdminDashboardView() {
	const { t } = useTranslation('admin-dashboard');
	const { cards } = useAppleChannelAdminDashboard();
	const { auth } = Route.useRouteContext();

	return (
		<TitleLayout title={t('welcome', { name: auth.profile?.firstName })}>
			<AdminDashboard
				cards={cards}
				renderOrderLink={({ row }) => (
					<Link
						to='/approvals/orders/$poNumber'
						params={{ poNumber: row.original.purchaseOrderNumber }}
						title={t('order:searchResults.table.links.viewOrderDetails')}
						children={row.original.purchaseOrderNumber}
					/>
				)}
			/>
		</TitleLayout>
	);
}
