import { z } from 'zod';

/** Manzanita.DataModels.OrderCommentStatus */
export enum OrderCommentStatus {
	Sent = 1,
	Received = 2,
}

/** Manzanita.DataModels.OrderCommentStatus */
export const orderCommentStatusSchema = z.nativeEnum(OrderCommentStatus);

export type OrderStatus =
	| 'InProcess'
	| 'Shipped'
	| 'PartialShipped'
	| 'Backordered'
	| 'OrderReview'
	| 'Rejected'
	| 'Cancelled'
	| 'PartialCancelled'
	| 'Created'
	| 'NewOrder'
	| 'Submitted'
	| 'SubmissionPending'
	| 'OnHold'
	| 'Confirmed'
	| 'Packing';

export type OrderStatusFilter = Exclude<
	OrderStatus,
	'Created' | 'NewOrder' | 'Submitted' | 'SubmissionPending' | 'OnHold' | 'Confirmed' | 'Packing'
>;

export const orderStatusSchema = z.enum([
	'InProcess',
	'Shipped',
	'PartialShipped',
	'Backordered',
	'OrderReview',
	'Rejected',
	'Cancelled',
	'PartialCancelled',
	'Created',
	'NewOrder',
	'Submitted',
	'SubmissionPending',
	'OnHold',
	'Confirmed',
	'Packing',
]) satisfies z.ZodType<OrderStatus>;

export const orderStatusFilterSchema = orderStatusSchema.exclude([
	'Created',
	'NewOrder',
	'Submitted',
	'SubmissionPending',
	'OnHold',
	'Confirmed',
	'Packing',
]) satisfies z.ZodType<OrderStatusFilter>;
