import { memo, useMemo } from 'react';
import { Group, Text, useMantineTheme } from '@mantine/core';
import type { TextProps } from '@mantine/core';

import { icons } from '@apple/assets';
import { useTranslation } from '@apple/lib/i18next';

type Props = {
	status: string;
	isOrderMarkedForCancellation?: boolean;
	size?: TextProps['size'];
};

export const OrderStatusDisplay = memo(
	({ status, isOrderMarkedForCancellation = false, size }: Props) => {
		const { t } = useTranslation('order');
		const theme = useMantineTheme();
		const statusColor = useMemo(
			() => theme.other.getOrderStatusColor(status, isOrderMarkedForCancellation, t),
			[theme.other, status, isOrderMarkedForCancellation, t],
		);

		return (
			<Group wrap='nowrap' align='center' justify='flex-start' gap={0}>
				<Text c={statusColor} size={size} span>
					{status}
				</Text>
				{isOrderMarkedForCancellation || status === t('orderStatus.orderReview') ? (
					<icons.Warning
						size={size}
						c='yellow.7'
						tooltip={
							isOrderMarkedForCancellation
								? t('statusTooltips.cancellationRequested')
								: t('statusTooltips.orderReview')
						}
					/>
				) : null}
			</Group>
		);
	},
);
OrderStatusDisplay.displayName = 'OrderStatusDisplay';
