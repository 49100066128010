import { keepPreviousData, queryOptions } from '@tanstack/react-query';

import { getApprovalOrders } from '@apple/features/order-channel/api/approval';

export const getApprovalOrdersOptions = queryOptions({
	queryKey: ['approval-orders'],
	queryFn: ({ signal }) => getApprovalOrders(signal),
	placeholderData: keepPreviousData,
	throwOnError: true,
	retry: false,
	initialData: [],
});
