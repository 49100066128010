import { useCallback } from 'react';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { z } from 'zod';

import { validateToken } from '@apple/features/auth';
import { FirstLoginPage } from '@apple/features/auth/pages/FirstLoginPage';
import { getEnv } from '@apple/utils/config/env';

const { APPLE_APP_TITLE } = getEnv();

export const Route = createFileRoute('/(public)/first-login_/form')({
	component: FirstLogonRoute,
	validateSearch: z.object({ token: z.string().optional() }),
	beforeLoad: ({ search: { token } }) => {
		if (!token) {
			throw redirect({ to: '/login', replace: true });
		}

		return { token };
	},
	loader: async ({ context: { token } }) => {
		const data = await validateToken(token);

		return { data: data };
	},
});

function FirstLogonRoute() {
	const { data } = Route.useLoaderData();
	const navigate = Route.useNavigate();

	const navigateToResetPassword = useCallback(() => {
		void navigate({ to: '/forgot-password' });
	}, [navigate]);

	const navigateToMain = useCallback(() => {
		void navigate({ to: '/' });
	}, [navigate]);

	return (
		<FirstLoginPage
			appName={APPLE_APP_TITLE}
			data={data}
			token={data.token}
			navigateToResetPassword={navigateToResetPassword}
			navigateToMain={navigateToMain}
		/>
	);
}
