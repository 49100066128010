import { Anchor, Group, Stack, Text } from '@mantine/core';
import moment from 'moment';

import { icons } from '@apple/assets';
import { useTranslation } from '@apple/lib/i18next';

import type { BulkOrderInfo } from '../models/models';

export type SavedCartsListProps = {
	savedCarts: BulkOrderInfo[] | undefined;
	onClick: (bulkOrderId: string) => void;
	onRemoveClick: (bulkOrderId: string) => void;
};

export function SavedCartsList({ savedCarts, onClick, onRemoveClick }: SavedCartsListProps) {
	const { t } = useTranslation('shop');

	const savedOrdersDates = savedCarts?.map(x => ({
		bulkOrderId: x.bulkOrderId,
		date: moment(x.dateSaved).format('L LT'),
	}));

	return (
		<>
			<Stack gap={0} mx='lg' mt='lg'>
				<Text fw='bold'>{t('cart.labels.savedCarts')}</Text>
				{savedOrdersDates?.map(({ bulkOrderId, date }) => (
					<Group key={bulkOrderId} wrap='nowrap' justify='space-between'>
						<Anchor onClick={() => onClick(bulkOrderId)}>
							<Text size='sm'>{date}</Text>
						</Anchor>
						<icons.Trash
							data-testid='removeSavedCart'
							variant='transparent'
							onClick={() => onRemoveClick(bulkOrderId)}
							c='red'
						/>
					</Group>
				))}
			</Stack>
		</>
	);
}
