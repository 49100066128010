import { useCallback } from 'react';
import { Center, Divider, Group, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useQueries } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { getFacetedMinMaxValues, getFacetedUniqueValues } from '@tanstack/table-core';
import type { SortingState } from '@tanstack/table-core';
import type { TFunction } from 'i18next';

import { AddIcon, DownloadIcon, ExportIcon, Upload } from '@apple/assets/icons';
import { requireAuth } from '@apple/features/auth';
import { plantQueryOptions } from '@apple/features/plants/queries/queries';
import {
	deleteProducts,
	getProductManagementTemplateUrl,
	saveProducts,
	uploadProducts,
} from '@apple/features/product-channel/api/management';
import { productVisibilitySchema } from '@apple/features/product-channel/models/listing';
import { getProductDataTableQueryOptions } from '@apple/features/product-channel/queries/odata';
import { productQueryKeys } from '@apple/features/product/queries/management';
import { useTranslation } from '@apple/lib/i18next';
import { DataTable, useTableDownload, useTableState } from '@apple/ui/data-table';
import { ToolbarButton } from '@apple/ui/data-table/controls/Toolbar';
import { TableLayout } from '@apple/ui/layouts';
import { Link } from '@apple/ui/link';
import { ExcelUploadDialog, useFileDownload } from '@apple/utils/files';
import { FormattedBoolean, FormattedPrice } from '@apple/utils/globalization';
import type {
	ProductFilters,
	ProductListing,
} from '@apple/features/product-channel/models/listing';
import type { AppleBrandedProduct } from '@apple/features/product-channel/models/management';
import type { CurrencyCode } from '@apple/utils/globalization';

export const Route = createFileRoute('/_authed/_admin/manage/products')({
	beforeLoad: args => {
		requireAuth(args, {
			requirePermission: 'Manzanita.Security.Features.ProductManagement.Read',
		});
	},
	component: ProductListRoute,
});

const defaultFilters: ProductFilters = {
	isActive: true,
	isEnabledInErp: true,
	isEndOfLifeComplete: false,
	requiresApproval: false,
};
const defaultSorting: SortingState = [{ id: 'sku', desc: true }];

function productVisibilityDisplay(value: unknown, t: TFunction<'manage', undefined>) {
	switch (value) {
		case 'Public':
			return t('product:details.visibility.public');
		case 'Confidential':
			return t('product:details.visibility.confidential');
		case 'CallCenter':
			return t('product:details.visibility.callCenter');
		default:
			return '';
	}
}

function ProductListRoute() {
	const { t } = useTranslation('manage');
	const [uploadOpen, uploadActions] = useDisclosure(false);
	const excelTemplate = useFileDownload({
		method: 'get',
		url: getProductManagementTemplateUrl(),
		fallbackFilename: 'products.xlsx',
		errorTitle: t('common:error.title'),
		errorMessage: t('common:error.fileDownload'),
	});
	const { queryClient } = Route.useRouteContext();
	const search = Route.useSearch();
	const navigate = Route.useNavigate();

	const tableState = useTableState<ProductListing, ProductFilters>({
		search,
		navigate,
		defaultFilters,
		defaultSorting,
		fieldMap: [],
	});

	const [productQuery, plantsQuery] = useQueries({
		queries: [
			getProductDataTableQueryOptions<ProductFilters>({
				pagination: tableState.state.pagination,
				filters: tableState.currentFilterData,
			}),
			plantQueryOptions.plants,
		],
	});

	const table = useReactTable<ProductListing>({
		data: productQuery.data.rows,
		rowCount: productQuery.data.totalRowCount,
		...tableState,
		enableFilters: true,
		enableColumnFilters: true,
		enableSorting: true,
		manualFiltering: true,
		manualSorting: true,
		manualPagination: true,
		getCoreRowModel: getCoreRowModel(),
		getFacetedMinMaxValues: (table, columnId) => {
			switch (columnId) {
				case 'availableQuantity':
					return () => [0, 100];
				case 'quantityLimit':
					return () => [0, 100];
				default:
					return getFacetedMinMaxValues<ProductListing>()(table, columnId);
			}
		},
		getFacetedUniqueValues: (table, columnId) => {
			switch (columnId) {
				case 'warehouseCode':
					return () => new Map(plantsQuery.data?.map(x => [x.warehouseCode, 1]));
				case 'availability':
					return () => new Map([undefined, 'inStock', 'outOfStock'].map(x => [x, 1]));
				case 'hasQuantityLimit':
					return () => new Map([undefined, 'yes', 'no'].map(x => [x, 1]));
				case 'visibility':
					return () => new Map(productVisibilitySchema._def.values.map(x => [x, 1]));
				default:
					return getFacetedUniqueValues<ProductListing>()(table, columnId);
			}
		},
		columnResizeMode: 'onChange',
		columns: [
			{
				id: 'partNumber',
				enableColumnFilter: true,
				accessorKey: 'sku',
				header: t('products.fields.sku'),
				size: 200,
				minSize: 200,
				filter: {
					group: t('products.filterGroups.keywordSearch'),
					sortOrder: 2,
				},
				cell: ({ row }) => (
					<Link
						to='/manage/products/$itemId'
						params={{ itemId: row.original.itemId }}
						title={t('products.links.view')}
					>
						<Text size='sm'>{row.original.sku}</Text>
					</Link>
				),
			},
			{
				id: 'name',
				accessorKey: 'productName',
				header: t('products.fields.productName'),
				enableColumnFilter: true,
				filter: {
					group: t('products.filterGroups.keywordSearch'),
					sortOrder: 1,
				},
				size: 450,
				cell: ({ row }) => (
					<Tooltip label={row.original.productName}>
						<Text lineClamp={1}>{row.original.productName}</Text>
					</Tooltip>
				),
			},
			{
				accessorKey: 'warehouseCode',
				header: t('products.fields.warehouseCode'),
				enableColumnFilter: true,
				filter: {
					group: t('products.filterGroups.availability'),
					variant: 'select',
					sortOrder: 6,
					getFilterDisplayValue: value =>
						plantsQuery.data?.find(x => x.warehouseCode === value)?.name ??
						String(value),
				},

				// TODO: Replace with remote data
				// getUniqueValues: () => ['N800', 'E800', 'A804', 'A801', '777'],
				size: 100,
			},
			{
				accessorKey: 'commercialPrice',
				header: t('products.fields.commercialPrice'),
				enableColumnFilter: false,
				size: 200,
				minSize: 200,
				cell: ({ row }) => (
					<FormattedPrice
						value={row.original.commercialPrice}
						currency={row.original.currencyCode as CurrencyCode}
					/>
				),
			},
			{
				accessorKey: 'commercialPriceUSD',
				header: t('products.fields.commercialPriceUSD'),
				enableColumnFilter: false,
				size: 200,
				minSize: 200,
				cell: ({ row }) => (
					<FormattedPrice value={row.original.commercialPriceUSD} currency={'USD'} />
				),
			},
			{
				accessorKey: 'isActive',
				header: t('products.fields.isActive'),
				size: 75,
				filter: {
					group: t('products.filterGroups.availability'),
					variant: 'switch',
					sortOrder: 3,
				},
				meta: {
					hideColumn: false,
				},
				cell: ({ row }) => (
					<Center>
						<FormattedBoolean value={row.original.isActive} useIcon />
					</Center>
				),
			},
			{
				id: 'visibility',
				accessorKey: 'visibility',
				header: t('products.fields.visibility'),
				enableColumnFilter: true,
				filter: {
					variant: 'select',
					group: t('products.filterGroups.availability'),
					sortOrder: 4,
					getFilterDisplayValue: x => productVisibilityDisplay(x, t),
				},
				cell: ({ row }) => productVisibilityDisplay(row.original.visibility, t),
				size: 110,
			},
			{
				accessorKey: 'quantityLimit',
				header: t('products.fields.quantityLimit'),
				enableColumnFilter: false,
				size: 100,
			},
			{
				id: 'hasQuantityLimit',
				accessorKey: 'quantityLimit',
				header: t('products.filters.hasQuantityLimit.label'),
				meta: {
					hideColumn: true,
				},
				enableColumnFilter: true,
				filter: {
					group: t('products.filterGroups.approvals'),
					variant: 'segmented-switch',
					sortOrder: 11,
					getFilterDisplayValue: x => {
						switch (x) {
							case 'yes':
								return t('common:label.yes');
							case 'no':
								return t('common:label.no');
							default:
								return t('common:label.all');
						}
					},
				},
			},
			{
				accessorKey: 'isBackorderable',
				header: t('products.fields.backorderable'),
				filter: {
					group: t('products.filterGroups.availability'),
					variant: 'false-only-switch',
					sortOrder: 5,
					label: t('products.filters.notBackorderable'),
				},
				enableColumnFilter: true,
				cell: ({ row }) => (
					<Group justify='center'>
						<FormattedBoolean value={row.original.isBackorderable} useIcon />
					</Group>
				),
			},
			{
				id: 'availableQuantity',
				accessorKey: 'availableQuantity',
				header: t('products.fields.atp'),
				enableColumnFilter: false,
				size: 100,
			},
			{
				id: 'availability',
				accessorKey: 'availableQuantity',
				header: t('products.filters.availability.label'),
				meta: {
					hideColumn: true,
				},
				enableColumnFilter: true,
				filter: {
					group: t('products.filterGroups.availability'),
					variant: 'segmented-switch',
					sortOrder: 7,
					getFilterDisplayValue: x => {
						switch (x) {
							case 'inStock':
								return t('products.filters.availability.inStock');
							case 'outOfStock':
								return t('products.filters.availability.outOfStock');
							default:
								return t('common:label.all');
						}
					},
				},
			},
			{
				accessorKey: 'isEndOfLifeComplete',
				enableColumnFilter: true,
				header: t('products.fields.isEndOfLifeComplete'),

				filter: {
					group: t('products.filterGroups.availability'),
					variant: 'switch',
					sortOrder: 8,
				},
				meta: {
					hideColumn: true,
				},
			},
			{
				accessorKey: 'isEnabledInErp',
				header: t('products.fields.isEnabledErp'),
				filter: {
					group: t('products.filterGroups.availability'),
					variant: 'switch',
					sortOrder: 9,
				},
				meta: {
					hideColumn: true,
				},
			},
			{
				accessorKey: 'hasImages',
				enableColumnFilter: true,
				header: t('products.fields.hasImages'),
				filter: {
					group: t('products.filterGroups.availability'),
					variant: 'false-only-switch',
					sortOrder: 10,
					label: t('products.filters.missingImages'),
				},
				meta: {
					hideColumn: true,
				},
			},
			{
				accessorKey: 'requiresApproval',
				header: t('products.fields.approvalRequired'),
				filter: {
					group: t('products.filterGroups.approvals'),
					variant: 'switch',
					sortOrder: 12,
				},
				meta: {
					hideColumn: false,
				},
				cell: ({ row }) => (
					<Center>
						<FormattedBoolean value={row.original.requiresApproval} useIcon />
					</Center>
				),
			},
		],
	});

	const tableDownload = useTableDownload({
		table,
		queryOptionsFn: getProductDataTableQueryOptions,
	});

	const handleExport = useCallback(() => {
		void tableDownload.download({
			filters: tableState.currentFilterData,
			fileName: 'Products',
		});
	}, [tableDownload, tableState.currentFilterData]);

	const handleUploadConfirmed = useCallback(async () => {
		await Promise.all([
			queryClient.resetQueries({
				queryKey: productQueryKeys.all,
			}),
			queryClient.resetQueries({
				queryKey: productQueryKeys.unassignedSkus,
			}),
		]);
		await productQuery.refetch();
	}, [productQuery, queryClient]);

	return (
		<TableLayout
			title={t('products.title')}
			table={table}
			toolbarButtons={[
				<ToolbarButton
					key='add'
					tooltip={t('products.buttons.add.tooltip')}
					icon={AddIcon}
					onClick={() => {
						void navigate({ to: '/manage/products/add' });
					}}
				/>,
				<Divider key='add-divider' orientation='vertical' mx='xs' />,
				<ToolbarButton
					key='download'
					tooltip={t('products.buttons.download.tooltip')}
					icon={DownloadIcon}
					loading={excelTemplate.downloading}
					onClick={() => void excelTemplate.download()}
				/>,
				<ToolbarButton
					key='upload'
					tooltip={t('products.buttons.upload.tooltip')}
					icon={Upload}
					disabled={uploadOpen}
					onClick={uploadActions.open}
				/>,
				<Divider key='export-divider' orientation='vertical' mx='xs' />,
				<ToolbarButton
					key='export'
					loading={tableDownload.downloading}
					tooltip={t('products.buttons.export.tooltip')}
					icon={ExportIcon}
					onClick={handleExport}
				/>,
			]}
		>
			<DataTable table={table} variant='apple-table' loading={productQuery.isFetching} />
			<ExcelUploadDialog<AppleBrandedProduct, 'itemId'>
				keyProperty={'itemId'}
				keyLabel={t('products.fields.sku')}
				opened={uploadOpen}
				close={uploadActions.close}
				onUpload={uploadProducts}
				onSave={products => saveProducts(products, 'N/A')}
				onDelete={itemIds => deleteProducts(itemIds, 'N/A')}
				onConfirmed={handleUploadConfirmed}
			/>
		</TableLayout>
	);
}
