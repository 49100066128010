import { Text } from '@mantine/core';
import { useQueries } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { useReactTable } from '@tanstack/react-table';
import {
	getCoreRowModel,
	getExpandedRowModel,
	getFacetedMinMaxValues,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	getSortedRowModel,
} from '@tanstack/table-core';

import { AddIcon } from '@apple/assets/icons';
import { requireAuth } from '@apple/features/auth';
import { getMerchandiserDataTableQueryOptions } from '@apple/features/merchandiser';
import { useTranslation } from '@apple/lib/i18next';
import { DataTable, useTableState } from '@apple/ui/data-table';
import { ToolbarButton } from '@apple/ui/data-table/controls/Toolbar';
import { TableLayout } from '@apple/ui/layouts';
import { Link } from '@apple/ui/link';
import { globalizationQueryOptions } from '@apple/utils/globalization';
import type { MerchandiserFilters, MerchandiserListing } from '@apple/features/merchandiser';

export const Route = createFileRoute('/_authed/_admin/manage/merchandisers')({
	beforeLoad: args => {
		requireAuth(args, {
			requirePermission:
				'AppleBrandedPrograms.Security.Features.AppleMerchandisersManagement.Read',
		});
	},
	component: MerchandiserManageRoute,
});

function MerchandiserManageRoute() {
	const { t } = useTranslation('manage');
	const search = Route.useSearch();
	const navigate = Route.useNavigate();

	const tableState = useTableState<MerchandiserListing, MerchandiserFilters>({
		search,
		navigate,
		defaultFilters: {},
		defaultSorting: [{ id: 'merchandiserName', desc: true }],
		fieldMap: [],
	});

	const [merchandisersQuery, countriesQuery] = useQueries({
		queries: [
			getMerchandiserDataTableQueryOptions({
				filters: tableState.currentFilterData,
				pagination: tableState.state.pagination,
				commonFilters: {},
			}),
			globalizationQueryOptions.countries,
		],
	});

	const table = useReactTable<MerchandiserListing>({
		data: merchandisersQuery.data.rows,
		rowCount: merchandisersQuery.data.totalRowCount,
		...tableState,
		enableFilters: true,
		enableColumnFilters: true,
		enableSorting: true,
		manualFiltering: true,
		manualSorting: true,
		manualPagination: true,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		getFacetedMinMaxValues: getFacetedMinMaxValues(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: (table, columnId) => {
			switch (columnId) {
				case 'countryName':
					return () => new Map(countriesQuery.data?.map(x => [x.Value, 1]));
				default:
					return getFacetedUniqueValues<MerchandiserListing>()(table, columnId);
			}
		},
		columnResizeMode: 'onChange',
		columns: [
			{
				id: 'merchandiserName',
				accessorKey: 'merchandiserName',
				header: t('merchandisers.fields.merchandiserName'),
				filter: {
					group: t('merchandisers.filterGroups.details'),
				},
				cell: ({ row }) => (
					<Link
						to='/manage/merchandisers/$locationId'
						params={{ locationId: row.original.id }}
						title={t('merchandisers.links.view')}
					>
						<Text size='sm'>{row.original.merchandiserName}</Text>
					</Link>
				),
			},
			{
				id: 'appleId',
				accessorKey: 'appleId',
				header: t('merchandisers.fields.appleId'),
				size: 450,
				filter: {
					group: t('merchandisers.filterGroups.details'),
				},
			},
			{
				accessorKey: 'countryName',
				header: t('merchandisers.fields.countryName'),
				size: 100,
				filter: {
					group: t('merchandisers.filterGroups.details'),
					variant: 'select',
					getFilterDisplayValue: value =>
						countriesQuery.data?.find(x => x.Key === value)?.Value ?? String(value),
				},
			},
		],
	});

	return (
		<TableLayout
			table={table}
			title={t('merchandisers.title')}
			toolbarButtons={[
				<ToolbarButton
					key='add'
					tooltip={t('merchandisers.buttons.add.tooltip')}
					icon={AddIcon}
					onClick={() =>
						void navigate({
							to: '/manage/merchandisers/add',
						})
					}
				/>,
			]}
		>
			<DataTable table={table} loading={merchandisersQuery.isFetching} />
		</TableLayout>
	);
}
