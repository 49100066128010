import axios from 'axios';
import { OdataQueryBuilder } from 'odata-builder';
import { z } from 'zod';
import type { PaginationState, RowData } from '@tanstack/react-table';

import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, getPageState } from '@apple/utils/pagination';
import type { CommonFilters } from '@apple/utils/filters';
import type { PageState } from '@apple/utils/pagination';
import type { UrlPath } from '@apple/utils/url';

export const DEFAULT_ODATA_PAGE_STATE = {
	pageIndex: DEFAULT_PAGE_INDEX,
	pageSize: DEFAULT_PAGE_SIZE,
	rows: [],
	totalPageCount: 0,
	totalRowCount: 0,
} as const satisfies PageState<unknown>;

export const DEFAULT_ODATA_PAGE_RESULT = {
	count: 0,
	nextPageLink: null,
	items: [],
} as const satisfies ODataPageResult<unknown>;

export type ODataQueryOptions<
	Row extends RowData,
	RowSchema extends z.ZodType<Row> = z.ZodType<Row>,
> = {
	urlPath: UrlPath;
	pagination: PaginationState;
	signal: AbortSignal;
	rowSchema: RowSchema;
	builderFn?: (odataBuilder: OdataQueryBuilder<Row>) => void;
	// TODO: Could this be merged into the Row model and then split out into separate types in the execODataQuery function?
	commonFilters?: CommonFilters;
};

// export type PageResult<T> = z.infer<
// 	ReturnType<typeof normalizeODataPageResultSchema<z.ZodType<T>>>
// >;

export async function execODataQuery<
	Row extends RowData,
	RowSchema extends z.ZodType<Row> = z.ZodType<Row>,
>(options: ODataQueryOptions<Row, RowSchema>): Promise<PageState<Row>> {
	const odataQueryBuilder = new OdataQueryBuilder<Row>()
		.count(true)
		.skip(options.pagination.pageIndex * options.pagination.pageSize)
		.top(options.pagination.pageSize);

	options.builderFn?.(odataQueryBuilder);

	let odataQuery = odataQueryBuilder.toQuery().replace('/$count?', '?$count=true&');

	// Append perspective filters to the query
	if (options.commonFilters) {
		const commonFilterParams = Object.entries(options.commonFilters)
			.map(
				([key, value]) =>
					`${encodeURIComponent(`perspectiveFilters[${key}]`)}=${encodeURIComponent(value as string)}`,
			)
			.join('&');
		odataQuery += `&${commonFilterParams}`;
	}

	const odataResponse = await axios.get<unknown>(`${options.urlPath}${odataQuery}`, {
		signal: options.signal,
	});

	const schema = createODataPageResultSchema<Row>(options.rowSchema);
	const result = schema.parse(odataResponse.data);

	return getPageState({
		rowSchema: options.rowSchema,
		state: options.pagination,
		rows: result.items,
		totalRowCount: result.count ?? 0,
	});
}

/** @see Microsoft.AspNet.OData.PageResult<T> */
export interface ODataPageResult<T> {
	/** The total number of items that match the query (not just the number of items in this page) */
	count?: number;
	nextPageLink: string | null;
	items: T[];
}

/**
 * @see ODataPageResult<T>
 * @see Microsoft.AspNet.OData.PageResult<T>
 * @remarks
 * 		This is only exported because it is used by a couple of non-odata controllers directly...
 * 		@see AppleBrandedPrograms.Web.Features.Orders.AppleBrandedOrderSearchController
 */
export function createODataPageResultSchema<
	TItem,
	TItemSchema extends z.ZodType<TItem> = z.ZodType<TItem>,
>(itemSchema: TItemSchema): z.ZodType<ODataPageResult<TItem>> {
	return z.object({
		count: z.number().int().nonnegative().optional(),
		nextPageLink: z.string().nullable(),
		items: z.array(itemSchema),
	}) satisfies z.ZodType<ODataPageResult<TItem>>;
}

export function escapeODataFilterValue(value: string): string {
	const escapedValue = value.replaceAll("'", "''");
	return encodeURIComponent(escapedValue);
}
