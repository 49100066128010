import { Text } from '@mantine/core';
import { useQueries } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import {
	getCoreRowModel,
	getExpandedRowModel,
	getFacetedMinMaxValues,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table';

import { AddIcon } from '@apple/assets/icons';
import { requireAuth } from '@apple/features/auth';
import { customerOdataQueryOptions } from '@apple/features/customer/odata.queries';
import { programsQueryOptions } from '@apple/features/program/queries';
import { useTranslation } from '@apple/lib/i18next';
import { DataTable, useTableState } from '@apple/ui/data-table';
import { ToolbarButton } from '@apple/ui/data-table/controls/Toolbar';
import { TableLayout } from '@apple/ui/layouts';
import { Link } from '@apple/ui/link';
import type { CustomerFilters, CustomerListing } from '@apple/features/customer/listing.models';

export const Route = createFileRoute('/_authed/_admin/manage/customers')({
	beforeLoad: args => {
		requireAuth(args, {
			requirePermission: 'Manzanita.Security.Features.CustomerManagement',
		});
	},
	component: CustomerManageRoute,
});

function CustomerManageRoute() {
	const { t } = useTranslation('manage');
	const search = Route.useSearch();
	const navigate = Route.useNavigate();

	const tableState = useTableState<CustomerListing, CustomerFilters>({
		search,
		navigate,
		defaultSorting: [{ id: 'name', desc: true }],
		fieldMap: [],
	});

	const [customerSearchQuery, programsQuery] = useQueries({
		queries: [
			customerOdataQueryOptions({
				pagination: tableState.state.pagination,
				filters: tableState.currentFilterData,
				// sorting: tableState.value.sorting,
			}),
			programsQueryOptions(),
		],
	});

	const table = useReactTable<CustomerListing>({
		data: customerSearchQuery.data.rows,
		rowCount: customerSearchQuery.data.totalRowCount,
		...tableState,
		enableFilters: true,
		enableColumnFilters: true,
		enableSorting: true,
		manualFiltering: true,
		manualSorting: true,
		manualPagination: true,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		getFacetedMinMaxValues: getFacetedMinMaxValues(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: (table, columnId) => {
			switch (columnId) {
				case 'customerGroupId':
					return () => new Map(programsQuery.data?.map(x => [x.id, 1]));
				default:
					return getFacetedUniqueValues<CustomerListing>()(table, columnId);
			}
		},
		columnResizeMode: 'onChange',
		columns: [
			{
				accessorKey: 'name',
				header: t('customers.fields.customerName'),
				filter: {
					group: t('customers.filterGroups.details'),
				},
				cell: ({ row }) => (
					<Link
						to='/manage/customers/$customerCode'
						params={{ customerCode: row.original.code }}
						title={row.original.name}
						children={row.original.name}
					/>
				),
			},
			{
				//id: 'customerCode',
				accessorKey: 'code',
				header: t('customers.fields.customerCode'),
				filter: {
					group: t('customers.filterGroups.details'),
				},
				cell: ({ row }) => <Text>{row.original.code}</Text>,
			},
			{
				accessorKey: 'customerGroupId',
				header: t('customers.fields.program'),
				size: 100,
				filter: {
					group: t('customers.filterGroups.details'),
					variant: 'select',
					getFilterDisplayValue: value =>
						programsQuery.data?.find(row => row.id === value)?.name ??
						(value as string),
				},
				cell: ({ row }) => <Text>{row.original.customerGroupName}</Text>,
			},
			{
				accessorKey: 'isActive',
				header: t('customers.fields.isActive'),
				size: 100,
				cell: ({ row }) => <Text>{row.original.isActive ? 'Active' : 'Inactive'}</Text>,
				filter: {
					group: t('customers.filterGroups.details'),
					variant: 'switch',
				},
			},
		],
	});

	return (
		<TableLayout
			title={t('customers.title')}
			table={table}
			toolbarButtons={[
				<ToolbarButton
					key='add'
					tooltip={t('customers.buttons.add.tooltip')}
					icon={AddIcon}
					onClick={() =>
						void navigate({
							to: '/manage/customers/add',
							viewTransition: false,
						})
					}
				/>,
			]}
		>
			<DataTable table={table} loading={customerSearchQuery.isFetching} />
		</TableLayout>
	);
}
