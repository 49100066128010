import { notifications } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';

import { ErrorIcon, Success } from '@apple/assets/icons';
import { requireAuth } from '@apple/features/auth';
import { ProgramForm } from '@apple/features/program/components/ProgramForm';
import { addProgram } from '@apple/features/program/management';
import { useTranslation } from '@apple/lib/i18next';
import { useRouterHistory } from '@apple/lib/tanstack-router/hooks/use-router-history';
import { DetailsLayout } from '@apple/ui/layouts';
import { ServerValidationError } from '@apple/utils/api';

export const Route = createFileRoute('/_authed/_admin/manage/programs_/add')({
	beforeLoad: args => {
		requireAuth(args, {
			requirePermission: 'Manzanita.Security.Features.CustomerGroupManagement.Write',
		});
	},
	component: ProgramRoute,
});

function ProgramRoute() {
	const { t } = useTranslation('program');
	const { queryClient } = Route.useRouteContext();

	const navigate = Route.useNavigate();
	const history = useRouterHistory();

	const createProgram = useMutation({
		mutationFn: addProgram,
		onSuccess: async () => {
			notifications.show({
				color: 'green',
				message: t('add.success'),
				icon: <Success />,
			});
			await queryClient.invalidateQueries({ queryKey: ['programs'] });
			history.canGoBack() ? history.back() : await navigate({ to: '/manage/programs' });
		},
		onError: (error: Error) => {
			if (!(error instanceof ServerValidationError)) {
				notifications.show({
					message: t('common:error.generic'),
					icon: <ErrorIcon />,
					color: 'red',
					autoClose: 5000,
				});
			}
		},
	});

	return (
		<DetailsLayout title={t('title.add')}>
			<ProgramForm isEdit={false} onSaveProgram={createProgram.mutateAsync} />
		</DetailsLayout>
	);
}
