import { useCallback } from 'react';
import { Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { useMutation, useSuspenseQueries } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';

import { icons } from '@apple/assets';
import { requireAuth } from '@apple/features/auth';
import { programsQueryOptions } from '@apple/features/program/queries';
import { deleteSubprogram, saveSubprogram } from '@apple/features/subprogram/api';
import { SubprogramForm } from '@apple/features/subprogram/components/SubprogramForm';
import { subProgramQueryKeys, subProgramQueryOptions } from '@apple/features/subprogram/queries';
import { useTranslation } from '@apple/lib/i18next';
import { useRouterHistory } from '@apple/lib/tanstack-router/hooks/use-router-history';
import { DetailsLayout } from '@apple/ui/layouts';
import { ServerValidationError } from '@apple/utils/api';
import { countryCodeSchema } from '@apple/utils/globalization';
import type { SubProgramKey } from '@apple/features/subprogram/models';

export const Route = createFileRoute(
	'/_authed/_admin/manage/subprograms_/$customerId_/$countryCode',
)({
	parseParams: ({ customerId, countryCode }) => ({
		customerId: Number(customerId),
		countryCode: countryCode,
	}),
	beforeLoad: ({ context, location, params: { customerId, countryCode }, cause }) => {
		requireAuth(
			{ context, location, cause },
			{
				requirePermission:
					'Manzanita.Security.Features.CustomerGroupCountryManagement.Read',
			},
		);

		return {
			subprogramQueryOptions: subProgramQueryOptions.subprogram(customerId, countryCode),
			programsQueryOptions: programsQueryOptions(),
		};
	},
	loader: ({ context: { queryClient, subprogramQueryOptions, programsQueryOptions } }) => {
		void queryClient.prefetchQuery(subprogramQueryOptions);
		void queryClient.prefetchQuery(programsQueryOptions);
	},
	wrapInSuspense: true,
	component: SubprogramRoute,
});

function SubprogramRoute() {
	const { t } = useTranslation('subprogram');
	const { auth, subprogramQueryOptions, programsQueryOptions, queryClient } =
		Route.useRouteContext();

	const [subprogramQuery, programsQuery] = useSuspenseQueries({
		queries: [subprogramQueryOptions, programsQueryOptions],
	});

	const navigate = Route.useNavigate();
	const history = useRouterHistory();

	const saveSubprogramMutation = useMutation({
		mutationFn: saveSubprogram,
		onSuccess: async () => {
			notifications.show({
				color: 'green',
				message: t('edit.success'),
				icon: <icons.Success />,
			});
			await Promise.all([
				queryClient.invalidateQueries({ queryKey: subProgramQueryKeys.all }),
				queryClient.invalidateQueries({
					queryKey: subprogramQueryOptions.queryKey,
				}),
			]);
			history.canGoBack() ? history.back() : await navigate({ to: '/manage/subprograms' });
		},
		onError: (error: Error) => {
			if (!(error instanceof ServerValidationError)) {
				notifications.show({
					message: t('common:error.generic'),
					icon: <icons.ErrorIcon />,
					color: 'red',
					autoClose: 5000,
				});
			}
		},
	});

	const deleteSubprogramMutation = useMutation({
		mutationFn: deleteSubprogram,
		onSuccess: async () => {
			notifications.show({
				color: 'green',
				message: t('delete.success'),
				icon: <icons.Success />,
			});
			await queryClient.invalidateQueries({ queryKey: subProgramQueryKeys.all });
			history.canGoBack() ? history.back() : await navigate({ to: '/manage/subprograms' });
		},
		onError: () => {
			notifications.show({
				color: 'red',
				message: t('delete.error'),
				icon: <icons.ErrorIcon />,
			});
		},
	});

	const handleDeleteSubprogram = useCallback(
		(subprogramKey: SubProgramKey) => {
			modals.openConfirmModal({
				title: t('delete.title'),
				labels: {
					confirm: t('common:buttons.confirm'),
					cancel: t('common:buttons.cancel'),
				},
				onConfirm: () => deleteSubprogramMutation.mutate([subprogramKey]),
				children: <Text>{t('delete.message')}</Text>,
			});
		},
		[deleteSubprogramMutation, t],
	);

	return (
		<DetailsLayout title={t('title.edit', { name: subprogramQuery.data.name })}>
			<SubprogramForm
				mode={
					auth.hasPermissions([
						'Manzanita.Security.Features.CustomerGroupCountryManagement.Write',
					])
						? 'edit'
						: 'view'
				}
				subprogram={subprogramQuery.data}
				onClose={() => history.back()}
				onSave={subprogram =>
					saveSubprogramMutation.mutateAsync({
						...subprogram,
						countryCode: countryCodeSchema.parse(subprogram.countryCode),
					})
				}
				onDelete={handleDeleteSubprogram}
				programs={programsQuery.data}
			/>
		</DetailsLayout>
	);
}
