import { Badge, Divider, Group, Loader, LoadingOverlay, Paper, Stack, Text } from '@mantine/core';
import type { BadgeVariant, DefaultMantineColor } from '@mantine/core';

import { useTranslation } from '@apple/lib/i18next';
import type { BulkUploadItem, BulkUploadResponse } from '@apple/utils/files/models/bulk-upload';

import {
	ErrorFileDownload,
	type ErrorFileDownloadProps,
} from '@apple/utils/files/components/ErrorFileDownload';
import type { ExcelError, ExcelResult } from '../models/excel';
import { ErrorTable } from './ErrorTable';
import type { ErrorTableProps } from './ErrorTable';

export type UploadResultsProps<
	TItem extends Record<string, unknown>,
	TKey extends keyof TItem & string,
	TError extends ExcelError<TItem, TKey>,
	TBulkUploadItem extends BulkUploadItem,
> = ErrorTableProps<TItem, TKey, TError> & {
	processing: boolean;
	results: ExcelResult<TItem, TKey, TError> | BulkUploadResponse<TBulkUploadItem>;
	errorDownloadProps?: ErrorFileDownloadProps;
};

export function UploadResults<
	TItem extends Record<string, unknown>,
	TKey extends keyof TItem & string,
	TError extends ExcelError<TItem, TKey>,
	TBulkUploadItem extends BulkUploadItem,
>({
	processing,
	results,
	keyProperty,
	keyLabel,
	errorsProperty,
	errorDownloadProps,
}: UploadResultsProps<TItem, TKey, TError, TBulkUploadItem>) {
	const { t } = useTranslation('manage');

	const isBulkUpload = 'numberOfImportedRows' in results;

	function isBulkUploadResponse(
		results: ExcelResult<TItem, TKey, TError> | BulkUploadResponse<TBulkUploadItem>,
	): results is BulkUploadResponse<TBulkUploadItem> {
		return 'numberOfImportedRows' in results;
	}

	const counts = {
		updates: isBulkUploadResponse(results)
			? results.numberOfUpdatedRows
			: results.updates.length,
		inserts: isBulkUploadResponse(results)
			? results.numberOfInsertedRows
			: results.inserts.length,
		deletes: isBulkUploadResponse(results)
			? results.numberOfDeletedRows
			: results.deletes.length,
		ignored: isBulkUploadResponse(results) ? results.numberOfIgnoredRows : 0,
		errors: isBulkUploadResponse(results) ? results.numberOfInvalidRows : results.errors.length,
	};

	const errorsList = isBulkUploadResponse(results) ? results.invalidRows || [] : results.errors;

	return (
		<Stack align='center'>
			<LoadingOverlay
				visible={processing}
				loaderProps={{
					children: (
						<Stack align='center'>
							<Text>{t('excelUpload.progress.saving')}</Text>
							<Loader />
						</Stack>
					),
				}}
			/>
			<Text>{t('excelUpload.results.message')}</Text>
			<Paper withBorder shadow='sm' radius='md'>
				<Group px='md' align='center' justify='space-evenly' wrap='nowrap'>
					<UploadResult
						label={t('excelUpload.results.labels.updates')}
						count={counts.updates}
						color={undefined}
						variant='light'
					/>
					<Divider orientation='vertical' />
					<UploadResult
						label={t('excelUpload.results.labels.inserts')}
						count={counts.inserts}
						color='green'
						variant='light'
					/>
					<Divider orientation='vertical' />
					<UploadResult
						label={t('excelUpload.results.labels.deletes')}
						count={counts.deletes}
						color='red.7'
						variant='light'
					/>
					<Divider orientation='vertical' />
					{isBulkUpload && (
						<>
							<UploadResult
								label={t('excelUpload.results.labels.ignore')}
								count={counts.ignored}
								color='gray'
								variant='light'
							/>
							<Divider orientation='vertical' />
						</>
					)}
					<UploadResult
						label={t('excelUpload.results.labels.errors')}
						count={counts.errors}
						color='red.7'
					/>
				</Group>
			</Paper>
			{errorsList.length > 0 && (
				<>
					{errorDownloadProps && <ErrorFileDownload {...errorDownloadProps} />}
					<ErrorTable
						keyLabel={keyLabel}
						keyProperty={keyProperty}
						errorsProperty={errorsProperty}
						errors={errorsList}
					/>
				</>
			)}
		</Stack>
	);
}

function UploadResult({
	label,
	count,
	color,
	variant,
}: {
	label: string;
	count: number;
	color?: DefaultMantineColor | undefined;
	variant?: BadgeVariant | undefined;
}) {
	return (
		<Group p='xs'>
			<Text inline>{label}</Text>
			<Badge
				data-testid={`badge-${label}`}
				size='lg'
				circle
				color={count > 0 ? color : 'gray.7'}
				variant={count > 0 ? variant : 'light'}
			>
				{count}
			</Badge>
		</Group>
	);
}
