import { useCallback } from 'react';
import { notifications } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { getLogger } from 'loglevel';

import { ErrorIcon, Success } from '@apple/assets/icons';
import { requireAuth } from '@apple/features/auth';
import { faqQueryKeys } from '@apple/features/faq';
import { FaqForm } from '@apple/features/faq/components/FaqForm';
import { saveFaq } from '@apple/features/faq/management';
import { useTranslation } from '@apple/lib/i18next';
import { DetailsLayout } from '@apple/ui/layouts';

const log = getLogger('manage-announcements');

export const Route = createFileRoute('/_authed/_admin/content/faq_/add')({
	wrapInSuspense: true,
	component: AddFaqRoute,
	beforeLoad: args => {
		requireAuth(args, {
			requirePermission: 'Manzanita.Security.Features.ContentManagement',
		});
	},
});

function AddFaqRoute() {
	const { t } = useTranslation('content');
	const { queryClient } = Route.useRouteContext();

	const navigate = Route.useNavigate();

	const onSuccessfulChange = useCallback(
		async (message: string) => {
			await queryClient.invalidateQueries({
				queryKey: faqQueryKeys.all,
			});
			notifications.show({
				message: message,
				icon: <Success />,
				color: 'green',
				autoClose: 5000,
			});
			await navigate({
				to: '/content/faq',
			});
		},
		[navigate, queryClient],
	);

	const onFailedChange = useCallback(
		(error: Error) => {
			//TODO: Have the API return model validation errors
			// if (!(error instanceof ServerValidationError)) {
			log.error(error);
			notifications.show({
				message: t('common:error.generic'),
				icon: <ErrorIcon />,
				color: 'red',
				autoClose: 5000,
			});
		},
		[t],
	);

	const createFAQMutation = useMutation({
		mutationFn: saveFaq,
		onSuccess: async () => {
			await onSuccessfulChange(t('faqs.notifications.success.save'));
		},
		onError: onFailedChange,
	});

	return (
		<DetailsLayout title={t('faqs.add.title')}>
			<FaqForm isEdit={false} saveMutation={createFAQMutation} />
		</DetailsLayout>
	);
}
