import { createFileRoute } from '@tanstack/react-router';

import { requireAuth } from '@apple/features/auth';
import { useLocationFeedTable } from '@apple/features/report/location-feed/hooks/useLocationFeedTable';
import { useTranslation } from '@apple/lib/i18next';
import { DataTable } from '@apple/ui/data-table';
import { TableLayout } from '@apple/ui/layouts';

export const Route = createFileRoute('/_authed/_admin/reports/location-feed')({
	beforeLoad: args =>
		requireAuth(args, {
			requirePermission:
				'AppleBrandedPrograms.Security.Features.AppleIdFeedLocationsManagement.Read',
		}),
	component: LocationFeedRoute,
});

function LocationFeedRoute() {
	const { t } = useTranslation('reports');
	const search = Route.useSearch();
	const navigate = Route.useNavigate();
	const { table, locationFeedQuery } = useLocationFeedTable({ search, navigate });
	return (
		<TableLayout title={t('locationFeed.title')} table={table}>
			<DataTable table={table} minWidth='3000px' loading={locationFeedQuery.isFetching} />
		</TableLayout>
	);
}
