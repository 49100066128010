import { useEffect, useState } from 'react';
import { notifications } from '@mantine/notifications';
import axios from 'axios';

import { icons } from '@apple/assets';

export type FileDownloadProps<T> = Readonly<{
	method: 'get' | 'post';
	url: string;
	data?: T;
	params?: T;
	fallbackFilename: string;
	errorTitle: string;
	errorMessage: string;
}>;

export function useFileDownload<T>({
	method,
	url,
	data = undefined,
	params = undefined,
	fallbackFilename,
	errorTitle,
	errorMessage,
}: FileDownloadProps<T>) {
	const [downloading, setDownloading] = useState(false);
	const [error, setError] = useState<unknown>();

	useEffect(() => {
		if (!error) {
			return;
		}

		notifications.show({
			title: errorTitle,
			color: 'red',
			autoClose: false,
			withCloseButton: true,
			message: errorMessage,
			icon: <icons.ErrorIcon />,
		});
	}, [error, errorMessage, errorTitle]);

	async function download({
		url: urlOverride,
		data: dataOverride,
		params: paramsOverride,
	}: {
		url?: string;
		data?: T;
		params?: T;
	} = {}): Promise<void> {
		setError(undefined);

		let downloadUrl = '';
		try {
			setDownloading(true);
			const response = await axios<Blob>({
				url: urlOverride ?? url,
				method,
				data: dataOverride ?? data,
				params: paramsOverride ?? params,
				responseType: 'blob',
			});

			const blob = new Blob([response.data], {
				type: (response.headers['content-type'] as string) || 'application/octet-stream',
			});

			const contentDisposition = response.headers['content-disposition'] as string;
			const matches = /filename=([^;]+)/.exec(contentDisposition ?? '');
			const filename = matches?.[1] ? matches[1] : fallbackFilename;

			downloadUrl = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = downloadUrl;
			a.download = filename;
			document.body.append(a);
			a.click();
			a.remove();
		} catch (err) {
			setError(err);
		} finally {
			setDownloading(false);
			if (downloadUrl) {
				window.URL.revokeObjectURL(downloadUrl);
			}
		}
	}

	return {
		download,
		downloading,
		error,
	};
}
