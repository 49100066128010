import { createElement } from 'react';
import { notifications } from '@mantine/notifications';
import axios, { CanceledError } from 'axios';
import log from 'loglevel';

import { ErrorIcon } from '@apple/assets/icons';
import { ServerError } from '@apple/utils/api/errors';

import type { OrderApprovalListing } from '../models/approval';

const unexpectedSearchErrorMessage =
	"We're sorry - an error occurred while fetching your orders. Please try again in a few minutes. Contact support if the problem persists.";

export async function getApprovalOrders(signal?: AbortSignal): Promise<OrderApprovalListing[]> {
	try {
		const response = await axios.get<OrderApprovalListing[]>('/api/approval-queue', { signal });

		return response.data;
	} catch (err) {
		if (err instanceof ServerError && !(err.innerError instanceof CanceledError)) {
			log.error('Error searching approval orders:', err);

			notifications.show({
				color: 'red',
				message: unexpectedSearchErrorMessage,
				icon: createElement(ErrorIcon),
			});
			return [];
		}

		throw err;
	}
}
