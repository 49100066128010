import { notifications } from '@mantine/notifications';
import { useMutation, useSuspenseQueries } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';

import { icons } from '@apple/assets';
import { requireAuth } from '@apple/features/auth';
import { programsQueryOptions } from '@apple/features/program/queries';
import { saveSubprogram } from '@apple/features/subprogram/api';
import { SubprogramForm } from '@apple/features/subprogram/components/SubprogramForm';
import { subProgramQueryKeys } from '@apple/features/subprogram/queries';
import { useTranslation } from '@apple/lib/i18next';
import { useRouterHistory } from '@apple/lib/tanstack-router/hooks/use-router-history';
import { DetailsLayout } from '@apple/ui/layouts';
import { ServerValidationError } from '@apple/utils/api';
import { countryCodeSchema } from '@apple/utils/globalization';

export const Route = createFileRoute('/_authed/_admin/manage/subprograms_/add')({
	beforeLoad: args => {
		requireAuth(args, {
			requirePermission: 'Manzanita.Security.Features.CustomerGroupCountryManagement.Read',
		});

		return {
			programsQueryOptions: programsQueryOptions(),
		};
	},
	loader: ({ context: { queryClient, programsQueryOptions } }) => {
		void queryClient.prefetchQuery(programsQueryOptions);
	},
	wrapInSuspense: true,
	component: SubprogramRoute,
});

function SubprogramRoute() {
	const { t } = useTranslation('subprogram');
	const { programsQueryOptions, queryClient } = Route.useRouteContext();

	const [programsQuery] = useSuspenseQueries({
		queries: [programsQueryOptions],
	});

	const navigate = Route.useNavigate();
	const history = useRouterHistory();

	const saveSubprogramMutation = useMutation({
		mutationFn: saveSubprogram,
		onSuccess: async () => {
			notifications.show({
				color: 'green',
				message: t('add.success'),
				icon: <icons.Success />,
			});
			await queryClient.invalidateQueries({ queryKey: subProgramQueryKeys.all });
			history.canGoBack() ? history.back() : await navigate({ to: '/manage/subprograms' });
		},
		onError: (error: Error) => {
			if (!(error instanceof ServerValidationError)) {
				notifications.show({
					message: t('common:error.generic'),
					icon: <icons.ErrorIcon />,
					color: 'red',
					autoClose: 5000,
				});
			}
		},
	});

	return (
		<DetailsLayout title={t('title.add')}>
			<SubprogramForm
				mode='add'
				programs={programsQuery.data}
				onClose={() =>
					history.canGoBack()
						? history.back()
						: void navigate({ to: '/manage/subprograms' })
				}
				onSave={subprogram =>
					saveSubprogramMutation.mutateAsync({
						...subprogram,
						countryCode: countryCodeSchema.parse(subprogram.countryCode),
					})
				}
			/>
		</DetailsLayout>
	);
}
