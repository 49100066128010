import { useCallback } from 'react';
import { Stack } from '@mantine/core';
import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { requireAuth } from '@apple/features/auth';
import { CurrencyDetail } from '@apple/features/currencies/components/CurrencyDetail';
import { useCurrencyManagement } from '@apple/features/currencies/hooks/useCurrencyManagement';
import { currencyQueries } from '@apple/features/currencies/queries';
import { useTranslation } from '@apple/lib/i18next';
import { DetailsLayout } from '@apple/ui/layouts';
import type {
	CurrencyConversion,
	CurrencyConversionDeleteItem,
} from '@apple/features/currencies/models';

export const Route = createFileRoute(
	'/_authed/_admin/manage/currencies_/$fromCurrency_/$toCurrency',
)({
	parseParams: ({ fromCurrency, toCurrency }) => ({
		fromCurrency: z.string().parse(fromCurrency),
		toCurrency: z.string().parse(toCurrency),
	}),
	beforeLoad: ({ params: { fromCurrency, toCurrency }, ...args }) => {
		requireAuth(args, {
			requirePermission: 'Manzanita.Security.Features.CurrencyConversionManagement.Read',
		});

		return {
			currencyQueryOptions: currencyQueries.getConversion(fromCurrency, toCurrency),
		};
	},
	loader: ({ context: { queryClient, currencyQueryOptions } }) => {
		void queryClient.prefetchQuery(currencyQueryOptions);
	},
	wrapInSuspense: true,
	component: ManageCurrencyRoute,
});

function ManageCurrencyRoute() {
	const { t } = useTranslation('manage');
	const { currencyQueryOptions } = Route.useRouteContext();

	const currencyQuery = useSuspenseQuery(currencyQueryOptions);

	const { edit, remove } = useCurrencyManagement();

	const navigate = Route.useNavigate();

	const handleEdit = useCallback(
		async (currencyConversion: CurrencyConversion) => {
			await edit.mutateAsync(currencyConversion, {
				onSuccess: () => {
					void navigate({
						to: '/manage/currencies',
					});
				},
			});
		},
		[edit, navigate],
	);

	const handleRemove = useCallback(
		async (currencyConversion: CurrencyConversionDeleteItem) => {
			await remove.mutateAsync([currencyConversion], {
				onSuccess: () => {
					void navigate({
						to: '/manage/currencies',
					});
				},
			});
		},
		[navigate, remove],
	);

	return (
		<DetailsLayout title={t('currencies.edit')}>
			<Stack>
				<CurrencyDetail
					currencyConversion={currencyQuery.data}
					isEdit
					loading={edit.isPending || remove.isPending}
					submitted={edit.isSuccess || remove.isSuccess}
					onSave={handleEdit}
					onDelete={handleRemove}
				/>
			</Stack>
		</DetailsLayout>
	);
}
