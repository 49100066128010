import { createContext } from 'react';

import type { RoleName } from '../models/roles';
import type {
	ChangePasswordRequest,
	Permission,
	ProfileDto,
	UpdateProfileRequest,
} from '../models/user';
import type { AuthRequirements } from '../utils/requirements';

export type AuthContextBase<TProfile extends ProfileDto> = {
	error: boolean;
	authenticated: boolean;
	profile: TProfile | null;
	login: (
		username: string,
		password: string,
		rememberMe: boolean,
		returnUrl: string,
	) => Promise<void>;
	logout: () => Promise<void>;
	reset: () => Promise<void>;
	resetPassword: (username: string) => Promise<void>;
	changePassword: (request: ChangePasswordRequest) => Promise<void>;
	updateProfile: (request: UpdateProfileRequest) => Promise<void>;
	hasRole: (role: RoleName) => boolean;
	hasRoles: (roles: RoleName[]) => boolean;
	hasPermission: (permission: Permission) => boolean;
	hasPermissions: (permissions: Permission[]) => boolean;
	filterByPermissions: <T>(items: AuthRequirements<T>[]) => T[];
};

export const AuthContext = createContext<AuthContextBase<ProfileDto> | undefined>(undefined);
