import { Stack } from '@mantine/core';
import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { requireAuth } from '@apple/features/auth';
import { getEmail } from '@apple/features/report/email';
import { EmailDetail } from '@apple/features/report/email/components/EmailDetail';
import { DetailsLayout } from '@apple/ui/layouts';

export const Route = createFileRoute('/_authed/_admin/reports/emails_/$emailId')({
	parseParams: ({ emailId }) => ({
		emailId: z.string().parse(emailId),
	}),
	beforeLoad: ({ params: { emailId }, ...args }) => {
		requireAuth(args, {
			requirePermission: 'Manzanita.Security.Features.Reports',
		});

		return {
			emailQueryOptions: queryOptions({
				queryKey: ['emails', emailId],
				queryFn: () => getEmail(emailId),
				staleTime: 5 * 60 * 1000, // 5 minutes
			}),
		};
	},
	loader: ({ context: { queryClient, emailQueryOptions } }) => {
		void queryClient.prefetchQuery(emailQueryOptions);
	},
	wrapInSuspense: true,
	component: EmailRoute,
});

function EmailRoute() {
	const { emailQueryOptions } = Route.useRouteContext();
	const emailQuery = useSuspenseQuery(emailQueryOptions);

	return (
		<DetailsLayout title={emailQuery.data.subject}>
			<Stack>
				<EmailDetail email={emailQuery.data} />
			</Stack>
		</DetailsLayout>
	);
}
