import { useCallback } from 'react';
import { Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { queryOptions, useMutation, useSuspenseQueries } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';

import { ErrorIcon, Success } from '@apple/assets/icons';
import { requireAuth } from '@apple/features/auth';
import { deleteCountry, getCountry, saveCountry } from '@apple/features/country/api';
import { CountryForm } from '@apple/features/country/components/CountryForm';
import { countryKeys } from '@apple/features/country/models';
import { plantQueryOptions } from '@apple/features/plants/queries/queries';
import { useTranslation } from '@apple/lib/i18next';
import { useRouterHistory } from '@apple/lib/tanstack-router/hooks/use-router-history';
import { DetailsLayout } from '@apple/ui/layouts';
import { ServerValidationError } from '@apple/utils/api';
import { globalizationQueryOptions } from '@apple/utils/globalization';
import { countryCodeSchema } from '@apple/utils/globalization/models';
import type { CountryCode } from '@apple/utils/globalization/models';

export const Route = createFileRoute('/_authed/_admin/manage/countries_/$countryCode')({
	parseParams: ({ countryCode }) => ({
		countryCode: countryCodeSchema.parse(countryCode),
	}),
	beforeLoad: ({ context, location, params: { countryCode }, cause }) => {
		requireAuth(
			{ context, location, cause },
			{
				requirePermission: 'Manzanita.Security.Features.CountryManagement.Write',
			},
		);

		return {
			plantsQueryOptions: plantQueryOptions.plants,
			languageOptions: globalizationQueryOptions.allSupportedLanguages,
			countryQueryOptions: queryOptions({
				queryKey: countryKeys.country(countryCode),
				queryFn: () => getCountry(countryCode),
				//staleTime: 5 * 60 * 1000, // 5 minutes
			}),
		};
	},
	loader: ({
		context: { queryClient, plantsQueryOptions, languageOptions, countryQueryOptions },
	}) => {
		void queryClient.prefetchQuery(plantsQueryOptions);
		void queryClient.prefetchQuery(languageOptions);
		void queryClient.prefetchQuery(countryQueryOptions);
	},
	wrapInSuspense: true,
	component: CountryRoute,
});

function CountryRoute() {
	const { t } = useTranslation('country');
	const { countryQueryOptions, plantsQueryOptions, languageOptions, queryClient } =
		Route.useRouteContext();

	const [countryQuery, plantsQuery, languageQuery] = useSuspenseQueries({
		queries: [countryQueryOptions, plantsQueryOptions, languageOptions],
	});

	const navigate = Route.useNavigate();
	const history = useRouterHistory();

	const updateCountry = useMutation({
		mutationFn: saveCountry,
		onSuccess: async () => {
			notifications.show({
				color: 'green',
				message: t('save.success'),
				icon: <Success />,
			});
			await queryClient.invalidateQueries({
				queryKey: [countryQueryOptions.queryKey],
			});
			history.canGoBack() ? history.back() : await navigate({ to: '/manage/countries' });
		},
		onError: (error: Error) => {
			if (!(error instanceof ServerValidationError)) {
				notifications.show({
					message: t('common:error.generic'),
					icon: <ErrorIcon />,
					color: 'red',
					autoClose: 5000,
				});
			}
		},
	});

	const deleteCountryMutation = useMutation({
		mutationFn: deleteCountry,
		onSuccess: async () => {
			notifications.show({
				color: 'green',
				message: t('delete.success'),
				icon: <Success />,
			});
			await queryClient.invalidateQueries({
				queryKey: [countryKeys.all],
			});
			history.canGoBack() ? history.back() : await navigate({ to: '/manage/countries' });
		},
		onError: () => {
			notifications.show({
				color: 'red',
				message: t('common:error.generic'),
				icon: <ErrorIcon />,
			});
		},
	});

	const handleDeleteCountry = useCallback(
		(countryCode: CountryCode) => {
			modals.openConfirmModal({
				title: t('delete.title'),
				labels: {
					confirm: t('common:buttons.confirm'),
					cancel: t('common:buttons.cancel'),
				},
				onConfirm: () => deleteCountryMutation.mutate(countryCode),
				children: <Text>{t('delete.message')}</Text>,
			});
		},
		[deleteCountryMutation, t],
	);

	return (
		<DetailsLayout title={t('title.edit')}>
			<CountryForm
				isEdit={true}
				onSaveCountry={updateCountry.mutateAsync}
				onDeleteCountry={handleDeleteCountry}
				country={countryQuery.data}
				plants={plantsQuery.data}
				languages={languageQuery.data}
			/>
		</DetailsLayout>
	);
}
