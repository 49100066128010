import { createFileRoute } from '@tanstack/react-router';

import { requireAuth } from '@apple/features/auth';
import { UploadFileForm } from '@apple/features/file-manager/components';
import { useTranslation } from '@apple/lib/i18next';
import { DetailsLayout } from '@apple/ui/layouts';

export const Route = createFileRoute('/_authed/_admin/content/file-manager_/upload')({
	component: UploadUserFileRoute,
	beforeLoad: args =>
		requireAuth(args, {
			requirePermission: 'AppleBranded.Security.UserFileRepository.Upload',
		}),
});

function UploadUserFileRoute() {
	const { t } = useTranslation('content');
	return (
		<DetailsLayout title={t('fileManager.title')}>
			<UploadFileForm />
		</DetailsLayout>
	);
}
