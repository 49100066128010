import { queryOptions } from '@tanstack/react-query';

import { getXsrfToken } from '../api/authentication';

export const xsrfQueryOptions = (withRefetchInterval = false) =>
	queryOptions({
		queryKey: ['xsrf', withRefetchInterval],
		queryFn: getXsrfToken,
		staleTime: Infinity,
		refetchInterval: withRefetchInterval ? 30 * 1000 : false,
	});
