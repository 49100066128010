import { keepPreviousData, queryOptions } from '@tanstack/react-query';
import type { PaginationState } from '@tanstack/react-table';

import { DEFAULT_PAGE_STATE } from '@apple/utils/pagination/models';
import type { QueryOptions } from '@apple/lib/tanstack-query';

import { getAllowedOrderSearchFields, getOrderSearchResults } from '../api/search';
import type { AllowedOrderSearchFilters, OrderSearchFilters } from '../models/search';

export function getOrderSearchQueryOptions(
	pagination: PaginationState,
	filters: OrderSearchFilters,
	onError?: (error: Error) => void,
) {
	return queryOptions({
		queryKey: ['order-search', filters, pagination],
		queryFn: () => getOrderSearchResults(pagination, filters, onError),
		placeholderData: keepPreviousData,
		throwOnError: true,
		retry: false,
		initialData: DEFAULT_PAGE_STATE,
	});
}

export const allowedOrderSearchFieldsQueryOptions = queryOptions({
	queryKey: ['order-search-options'],
	queryFn: () => getAllowedOrderSearchFields(),
	placeholderData: keepPreviousData,
	staleTime: 5 * 60 * 1000, // 5 minutes
}) as QueryOptions<AllowedOrderSearchFilters>;
