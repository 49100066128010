import axios from 'axios';

import { countryWithCulturesSchema } from '@apple/utils/globalization/models';
import type { CountryWithCultures } from '@apple/utils/globalization/models';

import { countrySchema } from './models';
import type { Country } from './models';

/** AppleBrandedPrograms.Web.Areas.Admin.ApiControllers.CountryAdminApiController */
export async function getCountry(countryCode: string): Promise<Country> {
	const response = await axios.get<unknown>(`/api/management/supported-countries/${countryCode}`);

	return countrySchema.parse(response.data);
}

/** AppleBrandedPrograms.Web.Areas.Admin.ApiControllers.CountryAdminApiController */
export async function getCountries(): Promise<CountryWithCultures[]> {
	const response = await axios.get<unknown>('/api/management/supported-countries/available');

	return countryWithCulturesSchema.array().parse(response.data);
}

/** AppleBrandedPrograms.Web.Areas.Admin.ApiControllers.CountryAdminApiController */
export async function saveCountry(country: Country): Promise<void> {
	await axios.put(`/api/management/supported-countries/${country.code}`, country);
}

/** AppleBrandedPrograms.Web.Areas.Admin.ApiControllers.CountryAdminApiController */
export async function deleteCountry(countryCode: string): Promise<void> {
	await axios.delete(`/api/management/supported-countries/${countryCode}`);
}
