import { useQueries } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import {
	getCoreRowModel,
	getFacetedMinMaxValues,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table';
import type { SortingState } from '@tanstack/react-table';

import { AddIcon } from '@apple/assets/icons';
import { requireAuth } from '@apple/features/auth';
import { programsQueryOptions } from '@apple/features/program/queries';
import type { Subprogram, SubprogramFilters } from '@apple/features/subprogram/models';
import { subProgramQueryOptions } from '@apple/features/subprogram/queries';
import { useTranslation } from '@apple/lib/i18next';
import { DataTable, useTableState } from '@apple/ui/data-table';
import { ToolbarButton } from '@apple/ui/data-table/controls/Toolbar';
import { TableLayout } from '@apple/ui/layouts';
import { Link } from '@apple/ui/link';
import { FormattedBoolean, globalizationQueryOptions } from '@apple/utils/globalization';

export const Route = createFileRoute('/_authed/_admin/manage/subprograms')({
	beforeLoad: args => {
		requireAuth(args, {
			requirePermission: 'Manzanita.Security.Features.CustomerGroupCountryManagement.Read',
		});
	},
	component: SubprogramManagementRoute,
});

const defaultFilters: SubprogramFilters = {};
const defaultSorting: SortingState = [{ id: 'name', desc: false }];

function SubprogramManagementRoute() {
	const { t } = useTranslation('subprogram');
	const navigate = Route.useNavigate();
	const search = Route.useSearch();
	const [subprogramsQuery, countriesQuery, programsQuery] = useQueries({
		queries: [
			subProgramQueryOptions.all,
			globalizationQueryOptions.countries,
			programsQueryOptions(),
		],
	});

	const tableState = useTableState<Subprogram, SubprogramFilters>({
		search,
		navigate,
		defaultFilters: defaultFilters,
		defaultSorting: defaultSorting,
		fieldMap: [],
	});

	const table = useReactTable<Subprogram>({
		data: subprogramsQuery.data,
		...tableState,
		getCoreRowModel: getCoreRowModel(),
		getFacetedMinMaxValues: getFacetedMinMaxValues(),
		getFacetedUniqueValues: (table, columnId) => {
			switch (columnId) {
				case 'countryName':
					return () => new Map(countriesQuery.data?.map(x => [x.Value, 1]));
				case 'programName':
					return () => new Map(programsQuery.data?.map(x => [x.name, 1]));
				default:
					return getFacetedUniqueValues<Subprogram>()(table, columnId);
			}
		},
		getFacetedRowModel: getFacetedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		enableFilters: true,
		enableColumnFilters: true,
		enableSorting: true,
		columnResizeMode: 'onChange',
		columns: [
			{
				accessorKey: 'name',
				header: t('table.subprogramName'),
				enableColumnFilter: false,
				cell: ({ row }) => (
					<Link
						to='/manage/subprograms/$customerId/$countryCode'
						params={{
							customerId: row.original.customerId,
							countryCode: row.original.countryCode,
						}}
						title={row.original.name}
						children={row.original.name}
					/>
				),
			},
			{
				accessorKey: 'programName',
				header: t('table.program'),
				filter: {
					group: t('filters.groups.labels.details'),
					variant: 'select',
					getFilterDisplayValue: value =>
						programsQuery.data?.find(x => x.name === value)?.name ?? String(value),
				},
			},
			{
				accessorKey: 'countryName',
				header: t('table.country'),
				filter: {
					group: t('filters.groups.labels.details'),
					variant: 'select',
					getFilterDisplayValue: value =>
						countriesQuery.data?.find(x => x.Key === value)?.Value ?? String(value),
				},
			},
			{
				accessorKey: 'customerCode',
				header: t('table.customerCode'),
				filter: {
					group: t('filters.groups.labels.details'),
				},
			},
			{
				accessorKey: 'status',
				header: t('table.status'),
				filter: {
					group: t('filters.groups.labels.details'),
					variant: 'select',
					getFilterDisplayValue: value => {
						return value === 'Status_Active'
							? t('labels.active')
							: t('labels.inactive');
					},
				},
				cell: ({ row }) => (
					<FormattedBoolean value={row.original.status === 'Status_Active'} />
				),
			},
			{
				accessorKey: 'plantAbbreviation',
				header: t('table.fulfillmentPlant'),
				enableColumnFilter: false,
			},
			{
				accessorKey: 'expeditedShippingFee',
				header: t('table.expeditedShippingFee'),
				enableColumnFilter: false,
			},
		],
	});

	return (
		<TableLayout
			title={t('title.list')}
			table={table}
			toolbarButtons={[
				<ToolbarButton
					key='add'
					tooltip={t('buttons.add')}
					icon={AddIcon}
					onClick={() =>
						void navigate({
							to: '/manage/subprograms/add',
						})
					}
				/>,
			]}
		>
			<DataTable table={table} loading={subprogramsQuery.isFetching} />
		</TableLayout>
	);
}
