import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_authed/cart/')({
	beforeLoad: args => {
		throw redirect({
			to: '/cart/$step',
			params: {
				step: args.context.auth.canAccessMultipleLocations ? 'locations' : 'products',
			},
		});
	},
});
