import { useSuspenseQueries } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { requireAuth } from '@apple/features/auth';
import { OrderDetails } from '@apple/features/order-channel/components/OrderDetails/OrderDetails';
import {
	commentsQueryOptions,
	purchaseOrderLinesQueryOptions,
	purchaseOrderQueryOptions,
} from '@apple/features/order-channel/queries/details';
import { useTranslation } from '@apple/lib/i18next';
import { useRouterHistory } from '@apple/lib/tanstack-router/hooks/use-router-history';
import { DetailsLayout } from '@apple/ui/layouts';
import { NotFoundPage } from '@apple/ui/shell/pages/NotFoundPage';
import { NotAuthorizedError } from '@apple/utils/api';

export const Route = createFileRoute('/_authed/orders/$poNumber')({
	errorComponent: ({ error }) => {
		if (error instanceof NotAuthorizedError) {
			return <NotFoundPage />;
		} else {
			throw error;
		}
	},
	parseParams: ({ poNumber }) => ({
		poNumber: z.string().parse(poNumber),
	}),
	beforeLoad: args => {
		requireAuth(args);

		return {
			purchaseOrderQueryOptions: purchaseOrderQueryOptions(args.params.poNumber),
			purchaseOrderLinesQueryOptions: purchaseOrderLinesQueryOptions(args.params.poNumber),
			commentsQueryOptions: commentsQueryOptions(args.params.poNumber),
		};
	},
	loader: ({
		context: {
			queryClient,
			purchaseOrderQueryOptions,
			purchaseOrderLinesQueryOptions,
			commentsQueryOptions,
		},
	}) => {
		void queryClient.prefetchQuery(purchaseOrderQueryOptions);
		void queryClient.prefetchQuery(purchaseOrderLinesQueryOptions);
		void queryClient.prefetchQuery(commentsQueryOptions);
	},
	wrapInSuspense: true,
	component: OrderRoute,
});

function OrderRoute() {
	const navigate = Route.useNavigate();
	const history = useRouterHistory();
	const { t } = useTranslation('order');
	const { purchaseOrderQueryOptions, purchaseOrderLinesQueryOptions, commentsQueryOptions } =
		Route.useRouteContext();
	const [orderQuery, linesQuery, commentsQuery] = useSuspenseQueries({
		queries: [purchaseOrderQueryOptions, purchaseOrderLinesQueryOptions, commentsQueryOptions],
	});

	return (
		<DetailsLayout
			title={t('orderDetails.title', {
				orderNumber: orderQuery.data.purchaseOrderNumber,
			})}
		>
			<OrderDetails
				order={orderQuery.data}
				address={orderQuery.data}
				internationalAddress={
					orderQuery.data.hasInternationalAddress ? orderQuery.data : undefined
				}
				lines={linesQuery.data}
				comments={commentsQuery.data}
				onOrderSaved={() =>
					history.canGoBack() ? history.back() : void navigate({ to: '/orders/search' })
				}
			/>
		</DetailsLayout>
	);
}
