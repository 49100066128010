import { useCallback } from 'react';
import { useSuspenseQueries } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';

import { requireAuth } from '@apple/features/auth';
import { CurrencyDetail } from '@apple/features/currencies/components/CurrencyDetail';
import { useCurrencyManagement } from '@apple/features/currencies/hooks/useCurrencyManagement';
import { currencyQueries } from '@apple/features/currencies/queries';
import { useTranslation } from '@apple/lib/i18next';
import { DetailsLayout } from '@apple/ui/layouts';
import { globalizationQueryOptions } from '@apple/utils/globalization/queries';
import type { CurrencyConversion } from '@apple/features/currencies/models';

export const Route = createFileRoute('/_authed/_admin/manage/currencies_/add')({
	component: ManageCurrencyRoute,
	beforeLoad: args => {
		requireAuth(args);

		return {
			activeCurrencyConversionsQueryOptions: currencyQueries.getActiveConversions(),
			supportedCurrenciesQueryOptions: globalizationQueryOptions.supportedCurrencies,
		};
	},
	loader: ({
		context: {
			queryClient,
			activeCurrencyConversionsQueryOptions,
			supportedCurrenciesQueryOptions,
		},
	}) => {
		void queryClient.prefetchQuery(activeCurrencyConversionsQueryOptions);
		void queryClient.prefetchQuery(supportedCurrenciesQueryOptions);
	},
	wrapInSuspense: true,
});

function ManageCurrencyRoute() {
	const navigate = Route.useNavigate();
	const { activeCurrencyConversionsQueryOptions, supportedCurrenciesQueryOptions } =
		Route.useRouteContext();
	const { t } = useTranslation('manage');
	const [activeCurrencyConversionsQuery, supportedCurrenciesQuery] = useSuspenseQueries({
		queries: [activeCurrencyConversionsQueryOptions, supportedCurrenciesQueryOptions],
	});
	const { add } = useCurrencyManagement();

	const handleSave = useCallback(
		async (currencyConversion: CurrencyConversion) => {
			await add.mutateAsync(currencyConversion, {
				onSuccess: () => {
					void navigate({
						to: '/manage/currencies',
					});
				},
			});
		},
		[add, navigate],
	);

	return (
		<DetailsLayout title={t('currencies.add')}>
			<CurrencyDetail
				onSave={handleSave}
				loading={add.isPending}
				submitted={add.isSuccess}
				activeConversions={activeCurrencyConversionsQuery.data}
				supportedCurrencies={supportedCurrenciesQuery.data}
			/>
		</DetailsLayout>
	);
}
