import { useCallback, useState } from 'react';
import { useForm } from '@mantine/form';
import { getLogger } from 'loglevel';
import type { UseFormInput, UseFormReturnType } from '@mantine/form';
import type { ReactElement, ReactNode } from 'react';

import { useTranslation } from '@apple/lib/i18next';
import { ServerError, ServerValidationError } from '@apple/utils/api';
import type { AllKeysAreStrings } from '@apple/utils/type';
import type { ErrorMessage, ErrorMessages, FormErrors } from '@apple/utils/validation';

import { FormError } from '../FormError';
import { SubmitButton } from '../SubmitButton';
import type { SubmitButtonProps } from '../SubmitButton';

const log = getLogger('validation');

export type ValidatedFormProps<T extends AllKeysAreStrings<T>> = UseFormInput<T> & {
	/** A unique name for the form */
	name: string;
	onValidSubmit: (data: T) => Promise<void>;
	onInvalidSubmit?: () => void;
	onAdditionalValidation?: (data: T) => ErrorMessage | ErrorMessages | null | undefined;
	fallbackErrorMessage?: string;
	ignoreModelErrorParent?: boolean;
	children: (props: {
		isSubmitting: boolean;
		hasSubmitted: boolean;
		form: UseFormReturnType<T>;
		formErrors: FormErrors | undefined;
		submit: () => void;
		renderFormErrors: (excludeFieldErrors?: boolean) => ReactElement;
		renderSubmitButton: (
			props: Omit<SubmitButtonProps, 'isSubmitting' | 'form'>,
		) => ReactElement<SubmitButtonProps>;
	}) => ReactNode;
};

export function ValidatedForm<T extends AllKeysAreStrings<T>>({
	name,
	initialValues,
	onValidSubmit,
	onInvalidSubmit,
	onAdditionalValidation,
	fallbackErrorMessage,
	validate,
	children,
	ignoreModelErrorParent = false,
	...useFormProps
}: ValidatedFormProps<T>) {
	const { t } = useTranslation('auth');
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [hasSubmitted, setHasSubmitted] = useState(false);
	const [formErrors, setFormErrors] = useState<FormErrors>();

	const form = useForm<T>({
		validateInputOnChange: true,
		validateInputOnBlur: true,

		...useFormProps,
		name,
		initialValues,
		validate,
	});

	const transformServerValidationErrorField = useCallback(
		(field: string) => {
			let fieldPath = field.split('.');
			if (fieldPath.length <= 1) {
				return field;
			}

			if (ignoreModelErrorParent) {
				fieldPath = fieldPath.slice(1);
			}

			// Nested fields are not camel-cased in model validation error response
			for (let i = 0; i < fieldPath.length; i++) {
				const value = fieldPath[i];
				if (value === undefined) {
					continue;
				}
				fieldPath[i] = value.charAt(0).toLowerCase() + value.slice(1);
			}

			// Pathing from the backend looks like an array indexer:
			//  list[0].field
			// in Mantine form it uses dots:
			//  list.0.field
			return fieldPath.join('.').replace(']', '').replace('[', '.');
		},
		[ignoreModelErrorParent],
	);

	const handleValidationError = useCallback(
		(error: ServerValidationError<T>) => {
			if (error.problem.formErrors) {
				log.warn('Form level errors:', error.problem.formErrors?.length ?? 0);
				setFormErrors(error.problem.formErrors);
			}

			if (error.problem.fieldErrors) {
				const fieldErrors = Object.entries<ErrorMessage | ErrorMessages>(
					error.problem.fieldErrors,
				);

				log.warn('Field level errors:', fieldErrors.length);
				fieldErrors.forEach(([field, error]) => {
					form.setFieldError(transformServerValidationErrorField(field), error);
				});
			}
		},
		[form, transformServerValidationErrorField],
	);

	const handleError = useCallback((error: Error) => {
		log.warn('Server error:', error.message);
		setFormErrors([error.message]);
	}, []);

	const handleSubmit = useCallback(
		async (data: T) => {
			log.info(`Handling '${name}' form submit:`, data);
			setIsSubmitting(true);
			setHasSubmitted(true);

			try {
				log.info(`Validating '${name}' form:`, data);

				if (onAdditionalValidation) {
					const result = onAdditionalValidation(data);

					if (result && result.length > 0) {
						log.error('Additional form validation failed:', result);
						setFormErrors(typeof result === 'string' ? [result] : result);
					}
				}

				const validateResult = form.validate();
				if (validateResult.hasErrors) {
					log.warn('Form validation failed:', data, validateResult);
					return;
				}

				log.info(`Submitting '${name}' form:`, data);
				await onValidSubmit(data);
				setFormErrors(undefined);
			} catch (error) {
				log.warn('An error occurred while attempting to submit form:\n', error);

				if (error instanceof ServerValidationError) {
					log.warn('Validation error:', error.message);
					handleValidationError(error as ServerValidationError<T>);
				} else if (error instanceof ServerError) {
					log.error('Server error:', error.message);
					handleError(error);
				} else {
					log.error('Unknown error:', error);
					handleError(new Error(fallbackErrorMessage ?? t('common:error.generic')));
				}

				if (onInvalidSubmit) {
					log.info(`Handling '${name}' form invalid submit`);
					onInvalidSubmit();
				}
			} finally {
				setIsSubmitting(false);
			}
		},
		[
			fallbackErrorMessage,
			form,
			handleError,
			handleValidationError,
			name,
			onAdditionalValidation,
			onInvalidSubmit,
			onValidSubmit,
			t,
		],
	);

	return (
		<form
			onSubmit={form.onSubmit(
				(values, e) => {
					log.info(`Submitting '${name}' form`);
					// Prevents interference with child forms
					e?.stopPropagation();
					void handleSubmit(values);
				},
				(errors, values) => {
					log.warn(`Form '${name}' has errors:`, errors, values);
				},
			)}
		>
			{children({
				isSubmitting,
				hasSubmitted,
				form,
				formErrors,
				submit: () => {
					log.info(`Submitting '${name}' form`);
					form.onSubmit(
						() => {
							log.info(`Form '${name}' validation passed`);
							void handleSubmit(form.values);
						},
						(errors, values) => {
							log.warn(`Form '${name}' has errors:`, errors, values);
						},
					)();
				},
				renderFormErrors: (excludeFieldErrors = false) => (
					<FormError errors={formErrors ?? (excludeFieldErrors ? [] : form.errors)} />
				),
				renderSubmitButton: props => (
					<SubmitButton
						{...props}
						form={form as UseFormReturnType<unknown>}
						isSubmitting={isSubmitting}
					/>
				),
			})}
		</form>
	);
}
