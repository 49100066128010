import axios, { CanceledError } from 'axios';
import log from 'loglevel';
import type { PaginationState as TanstackPaginationState } from '@tanstack/react-table';

import { createODataPageResultSchema, ServerError } from '@apple/utils/api';
import { getPageState } from '@apple/utils/pagination';
import type { PageState } from '@apple/utils/pagination';

import { allowedOrderSearchFiltersSchema, orderSearchListingSchema } from '../models/search';
import type {
	AllowedOrderSearchFilters,
	OrderSearchFilters,
	OrderSearchListing,
} from '../models/search';

const orderSearchODataSchema =
	createODataPageResultSchema<OrderSearchListing>(orderSearchListingSchema);

export async function getOrderSearchResults(
	pagination: TanstackPaginationState,
	filters: OrderSearchFilters,
	onError?: (error: Error) => void,
): Promise<PageState<OrderSearchListing>> {
	try {
		const response = await axios.get<unknown>('/api/order-search', {
			params: {
				skip: pagination.pageIndex * pagination.pageSize,
				take: pagination.pageSize,
				filter: filters,
			},
		});

		const result = orderSearchODataSchema.parse(response.data);

		return getPageState<OrderSearchListing>({
			rowSchema: orderSearchListingSchema,
			state: pagination,
			rows: result.items,
			totalRowCount: result.count ?? 0,
		});
	} catch (err) {
		if (err instanceof ServerError && !(err.innerError instanceof CanceledError)) {
			log.error('Error searching orders:', err);

			if (onError) {
				onError(err);
			}

			return getPageState<OrderSearchListing>({
				rowSchema: orderSearchListingSchema,
				state: pagination,
				rows: [],
				totalRowCount: 0,
			});
		}

		throw err;
	}
}

export async function getAllowedOrderSearchFields(): Promise<AllowedOrderSearchFilters> {
	const data = (await axios.get<unknown>('/api/order-search/options')).data;
	return allowedOrderSearchFiltersSchema.parse(data);
}

export function getOrderSearchExportUrl() {
	return '/api/order-search/export';
}
