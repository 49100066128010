import { useCallback } from 'react';
import { Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { queryOptions, useMutation, useSuspenseQueries } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { icons } from '@apple/assets';
import { requireAuth } from '@apple/features/auth';
import { CustomerForm } from '@apple/features/customer/components/CustomerManagement';
import {
	deleteCustomer,
	getSingleCustomer,
	saveCustomer,
} from '@apple/features/customer/management';
import { programsQueryOptions } from '@apple/features/program/queries';
import { useTranslation } from '@apple/lib/i18next';
import { useRouterHistory } from '@apple/lib/tanstack-router/hooks/use-router-history';
import { DetailsLayout } from '@apple/ui/layouts';
import { ServerValidationError } from '@apple/utils/api';

export const Route = createFileRoute('/_authed/_admin/manage/customers_/$customerCode')({
	parseParams: ({ customerCode }) => ({
		customerCode: z.string().parse(customerCode),
	}),
	beforeLoad: ({ context, location, params: { customerCode }, cause }) => {
		requireAuth(
			{ context, location, cause },
			{
				requirePermission: 'Manzanita.Security.Features.CustomerManagement',
			},
		);

		return {
			customerQueryOptions: queryOptions({
				queryKey: ['customer', customerCode],
				queryFn: () => getSingleCustomer(customerCode),
				staleTime: 5 * 60 * 1000, // 5 minutes
			}),
			programsQueryOptions: programsQueryOptions(),
		};
	},
	loader: ({ context: { queryClient, customerQueryOptions, programsQueryOptions } }) => {
		void queryClient.prefetchQuery(customerQueryOptions);
		void queryClient.prefetchQuery(programsQueryOptions);
	},
	wrapInSuspense: true,
	component: CustomerRoute,
});

function CustomerRoute() {
	const { t } = useTranslation('customer');
	const { customerQueryOptions, programsQueryOptions, queryClient } = Route.useRouteContext();

	const [customerQuery, programsQuery] = useSuspenseQueries({
		queries: [customerQueryOptions, programsQueryOptions],
	});

	const navigate = Route.useNavigate();
	const history = useRouterHistory();

	const updateCustomer = useMutation({
		mutationFn: saveCustomer,
		onSuccess: async () => {
			notifications.show({
				color: 'green',
				message: t('update.success'),
				icon: <icons.Success />,
			});
			await queryClient.invalidateQueries({
				queryKey: customerQueryOptions.queryKey,
			});
			await queryClient.invalidateQueries({ queryKey: ['customers-odata'] });
			history.canGoBack() ? history.back() : await navigate({ to: '/manage/customers' });
		},
		onError: (error: Error) => {
			if (!(error instanceof ServerValidationError)) {
				notifications.show({
					message: t('common:error.generic'),
					icon: <icons.ErrorIcon />,
					color: 'red',
					autoClose: 5000,
				});
			}
		},
	});

	const deleteCustomerMutation = useMutation({
		mutationFn: deleteCustomer,
		onSuccess: async () => {
			notifications.show({
				color: 'green',
				message: t('delete.success'),
				icon: <icons.Success />,
			});
			await queryClient.invalidateQueries({
				queryKey: ['customers-odata'],
			});
			history.canGoBack() ? history.back() : await navigate({ to: '/manage/customers' });
		},
		onError: () => {
			notifications.show({
				color: 'red',
				message: t('delete.error'),
				icon: <icons.ErrorIcon />,
			});
		},
	});

	const handleDeleteCustomer = useCallback(
		(customerCode: string) => {
			modals.openConfirmModal({
				title: t('delete.title'),
				labels: {
					confirm: t('common:buttons.confirm'),
					cancel: t('common:buttons.cancel'),
				},
				onConfirm: () => deleteCustomerMutation.mutate(customerCode),
				children: <Text>{t('delete.message')}</Text>,
			});
		},
		[deleteCustomerMutation, t],
	);

	return (
		<DetailsLayout title={t('title.edit')}>
			<CustomerForm
				isEdit={true}
				onClose={() =>
					history.canGoBack()
						? history.back()
						: void navigate({ to: '/manage/customers' })
				}
				onSaveCustomer={updateCustomer.mutateAsync}
				customer={customerQuery.data}
				programs={programsQuery.data}
				onDeleteCustomer={handleDeleteCustomer}
			/>
		</DetailsLayout>
	);
}
