import { Card } from '@mantine/core';
import cx from 'clsx';

import classes from './card.module.css';

declare module '@mantine/core' {
	export interface CardProps {
		variant?: 'selectable' | 'with-heading';
	}
}

export const CardTheme = Card.extend({
	classNames: (_theme, props, _ctx) => ({
		root: cx(
			classes.card,
			props.variant === 'selectable' && classes.selectable,
			props.variant === 'with-heading' && classes.cardWithHeading,
		),
		section: cx(props.variant === 'with-heading' && classes.sectionWithHeading),
	}),
});
