import { createElement, useMemo } from 'react';
import { Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { keepPreviousData, queryOptions, useQueries, useQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import {
	getCoreRowModel,
	getFacetedMinMaxValues,
	getFacetedUniqueValues,
	useReactTable,
} from '@tanstack/react-table';
import dayjs from 'dayjs';
import { camelCase } from 'lodash-es';
import { z } from 'zod';
import type { SortingState } from '@tanstack/react-table';

import { icons } from '@apple/assets';
import { AddressDisplay } from '@apple/features/address/components/address-display';
import { requireAuth } from '@apple/features/auth';
import { getOrderSearchExportUrl } from '@apple/features/order-channel/api/search';
import {
	allowedOrderSearchFieldsQueryOptions,
	getOrderSearchQueryOptions,
} from '@apple/features/order-channel/queries/search';
import { OrderStatusDisplay } from '@apple/features/order/components/status';
import { orderStatusFilterSchema } from '@apple/features/order/models/status';
import { plantQueryOptions } from '@apple/features/plants/queries/queries';
import { programsQueryOptions } from '@apple/features/program/queries';
import { getProjects } from '@apple/features/project/api';
import { useTranslation } from '@apple/lib/i18next';
import { createFilterOnlyColumn, DataTable, useTableState } from '@apple/ui/data-table';
import { TableLayout } from '@apple/ui/layouts';
import { Link } from '@apple/ui/link';
import { PopoverText } from '@apple/ui/typogrophy/components/popover-text';
import { ToolbarExportButton } from '@apple/utils/files';
import { FormattedDate, globalizationQueryOptions } from '@apple/utils/globalization';
import type {
	OrderSearchFilters,
	OrderSearchListing,
} from '@apple/features/order-channel/models/search';
import type { Project } from '@apple/features/project/models';
import type { QueryOptions } from '@apple/lib/tanstack-query';

const orderSearchParamsSchema = z.object({
	userId: z.string().optional(),
	appleId: z.string().optional(),
	/** Sales or Purchase Order Number  */
	orderNumber: z.string().optional(),
});
const defaultSorting: SortingState = [{ id: 'orderData', desc: true }];

export const Route = createFileRoute('/_authed/orders/search')({
	// TODO: Create a minimal repro for the route not loading due to this schema...
	// validateSearch: createDataTableSearchSchema<OrderSearchParams>(orderSearchParamsSchema),
	validateSearch: orderSearchParamsSchema,
	beforeLoad: args => {
		requireAuth(args);

		return {
			// ordersQueryOptions: getOrderSearchQueryOptions(pagination, filters),
			// getOrderQueryOptions: (sorting: SortingState) => {
			// 	return orderSearchQueryOptions(pagination, filters, sorting)
			// },
			optionsQueryOptions: allowedOrderSearchFieldsQueryOptions,
			plantsQueryOptions: plantQueryOptions.plants,
			countriesWithCulturesQueryOptions: globalizationQueryOptions.countriesWithCultures(),
			programsQueryOptions: programsQueryOptions(),
			projectsQueryOptions: queryOptions({
				queryKey: ['projects'],
				queryFn: () => getProjects(),
				placeholderData: keepPreviousData,
				staleTime: 5 * 60 * 1000, // 5 minutes
			}) as QueryOptions<Project[]>,
		};
	},
	loaderDeps: ({ search }) => search,
	loader: ({
		context: {
			queryClient,
			optionsQueryOptions,
			plantsQueryOptions,
			countriesWithCulturesQueryOptions,
			programsQueryOptions,
		},
	}) => {
		void queryClient.prefetchQuery(optionsQueryOptions);
		void queryClient.prefetchQuery(plantsQueryOptions);
		void queryClient.prefetchQuery(countriesWithCulturesQueryOptions);
		void queryClient.prefetchQuery(programsQueryOptions);
	},
	component: OrderSearchRoute,
});

function OrderSearchRoute() {
	const { t } = useTranslation('order');
	const search = Route.useSearch();
	const navigate = Route.useNavigate();
	const defaultFilters: OrderSearchFilters = {
		orderDateRangeInDays: 60,
		startDate: dayjs().subtract(60, 'days').startOf('day').toDate(),
	};

	const tableState = useTableState<OrderSearchListing, OrderSearchFilters>({
		search,
		navigate,
		defaultFilters,
		searchFilters: [
			...(search.orderNumber ? [{ id: 'orderNumber', value: search.orderNumber }] : []),
			...(search.appleId ? [{ id: 'appleId', value: search.appleId }] : []),
			...(search.userId ? [{ id: 'userId', value: search.userId }] : []),
		],
		defaultSorting,
		fieldMap: [],
	});

	const {
		optionsQueryOptions,
		plantsQueryOptions,
		projectsQueryOptions,
		countriesWithCulturesQueryOptions,
		programsQueryOptions,
	} = Route.useRouteContext();
	const [optionsQuery, plantsQuery] = useQueries({
		queries: [optionsQueryOptions, plantsQueryOptions],
	});

	const filterableFields = useMemo(
		() => optionsQuery.data?.filters.map(x => x.id) ?? [],
		[optionsQuery.data?.filters],
	);

	const projectsQuery = useQuery({
		...projectsQueryOptions,
		enabled: filterableFields.includes('Project'),
	});

	const countriesQuery = useQuery({
		...countriesWithCulturesQueryOptions,
		enabled: filterableFields.includes('Country'),
	});

	const programsQuery = useQuery({
		...programsQueryOptions,
		enabled: filterableFields.includes('Program'),
	});

	const orderSearchQuery = useQuery(
		getOrderSearchQueryOptions(tableState.state.pagination, tableState.currentFilterData, _ => {
			notifications.show({
				color: 'red',
				message: t('common:error.fetchOrders'),
				icon: createElement(icons.ErrorIcon),
			});
		}),
	);

	const plantCodes = useMemo(
		() => plantsQuery.data?.map(x => x.warehouseCode) ?? [],
		[plantsQuery.data],
	);

	const projectIds = useMemo(
		// TODO: This needs to show the name of the project, not the ID
		() => projectsQuery.data?.map(x => x.id) ?? [],
		[projectsQuery.data],
	);

	const countryCodes = useMemo(
		() => countriesQuery.data?.map(x => x.code) ?? [],
		[countriesQuery.data],
	);

	const programIds = useMemo(
		() => programsQuery.data?.map(x => x.id) ?? [],
		[programsQuery.data],
	);

	const table = useReactTable<OrderSearchListing>({
		data: orderSearchQuery.data.rows,
		rowCount: orderSearchQuery.data.totalRowCount,
		...tableState,
		enableFilters: true,
		enableColumnFilters: true,
		enableSorting: true,
		manualFiltering: true,
		manualSorting: true,
		manualPagination: true,
		getCoreRowModel: getCoreRowModel(),
		getFacetedMinMaxValues: getFacetedMinMaxValues(),
		getFacetedUniqueValues: (table, columnId) => {
			switch (columnId) {
				case 'projectId':
					return () => new Map(projectIds.map(x => [x, 1]));
				case 'warehouseCode':
					return () => new Map(plantCodes.map(x => [x, 1]));
				case 'orderStatus':
					return () => new Map(orderStatusFilterSchema._def.values.map(x => [x, 1]));
				case 'countryCode':
					return () => new Map(countryCodes.map(x => [x, 1]));
				case 'customerGroupId':
					return () => new Map(programIds.map(x => [x, 1]));
				case 'orderDateRangeInDays':
					return () =>
						new Map([
							['30', 1],
							['60', 1],
							['90', 1],
							['custom', 1],
						]);
				default:
					return getFacetedUniqueValues<OrderSearchListing>()(table, columnId);
			}
		},
		columnResizeMode: 'onChange',
		columns: [
			{
				id: 'orderNumber',
				accessorKey: 'purchaseOrderNumber',
				header: t('filters.labels.orderNumber'),
				filter: {
					group: t('filters.groups.labels.allFilters'),
				},
				cell: ({ row }) => (
					<Link
						to='/orders/$poNumber'
						params={{ poNumber: row.original.purchaseOrderNumber }}
						title={t('searchResults.table.links.viewOrderDetails')}
					>
						<Text size='sm'>{row.original.purchaseOrderNumber}</Text>
					</Link>
				),
			},
			{
				id: 'orderDate',
				header: t('searchResults.table.columns.orderDate'),
				accessorKey: 'orderDate',
				enableColumnFilter: false,
				cell: ({ row }) => (
					<FormattedDate value={row.original.orderDate} humanize size='sm' />
				),
			},
			createFilterOnlyColumn<OrderSearchListing, OrderSearchFilters>({
				group: t('filters.groups.labels.allFilters'),
				label: t('filters.labels.orderDate'),
				field: 'orderDateRangeInDays',
				variant: 'select',
				getFilterDisplayValue: value =>
					value === 'custom'
						? t('controls:dataTable.filters.options.dateRange.custom')
						: t('controls:dataTable.filters.options.dateRange.lastNumberOfDays', {
								days: value,
							}),
			}),
			createFilterOnlyColumn<OrderSearchListing, OrderSearchFilters>({
				group: t('filters.groups.labels.allFilters'),
				label: t('filters.labels.startDate'),
				field: 'startDate',
				variant: 'date',
				hidden: tableState.currentFilterData.orderDateRangeInDays !== 'custom',
			}),
			createFilterOnlyColumn<OrderSearchListing, OrderSearchFilters>({
				group: t('filters.groups.labels.allFilters'),
				label: t('filters.labels.endDate'),
				field: 'endDate',
				variant: 'date',
				hidden: tableState.currentFilterData.orderDateRangeInDays !== 'custom',
			}),
			createFilterOnlyColumn<OrderSearchListing, OrderSearchFilters>({
				group: t('filters.groups.labels.allFilters'),
				label: t('filters.labels.partNumber'),
				variant: 'text',
				field: 'partNumber',
				hidden: !filterableFields.includes('PartNumber'),
			}),
			createFilterOnlyColumn<OrderSearchListing, OrderSearchFilters>({
				group: t('filters.groups.labels.allFilters'),
				label: t('filters.labels.username'),
				variant: 'text',
				field: 'userId',
				hidden: !filterableFields.includes('UserId'),
			}),
			{
				header: t('filters.labels.location'),
				accessorKey: 'displayName',
				minSize: 250,
				enableColumnFilter: false,
				// grow: 1,
				cell: ({ row }) => (
					<PopoverText
						size='sm'
						label={row.original.recipient}
						testId={`display-address-${row.original.purchaseOrderNumber}`}
					>
						<AddressDisplay
							stackProps={{ m: 0, p: 0 }}
							label={row.original.displayName}
							recipient={row.original.recipient}
							attentionTo={row.original.attentionTo}
							line1={row.original.line1}
							line2={row.original.line2}
							line3={row.original.line3}
							city={row.original.city}
							stateOrProvince={row.original.stateOrProvince}
							postalCode={row.original.postalCode}
							countryCode={row.original.countryCode}
							countryDisplayName={row.original.countryDisplayName}
							phoneNumber={row.original.phoneNumber}
						/>
					</PopoverText>
				),
			},
			{
				header: t('searchResults.table.columns.appleId'),
				accessorKey: 'appleId',
				filter: {
					group: t('filters.groups.labels.allFilters'),
				},
				enableColumnFilter: filterableFields.includes('AppleId'),
			},
			{
				id: 'orderStatus',
				accessorKey: 'status',
				header: t('searchResults.table.columns.status'),
				filter: {
					group: t('filters.groups.labels.allFilters'),
					variant: 'select',
					getFilterDisplayValue: value => t(`orderStatus.${camelCase(value as string)}`),
				},
				cell: ({ row }) => (
					<OrderStatusDisplay
						status={row.original.status}
						isOrderMarkedForCancellation={row.original.isOrderMarkedForCancellation}
						size='sm'
					/>
				),
				enableColumnFilter: filterableFields.includes('OrderStatus'),
			},
			{
				header: t('searchResults.table.columns.soNumber'),
				accessorKey: 'salesOrderNumber',
				// `orderNumber` is used to filter both `purchaseOrderNumber` and `salesOrderNumber`
				enableColumnFilter: false,
				filter: {
					group: t('filters.groups.labels.allFilters'),
				},
			},
			createFilterOnlyColumn<OrderSearchListing, OrderSearchFilters>({
				group: t('filters.groups.labels.allFilters'),
				label: t('filters.labels.plant'),
				field: 'warehouseCode',
				variant: 'select',
				hidden: !filterableFields.includes('Plant'),
				getFilterDisplayValue: value =>
					plantsQuery.data?.find(x => x.warehouseCode === value)?.name ?? String(value),
			}),
			createFilterOnlyColumn<OrderSearchListing, OrderSearchFilters>({
				group: t('filters.groups.labels.allFilters'),
				label: t('filters.labels.country'),
				field: 'countryCode',
				variant: 'select',
				hidden: !filterableFields.includes('Country'),
				getFilterDisplayValue: value =>
					countriesQuery.data?.find(x => x.code === value)?.name ?? String(value),
			}),
			createFilterOnlyColumn<OrderSearchListing, OrderSearchFilters>({
				group: t('filters.groups.labels.allFilters'),
				label: t('filters.labels.program'),
				field: 'customerGroupId',
				variant: 'select',
				hidden: !filterableFields.includes('Program'),
				getFilterDisplayValue: value =>
					programsQuery.data?.find(x => x.id === Number(value))?.name ?? String(value),
			}),
			createFilterOnlyColumn<OrderSearchListing, OrderSearchFilters>({
				group: t('filters.groups.labels.allFilters'),
				label: t('filters.labels.project'),
				field: 'projectId',
				variant: 'select',
				// options: projectIds,
				filterFromString: Number,
				hidden: !filterableFields.includes('Project'),
				getFilterDisplayValue: value =>
					projectsQuery.data?.find(x => x.id === Number(value))?.name ?? String(value),
			}),
		],
	});

	return (
		<TableLayout
			title={t('searchResults.title')}
			table={table}
			toolbarButtons={[
				<ToolbarExportButton
					key='export'
					tooltip={t('controls:dataTable.exportResults')}
					exportUrl={getOrderSearchExportUrl()}
					fallbackFilename='OrderSearchResult.xlsx'
					filters={tableState.currentFilterData}
				/>,
			]}
		>
			<DataTable table={table} loading={orderSearchQuery.isFetching} />
		</TableLayout>
	);

	// TODO: Show/hide columns based on filterableFields
}
