import { useCallback, useMemo, useState } from 'react';
import { Container } from '@mantine/core';
import { useWindowScroll } from '@mantine/hooks';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { getLogger } from 'loglevel';
import type { QueryClient } from '@tanstack/react-query';

import { getFirstInvalidStep } from '@apple/channel/routes/_authed/-steps/validation';
import { setLocationSelections } from '@apple/features/cart-channel/api';
import { orderSubmissionFormActions } from '@apple/features/cart-channel/components/OrderOptions/form-actions';
import { useSelectedQuantities } from '@apple/features/cart-channel/hooks/useSelectedQuantities';
import { useSelectedShippingAddress } from '@apple/features/cart-channel/hooks/useSelectedShippingAddress';
import { useSubmitCart } from '@apple/features/cart-channel/hooks/useSubmitCart';
import { cartQueryOptions } from '@apple/features/cart-channel/queries';
import { useCartId } from '@apple/features/cart/contexts/cart-id';
import { locationQueries } from '@apple/features/location/queries/location-listing.queries';
import { useTranslation } from '@apple/lib/i18next';
import { useBreakpoints } from '@apple/ui/layouts/hooks/useBreakpoints';
import { useLogger } from '@apple/utils/logging';
import type { OrderSubmissionRequest } from '@apple/features/cart-channel/models/submission';
import type { CartId } from '@apple/features/cart/models/cart-id';

import { ShopSteps } from './-steps/ShopSteps';
import { stepNameSchema, stepRouteParamsSchema } from './-steps/types';

const log = getLogger('cart');

export const Route = createFileRoute('/_authed/cart/$step')({
	params: {
		parse: x => stepRouteParamsSchema.parse(x),
		stringify: ({ step }) => ({ step: `${step}` }),
	},

	beforeLoad: async args => {
		const { bulkOrderId: cartId } = await args.context.queryClient.fetchQuery(
			cartQueryOptions.cartId(true),
		);
		const firstInvalidStep = await getFirstInvalidStep({
			cartId,
			queryClient: args.context.queryClient,
		});

		if (firstInvalidStep === 'locations' && !args.context.auth.canAccessMultipleLocations) {
			await addSingleLocation(args.context.queryClient, cartId);
			throw redirect({
				to: '/cart/$step',
				params: {
					step: 'products',
				},
			});
		}

		const indexOfCurrentStep = stepNameSchema._def.values.indexOf(args.params.step);
		const indexOfFirstInvalidStep = stepNameSchema._def.values.indexOf(firstInvalidStep);

		if (indexOfCurrentStep > indexOfFirstInvalidStep) {
			// Redirect to the first invalid step if it is not the requested step
			throw redirect({
				to: '/cart/$step',
				params: {
					step: firstInvalidStep,
				},
			});
		}

		return {
			stepName: args.params.step,
		};
	},
	component: CartStepRoute,
});

function CartStepRoute() {
	const { t } = useTranslation('shop');
	const { auth, stepName } = Route.useRouteContext();
	const navigate = Route.useNavigate();
	const [_, scroll] = useWindowScroll();
	const cartId = useCartId();
	const quantitySelections = useSelectedQuantities();
	const shippingAddressesSelections = useSelectedShippingAddress();
	const submission = useSubmitCart();
	const { smOrSmaller, mdOrSmaller } = useBreakpoints();

	const [manualValidationErrors, setManualValidationErrors] = useState({
		invalidItems: false,
		invalidShippingAddress: false,
	});

	const everyLocationWithQuantitiesHasShippingAddress = useMemo(
		() =>
			quantitySelections.locationsWithQuantities.every(x =>
				shippingAddressesSelections.query.data?.some(y => x.locationId == y.shipToKey),
			) && (shippingAddressesSelections.query.data?.length ?? 0) > 0,
		[shippingAddressesSelections.query.data, quantitySelections.locationsWithQuantities],
	);

	const canBeSubmitted = useMemo(() => {
		return (
			quantitySelections.locationsWithQuantities.length > 0 &&
			everyLocationWithQuantitiesHasShippingAddress
		);
	}, [quantitySelections.locationsWithQuantities, everyLocationWithQuantitiesHasShippingAddress]);

	useLogger({
		name: 'CartStepRoute',
		props: [{ auth, stepName, canBeSubmitted, everyLocationWithQuantitiesHasShippingAddress }],
		log,
	});

	const handleSubmit = useCallback(
		async (request: OrderSubmissionRequest) => {
			const invalidItems = quantitySelections.locationsWithQuantities.length <= 0;
			const invalidShippingAddress = !everyLocationWithQuantitiesHasShippingAddress;

			setManualValidationErrors({ invalidItems, invalidShippingAddress });

			if (!canBeSubmitted || invalidItems || invalidShippingAddress) {
				log.warn('Cannot submit order:', {
					canBeSubmitted,
					invalidItems,
					invalidShippingAddress,
				});
				return;
			}

			// Mutate resets the cartId so we need to store it before calling it
			const submittedCartId = cartId;

			log.debug('Submitting order:', { submittedCartId, request });

			await submission.mutation.mutateAsync(
				{
					orderReason: request.shopOrderType ?? '',
					options: {
						holdUntilDate: request.shopOrderDate,
						isGhostShipment: request.ghostShipment,
						orderComments: request.shopOrderComments,
						orderName: request.shopOrderName,
						orderNumber: request.shopPurchaseOrderNumber,
						userAcknowledgedApprovalRequired: !!request.shopOrderComments,
					},
					onCommentRequiredError() {
						orderSubmissionFormActions.setFieldError(
							'shopOrderComments',
							t('error.commentRequired'),
						);
					},
				},
				{
					onSuccess: () =>
						void navigate({
							to: '/cart/$cartId/confirmation',
							params: { cartId: submittedCartId },
							resetScroll: true,
						}),
					onError: () => {
						// Scroll to the top of the page to ensure that the user sees the error message.
						scroll({ x: 0, y: 0 });
					},
				},
			);
		},
		[
			quantitySelections.locationsWithQuantities.length,
			everyLocationWithQuantitiesHasShippingAddress,
			canBeSubmitted,
			cartId,
			submission.mutation,
			t,
			navigate,
			scroll,
		],
	);

	return (
		<Container size='xl' p='md'>
			<ShopSteps
				currentStepIndex={
					stepNameSchema._def.values.indexOf(stepName) -
					(auth.canAccessMultipleLocations ? 0 : 1)
				}
				locationStepVisible={auth.canAccessMultipleLocations}
				stepperVerticalOrientation={smOrSmaller}
				hideDescription={mdOrSmaller}
				onGotoStep={step => {
					const stepName = stepNameSchema._def.values.at(
						step + (auth.canAccessMultipleLocations ? 0 : 1),
					);
					void navigate({ to: '/cart/$step', params: { step: stepName } });
				}}
				invalidItems={manualValidationErrors.invalidItems}
				invalidShippingAddress={manualValidationErrors.invalidShippingAddress}
				submissionPending={submission.mutation.isPending}
				onSubmit={handleSubmit}
			/>
		</Container>
	);
}

async function addSingleLocation(queryClient: QueryClient, cartId: CartId) {
	const locations = await queryClient.fetchQuery(locationQueries.getLocations({}));
	if (locations.locations.items.length !== 1) {
		log.error(
			'Expected exactly one location to be available, but found:',
			locations.locations.items,
		);
		return;
	}

	const location = locations.locations.items.at(0)!;
	await setLocationSelections({
		bulkOrderId: cartId,
		isSelected: true,
		locationSelection: {
			locationId: location.id,
			programs: location.programs.map(x => x.customerGroupId),
		},
	});
}
