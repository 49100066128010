import axios from 'axios';

import { userFileModelSchema } from '@apple/features/file-manager/models/models';
import type { UserFileModel } from '@apple/features/file-manager/models/models';

export async function getUserFiles(signal?: AbortSignal): Promise<UserFileModel[]> {
	const response = await axios.get('/api/user-files', {
		signal,
	});

	return userFileModelSchema.array().parse(response.data);
}

export function getUserFileDownloadUrl(documentId: string) {
	return `/api/user-files/${documentId}`;
}

export async function deleteUserFile(documentId: string): Promise<void> {
	await axios.delete(`/api/user-files/${documentId}`);
}

export async function uploadUserFile(file: File | null, description: string) {
	const formData = new FormData();
	file && formData.append('file0', file);
	formData.append('Description', description);

	await axios.put('/api/user-files', formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
}
