import { useCallback } from 'react';
import { Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { queryOptions, useMutation, useSuspenseQueries } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';

import { ErrorIcon, Success } from '@apple/assets/icons';
import { requireAuth } from '@apple/features/auth';
import { getProgramCountries, getProgramCustomers } from '@apple/features/program/api';
import { ProgramForm } from '@apple/features/program/components/ProgramForm';
import { deleteProgram, getProgram, updateProgram } from '@apple/features/program/management';
import { programIdSchema } from '@apple/features/program/models';
import { useTranslation } from '@apple/lib/i18next';
import { useRouterHistory } from '@apple/lib/tanstack-router/hooks/use-router-history';
import { DetailsLayout } from '@apple/ui/layouts';
import { ServerValidationError } from '@apple/utils/api';
import type { ProgramId } from '@apple/features/program/models';

export const Route = createFileRoute('/_authed/_admin/manage/programs_/$programId')({
	parseParams: ({ programId }) => ({
		programId: programIdSchema.parse(Number(programId)),
	}),
	beforeLoad: ({ context, location, params: { programId }, cause }) => {
		requireAuth(
			{ context, location, cause },
			{
				requirePermission: 'Manzanita.Security.Features.CustomerGroupManagement.Write',
			},
		);

		return {
			programQueryOptions: queryOptions({
				queryKey: ['program', programId],
				queryFn: () => getProgram(Number(programId)),
				staleTime: 5 * 60 * 1000, // 5 minutes
			}),
			programCountriesQueryOptions: queryOptions({
				queryKey: ['program-countries', programId],
				queryFn: () => getProgramCountries(Number(programId)),
			}),
			programCustomersQueryOptions: queryOptions({
				queryKey: ['program-customers', programId],
				queryFn: () => getProgramCustomers(Number(programId)),
			}),
		};
	},
	loader: ({
		context: {
			queryClient,
			programQueryOptions,
			programCountriesQueryOptions,
			programCustomersQueryOptions,
		},
	}) => {
		void queryClient.prefetchQuery(programQueryOptions);
		void queryClient.prefetchQuery(programCountriesQueryOptions);
		void queryClient.prefetchQuery(programCustomersQueryOptions);
	},
	wrapInSuspense: true,
	component: ProgramRoute,
});

function ProgramRoute() {
	const { t } = useTranslation('program');
	const {
		programQueryOptions,
		programCountriesQueryOptions,
		programCustomersQueryOptions,
		queryClient,
	} = Route.useRouteContext();

	const [programQuery, programCountriesQuery, programCustomersQuery] = useSuspenseQueries({
		queries: [programQueryOptions, programCountriesQueryOptions, programCustomersQueryOptions],
	});

	const navigate = Route.useNavigate();
	const history = useRouterHistory();

	const saveProgram = useMutation({
		mutationFn: updateProgram,
		onSuccess: async () => {
			notifications.show({
				color: 'green',
				message: t('update.success'),
				icon: <Success />,
			});
			await queryClient.invalidateQueries({
				queryKey: programQueryOptions.queryKey,
			});
			history.canGoBack() ? history.back() : await navigate({ to: '/manage/programs' });
		},
		onError: (error: Error) => {
			if (!(error instanceof ServerValidationError)) {
				notifications.show({
					message: t('common:error.generic'),
					icon: <ErrorIcon />,
					color: 'red',
					autoClose: 5000,
				});
			}
		},
	});

	const deleteProgramMutation = useMutation({
		mutationFn: deleteProgram,
		onSuccess: async () => {
			notifications.show({
				color: 'green',
				message: t('delete.success'),
				icon: <Success />,
			});
			await queryClient.invalidateQueries({
				queryKey: ['programs'],
			});
			history.canGoBack() ? history.back() : await navigate({ to: '/manage/programs' });
		},
		onError: () => {
			notifications.show({
				color: 'red',
				message: t('delete.error'),
				icon: <ErrorIcon />,
			});
		},
	});

	const handleDeleteProgram = useCallback(
		(programId: ProgramId) => {
			if (programCountriesQuery.data.length > 0 || programCustomersQuery.data.length > 0) {
				notifications.show({
					color: 'red',
					message: t('delete.error'),
					icon: <ErrorIcon />,
				});
				return;
			}

			modals.openConfirmModal({
				title: t('delete.title'),
				labels: {
					confirm: t('common:buttons.confirm'),
					cancel: t('common:buttons.cancel'),
				},
				onConfirm: () => deleteProgramMutation.mutate(programId),
				children: <Text>{t('delete.message')}</Text>,
			});
		},
		[deleteProgramMutation, programCountriesQuery.data, programCustomersQuery.data, t],
	);

	return (
		<DetailsLayout title={t('title.edit')}>
			<ProgramForm
				isEdit={true}
				onSaveProgram={saveProgram.mutateAsync}
				program={programQuery.data}
				onDeleteProgram={handleDeleteProgram}
			/>
		</DetailsLayout>
	);
}
