import { createFileRoute } from '@tanstack/react-router';

import { requireAuth } from '@apple/features/auth';
import { useEmailTable } from '@apple/features/emails/hooks/useEmailTable';
import { useTranslation } from '@apple/lib/i18next';
import { DataTable } from '@apple/ui/data-table';
import { TableLayout } from '@apple/ui/layouts';

export const Route = createFileRoute('/_authed/_admin/reports/emails')({
	beforeLoad: args =>
		requireAuth(args, {
			requirePermission: 'Manzanita.Security.Features.Reports',
		}),
	component: EmailsReportRoute,
});

function EmailsReportRoute() {
	const { t } = useTranslation('reports');
	const search = Route.useSearch();
	const navigate = Route.useNavigate();
	const { table, emailsQuery } = useEmailTable({ search, navigate });
	return (
		<TableLayout title={t('emails.title')} table={table}>
			<DataTable table={table} loading={emailsQuery.isFetching} />
		</TableLayout>
	);
}
