import { queryOptions } from '@tanstack/react-query';

import { getHealth } from './api';

export const healthQueryOptions = (withRefetchInterval = false) =>
	queryOptions({
		queryKey: ['health', withRefetchInterval],
		queryFn: getHealth,
		staleTime: 5 * 60 * 1000, // 5 minutes
		refetchInterval: withRefetchInterval ? 30 * 1000 : false, //30 seconds
		retry: !withRefetchInterval ? 1 : 0,
	});
