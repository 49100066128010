import { z } from 'zod';

export enum Weekday {
	Sunday = 0,
	Monday = 1,
	Tuesday = 2,
	Wednesday = 3,
	Thursday = 4,
	Friday = 5,
	Saturday = 6,
}

export interface WeekdayOption {
	displayName: string;
	value: Weekday;
}

export const weekdaySchema = z.nativeEnum(Weekday);

export const weekdayOptionSchema = z.object({
	displayName: z.string(),
	value: weekdaySchema,
}) satisfies z.ZodType<WeekdayOption>;
