import { Text } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import {
	getCoreRowModel,
	getFacetedMinMaxValues,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table';
import type { SortingState } from '@tanstack/react-table';

import { AddIcon } from '@apple/assets/icons';
import { requireAuth } from '@apple/features/auth';
import { programsQueryOptions } from '@apple/features/program/management.queries';
import { useTranslation } from '@apple/lib/i18next';
import { DataTable, useTableState } from '@apple/ui/data-table';
import { ToolbarButton } from '@apple/ui/data-table/controls/Toolbar';
import { TableLayout } from '@apple/ui/layouts';
import { Link } from '@apple/ui/link';
import type { ProgramFilters, ProgramListing } from '@apple/features/program/management.models';

export const Route = createFileRoute('/_authed/_admin/manage/programs')({
	beforeLoad: args => {
		requireAuth(args, {
			requirePermission: 'Manzanita.Security.Features.CustomerGroupManagement.Read',
		});

		return {
			programsQueryOptions: programsQueryOptions,
		};
	},
	loader: ({ context: { queryClient, programsQueryOptions } }) => {
		void queryClient.prefetchQuery(programsQueryOptions);
	},
	wrapInSuspense: true,
	component: ProgramManagementRoute,
});

const defaultFilters: ProgramFilters = {};
const defaultSorting: SortingState = [{ id: 'name', desc: false }];

function ProgramManagementRoute() {
	const { t } = useTranslation('program');
	const navigate = Route.useNavigate();
	const search = Route.useSearch();
	const { programsQueryOptions } = Route.useRouteContext();
	const programsQuery = useQuery(programsQueryOptions);

	const tableState = useTableState<ProgramListing, ProgramFilters>({
		search,
		navigate,
		defaultFilters: defaultFilters,
		defaultSorting: defaultSorting,
		fieldMap: [],
	});

	const table = useReactTable<ProgramListing>({
		data: programsQuery.data,
		...tableState,
		getCoreRowModel: getCoreRowModel(),
		getFacetedMinMaxValues: getFacetedMinMaxValues(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
		getFacetedRowModel: getFacetedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		enableFilters: true,
		enableColumnFilters: true,
		enableSorting: true,
		columnResizeMode: 'onChange',
		columns: [
			{
				accessorKey: 'name',
				header: t('labels.name'),
				filter: {
					group: t('filterGroups.details'),
				},
				size: 250,
				cell: ({ row }) => (
					<Link
						to='/manage/programs/$programId'
						params={{ programId: row.original.id }}
						title={row.original.name}
						children={row.original.name}
					/>
				),
			},
			{
				accessorKey: 'code',
				header: t('labels.customerGroupCode'),
				filter: {
					group: t('filterGroups.details'),
				},
			},
			{
				accessorKey: 'customerCount',
				header: t('labels.customerCount'),
				filter: {
					group: t('filterGroups.details'),
					variant: 'number-range-slider',
				},
				cell: ({ row }) => <Text>{row.original.customerCount}</Text>,
			},
			{
				id: 'status',
				accessorKey: 'status',
				header: t('labels.status'),
				filter: {
					group: t('filterGroups.details'),
					variant: 'select',
					getFilterDisplayValue: value => {
						return value === '1' ? t('labels.active') : t('labels.inactive');
					},
				},
				cell: ({ row }) => {
					const status =
						row.original.status === '1' ? t('labels.active') : t('labels.inactive');
					return <Text>{status}</Text>;
				},
			},
		],
	});

	return (
		<TableLayout
			title={t('title.list')}
			table={table}
			toolbarButtons={[
				<ToolbarButton
					key='add'
					tooltip={t('buttons.add')}
					icon={AddIcon}
					onClick={() =>
						void navigate({
							to: '/manage/programs/add',
						})
					}
				/>,
			]}
		>
			<DataTable table={table} loading={programsQuery.isFetching} />
		</TableLayout>
	);
}
