import { useCallback, useMemo, useState } from 'react';
import { Anchor, FocusTrap, Modal, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { keepPreviousData, queryOptions, useMutation, useQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import {
	getCoreRowModel,
	getFacetedMinMaxValues,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table';

import { icons } from '@apple/assets';
import { requireAuth } from '@apple/features/auth';
import { deleteProject, getAllProjects, saveProject } from '@apple/features/project/api';
import { ProjectForm } from '@apple/features/project/components/ProjectForm';
import { useTranslation } from '@apple/lib/i18next';
import { DataTable } from '@apple/ui/data-table';
import { ToolbarButton } from '@apple/ui/data-table/controls/Toolbar';
import { TableLayout } from '@apple/ui/layouts';
import type { Project, ProjectId } from '@apple/features/project/models';

export const Route = createFileRoute('/_authed/_admin/content/projects')({
	wrapInSuspense: true,
	component: ProjectManagePageRoute,
	beforeLoad: args => {
		requireAuth(args);

		return {
			projectsQueryOptions: queryOptions({
				queryKey: ['projects'],
				queryFn: () => getAllProjects(),
				placeholderData: keepPreviousData,
				throwOnError: true,
				initialData: [],
			}),
		};
	},
	loader: ({ context: { queryClient, projectsQueryOptions } }) => {
		void queryClient.prefetchQuery(projectsQueryOptions);
	},
});

function ProjectManagePageRoute() {
	const { t } = useTranslation('content');
	const { projectsQueryOptions, queryClient } = Route.useRouteContext();
	const projectsQuery = useQuery(projectsQueryOptions);

	const [selectedProject, setSelectedProject] = useState<Project>();
	const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure(false);
	const [isEditMode, setIsEditMode] = useState(false);

	const handleAddProject = useCallback(() => {
		setSelectedProject(undefined);
		setIsEditMode(false);
		openModal();
	}, [openModal]);

	const handleEditProject = useCallback(
		(project: Project) => {
			setSelectedProject(project);
			setIsEditMode(true);
			openModal();
		},
		[openModal],
	);

	const saveProjectMutation = useMutation({
		mutationFn: saveProject,
		onSuccess: async () => {
			notifications.show({
				color: 'green',
				message: isEditMode
					? t('projects.editProject.success')
					: t('projects.addProject.success'),
				icon: <icons.Success />,
			});
			await queryClient.invalidateQueries({ queryKey: ['projects'] });
		},
		onError: () => {
			notifications.show({
				color: 'red',
				message: t('common:error.generic'),
				icon: <icons.ErrorIcon />,
			});
		},
	});

	const deleteProjectMutation = useMutation({
		mutationFn: deleteProject,
		onSuccess: async () => {
			closeModal();
			notifications.show({
				color: 'green',
				message: t('projects.deleteProject.success'),
				icon: <icons.Success />,
			});
			await queryClient.invalidateQueries({
				queryKey: ['projects'],
			});
		},
		onError: () => {
			notifications.show({
				color: 'red',
				message: t('common:error.generic'),
				icon: <icons.ErrorIcon />,
			});
		},
	});

	const handleDeleteProject = useCallback(
		(projectId: ProjectId) => {
			modals.openConfirmModal({
				title: t('projects.deleteProject.title'),
				labels: {
					confirm: t('common:buttons.confirm'),
					cancel: t('common:buttons.cancel'),
				},
				onConfirm: () => deleteProjectMutation.mutate(projectId),
				children: <Text>{t('projects.deleteProject.message')}</Text>,
			});
		},
		[deleteProjectMutation, t],
	);

	const modalTitle = useMemo(() => {
		return isEditMode
			? t('projects.editProject.title', { project: selectedProject?.name ?? '' })
			: t('projects.addProject.title');
	}, [isEditMode, selectedProject, t]);

	const table = useReactTable({
		data: projectsQuery.data,
		getCoreRowModel: getCoreRowModel(),
		getFacetedMinMaxValues: getFacetedMinMaxValues(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
		getFacetedRowModel: getFacetedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		enableFilters: true,
		enableColumnFilters: true,
		enableSorting: true,
		columnResizeMode: 'onChange',
		initialState: {
			sorting: [
				{
					id: 'name',
					desc: true,
				},
			],
		},
		columns: [
			{
				accessorKey: 'name',
				header: t('projects.headers.name'),
				filter: {
					group: t('projects.filter-groups.details.title'),
				},
				size: 250,
				cell: ({ row }) => (
					<Anchor onClick={() => handleEditProject(row.original)} underline='never'>
						<Text>{row.original.name}</Text>
					</Anchor>
				),
			},
			{
				accessorKey: 'description',
				header: t('projects.headers.description'),
				filter: {
					group: t('projects.filter-groups.details.title'),
				},
			},
		],
	});

	return (
		<TableLayout
			table={table}
			title={t('projects.title')}
			toolbarButtons={[
				<ToolbarButton
					key='add'
					tooltip={t('projects.buttons.addProject')}
					icon={icons.AddIcon}
					onClick={handleAddProject}
				/>,
			]}
		>
			<DataTable table={table} loading={projectsQuery.isFetching} />
			<Modal
				id='project-dialog'
				title={modalTitle}
				opened={modalOpened}
				onClose={closeModal}
				closeOnClickOutside={false}
				size='lg'
			>
				<FocusTrap.InitialFocus />
				<ProjectForm
					project={selectedProject}
					onSaveProject={saveProjectMutation.mutate}
					onDeleteProject={isEditMode ? handleDeleteProject : undefined}
					onClose={closeModal}
					isEdit={isEditMode}
				/>
			</Modal>
		</TableLayout>
	);
}
