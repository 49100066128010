import axios, { AxiosError } from 'axios';
import * as AxiosLogger from 'axios-logger';
import type { AxiosInstance, AxiosResponse } from 'axios';

import { getEnv } from '@apple/utils/config/env';
import { sleep } from '@apple/utils/sleep';
import { parseServerError } from '@apple/utils/validation';

export function configAxios() {
	const { APPLE_API_URL, APPLE_DEV_API_REQUEST_DELAY_MS, DEV } = getEnv();
	axios.defaults.baseURL = APPLE_API_URL;
	axios.defaults.headers.common['x-use-camel-case'] = 'true';
	// https://github.com/axios/axios/issues/1322
	// https://github.com/aspnet/AspNetKatana/blob/43996b47015ca0c0ad12cdb6c87a017534eec620/src/Microsoft.Owin.Security.Cookies/Provider/DefaultBehavior.cs#L50
	axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
	// Forces sending cookies along with requests that are for a different domain than the site
	axios.defaults.withCredentials = true;
	// axiosBetterStacktrace(axios);

	if (DEV) {
		AxiosLogger.setGlobalConfig({
			// data: true,
			method: true,
			url: true,
			status: true,
			// headers: true,
			// params: true,
		});

		axios.interceptors.request.use(AxiosLogger.requestLogger, AxiosLogger.errorLogger);
		// axios.interceptors.response.use(AxiosLogger.responseLogger, AxiosLogger.errorLogger);
	}

	setupXsrfHandler(axios);
	setupServerValidationHandler(axios);

	if (APPLE_DEV_API_REQUEST_DELAY_MS !== undefined) {
		setupArtificialDelayHandler(axios, APPLE_DEV_API_REQUEST_DELAY_MS);
	}
}

// Helper function to get a cookie value by name
function getCookie(name: string): string | undefined {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop()?.split(';').shift();
	return undefined;
}

export function setupXsrfHandler(instance: AxiosInstance): void {
	const xsrfRequestHeaderName = 'RequestVerificationToken';
	const xsrfCookieName = 'XSRF-TOKEN';

	function updateXsrfToken(response: AxiosResponse) {
		let token = getCookie(xsrfCookieName);
		token = token !== undefined ? decodeURIComponent(token) : undefined;

		if (token !== undefined && axios.defaults.headers.common[xsrfRequestHeaderName] !== token) {
			axios.defaults.headers.common[xsrfRequestHeaderName] = token;
		}

		return response;
	}

	instance.interceptors.response.use(
		success => updateXsrfToken(success),
		error => {
			if (error instanceof AxiosError && error.response !== undefined) {
				updateXsrfToken(error.response);
			}
			throw error;
		},
	);
}

export function setupServerValidationHandler(instance: AxiosInstance): void {
	instance.interceptors.response.use(
		success => success,
		(error: unknown) => {
			throw parseServerError(error);
		},
	);
}

export function setupArtificialDelayHandler(instance: AxiosInstance, delayMs: number): void {
	instance.interceptors.response.use(
		async success => {
			await sleep(delayMs);
			return success;
		},
		async (error: unknown) => {
			await sleep(delayMs);
			throw error;
		},
	);
}
