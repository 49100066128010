import { useCallback, useMemo } from 'react';
import { useSuspenseQueries } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { AnnouncementForm } from '@apple/features/announcements/components/AnnouncementForm';
import { useAnnouncementManagement } from '@apple/features/announcements/hooks/useAnnouncementManagement';
import { announcementQueries } from '@apple/features/announcements/queries/management';
import { requireAuth } from '@apple/features/auth';
import { useTranslation } from '@apple/lib/i18next';
import { DetailsLayout } from '@apple/ui/layouts';
import { NotFoundPage } from '@apple/ui/shell/pages/NotFoundPage';
import { NotAuthorizedError } from '@apple/utils/api/errors';
import type { Announcement } from '@apple/features/announcements/models/management';

export const Route = createFileRoute('/_authed/_admin/content/announcements_/$announcementId')({
	component: ManageAnnouncementRoute,
	errorComponent: ({ error }) => {
		if (error instanceof NotAuthorizedError) {
			return <NotFoundPage />;
		} else {
			throw error;
		}
	},
	parseParams: ({ announcementId }) => ({
		announcementId: z.string().parse(announcementId),
	}),
	beforeLoad: ({ params: { announcementId }, ...args }) => {
		requireAuth(args);

		return {
			announcementQueryOptions: announcementQueries.getAnnouncementById(announcementId),
			announcementsQueryOptions: announcementQueries.getAllAnnouncements(),
		};
	},
	loader: ({ context: { queryClient, announcementQueryOptions, announcementsQueryOptions } }) => {
		void queryClient.prefetchQuery(announcementQueryOptions);
		void queryClient.prefetchQuery(announcementsQueryOptions);
	},
	wrapInSuspense: true,
});

function ManageAnnouncementRoute() {
	const { t } = useTranslation('content');
	const { announcementQueryOptions, announcementsQueryOptions } = Route.useRouteContext();
	const [announcementQuery, announcementsQuery] = useSuspenseQueries({
		queries: [announcementQueryOptions, announcementsQueryOptions],
	});
	const { edit, remove } = useAnnouncementManagement();

	const navigate = Route.useNavigate();

	const handleEdit = useCallback(
		async (announcement: Announcement) => {
			await edit.mutateAsync(announcement, {
				onSuccess: () => {
					void navigate({
						to: '/content/announcements',
					});
				},
			});
		},
		[edit, navigate],
	);

	const handleRemove = useCallback(
		async (announcementId: string) => {
			await remove.mutateAsync(announcementId, {
				onSuccess: () => {
					void navigate({
						to: '/content/announcements',
					});
				},
			});
		},
		[navigate, remove],
	);

	const activeAllowed = useMemo(() => {
		const activeAnnouncement = announcementsQuery.data.find(x => x.active);

		if (!activeAnnouncement) {
			return true;
		}

		return activeAnnouncement.contentGroupId === announcementQuery.data.contentGroupId;
	}, [announcementQuery.data.contentGroupId, announcementsQuery.data]);

	return (
		<DetailsLayout title={t('announcements.edit.title')}>
			<AnnouncementForm
				announcement={announcementQuery.data}
				isEdit
				activeAllowed={activeAllowed}
				loading={edit.isPending || remove.isPending}
				submitted={edit.isSuccess || remove.isSuccess}
				onSave={handleEdit}
				onDelete={handleRemove}
			/>
		</DetailsLayout>
	);
}
