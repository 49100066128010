import { z } from 'zod';

import { emptyOrNullableLocalDateTimeSchema } from '@apple/utils/globalization';

export type Customer = z.infer<typeof customerSchema>;

/** Manzanita.Web.Models.CustomerGroupModel */
export interface CustomerGroupModel {
	id: number;
	name: string;
	code: string | null;
	status: string | null;
	countries: string[];
	logoImageUrl: string | null;
	activeDate: string | null;
	inactiveDate: string | null;
	isDeleted: boolean;
	description: string | null;
}

export const customerIdSchema = z.number();

export const customerSchema = z.object({
	id: customerIdSchema.optional(),
	name: z.string().min(1),
	storeNumber: z.string(),
	streetAddress1: z.string(),
	streetAddress2: z.string().optional(),
	streetAddress3: z.string().optional(),
	city: z.string(),
	stateOrProvince: z.string(),
	postalCode: z.string(),
	countryCode: z.string(),
	arvatoCustomerCode: z.string().min(1),
	customerGroupId: z.number().min(1),
	customerClassificationId: z.number(),
	isDeleted: z.boolean(),
	activeFrom: emptyOrNullableLocalDateTimeSchema,
	activeUntil: emptyOrNullableLocalDateTimeSchema,
});

/** Manzanita.Web.Models.CustomerGroupModel */
export const customerGroupSchema = z.object({
	id: z.number(),
	name: z.string(),
	code: z.string().nullable(),
	status: z.string().nullable(),
	countries: z.array(z.string()),
	logoImageUrl: z.string().nullable(),
	activeDate: z.string().nullable(),
	inactiveDate: z.string().nullable(),
	isDeleted: z.boolean(),
	description: z.string().nullable(),
}) satisfies z.ZodType<CustomerGroupModel>;
