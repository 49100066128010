import { useCallback, useState } from 'react';
import { Box, Center, Checkbox, Group, Space, Text, Title, Tooltip } from '@mantine/core';
import { useSet } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { keepPreviousData, queryOptions, useMutation, useQueries } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import {
	getCoreRowModel,
	getFacetedMinMaxValues,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table';
import dayjs from 'dayjs';

import { icons } from '@apple/assets';
import { requireAuth } from '@apple/features/auth';
import { getApprovalOrders } from '@apple/features/order-channel/api/approval';
import { approveOrders, rejectOrders } from '@apple/features/order/api/approval';
import { OrderCommentStatus } from '@apple/features/order/models/status';
import { getPlants } from '@apple/features/plants/api/api';
import { programsQueryOptions } from '@apple/features/program/queries';
import { useTranslation } from '@apple/lib/i18next';
import { DataTable, useTableState } from '@apple/ui/data-table';
import { ToolbarButton } from '@apple/ui/data-table/controls/Toolbar';
import { TableLayout } from '@apple/ui/layouts';
import { Link } from '@apple/ui/link';
import {
	FormattedDate,
	FormattedPrice,
	globalizationQueryOptions,
} from '@apple/utils/globalization';
import type {
	OrderApprovalFilters,
	OrderApprovalListing,
	OrderApprovalReason,
} from '@apple/features/order-channel/models/approval';
import type { ApproveOrdersRequest } from '@apple/features/order/models/approval';
import type { CurrencyCode } from '@apple/utils/globalization';

export const Route = createFileRoute('/_authed/_admin/approvals/orders')({
	component: OrderApprovalRoute,
	beforeLoad: args => {
		requireAuth(args, {
			requirePermission: 'Manzanita.Security.Features.OrderManagement.Read',
		});

		return {
			approvalOrdersQueryOptions: queryOptions({
				queryKey: ['approval-orders'],
				queryFn: () => getApprovalOrders(),
				placeholderData: keepPreviousData,
				throwOnError: true,
				initialData: [],
			}),
			plantsQueryOptions: queryOptions({
				queryKey: ['plants'],
				queryFn: () => getPlants(),
				placeholderData: keepPreviousData,
				throwOnError: true,
				staleTime: Infinity,
			}),
			countriesWithCulturesQueryOptions: globalizationQueryOptions.countriesWithCultures(),
			programsQueryOptions: programsQueryOptions(),
		};
	},
	loader: ({
		context: {
			queryClient,
			approvalOrdersQueryOptions,
			plantsQueryOptions,
			countriesWithCulturesQueryOptions,
			programsQueryOptions,
		},
	}) => {
		void queryClient.prefetchQuery(approvalOrdersQueryOptions);
		void queryClient.prefetchQuery(plantsQueryOptions);
		void queryClient.prefetchQuery(countriesWithCulturesQueryOptions);
		void queryClient.prefetchQuery(programsQueryOptions);
	},
});

function getSelectedItems(
	data: OrderApprovalListing[] | undefined,
	selectedOrderNumbers: Set<string>,
) {
	return data?.filter(x => selectedOrderNumbers.has(x.purchaseOrderNumber)) ?? [];
}

function getApprovalReasonIcon(
	reasonKey: OrderApprovalReason,
	reasonKeys: string[],
	reasonTexts: string[],
) {
	const iconSize = '23px';
	const reasonIndex = reasonKeys.indexOf(reasonKey);
	let reasonIcon = <Space w={iconSize} />;
	if (reasonIndex === -1) return reasonIcon;
	const reasonText = reasonIndex === -1 ? '' : reasonTexts[reasonIndex];

	switch (reasonKey) {
		case 'ApprovalReasonItemQuantityLimit':
			reasonIcon = <icons.QuantityLimitExceeded size={iconSize} c='yellow.7' />;
			break;
		case 'RequiresAdminApproval':
			reasonIcon = <icons.ApprovalRequired size={iconSize} />;
			break;
		case 'ApprovalReasonExpeditedShipping':
			reasonIcon = <icons.ExpeditedShippingIcon size={iconSize} c='blue.7' />;
			break;
		case 'ApprovalReasonOrderValueLimit':
			reasonIcon = <icons.OrderValueExceeded size={iconSize} c='green.7' />;
			break;
	}

	return (
		<Tooltip
			transitionProps={{ transition: 'fade-up', duration: 200 }}
			label={<Text style={{ whiteSpace: 'pre-wrap' }}>{reasonText}</Text>}
		>
			<span>{reasonIcon}</span>
		</Tooltip>
	);
}

function OrderApprovalRoute() {
	const { t } = useTranslation(['order-approval', 'order'], {
		nsMode: 'fallback',
	});
	const { approvalOrdersQueryOptions } = Route.useRouteContext();

	const [ordersQuery, countriesQuery] = useQueries({
		queries: [approvalOrdersQueryOptions, globalizationQueryOptions.countries],
	});
	const [isCustomDateRange, setIsCustomDateRange] = useState(false);
	const search = Route.useSearch();
	const navigate = Route.useNavigate();
	const selectedOrderNumbers = useSet<string>();

	const defaultFilters: OrderApprovalFilters = {
		orderDateRangeInDays: 60,
	};
	const tableState = useTableState<OrderApprovalListing, OrderApprovalFilters>({
		search,
		navigate,
		defaultFilters,
		defaultSorting: [{ id: 'orderDate', desc: true }],
		fieldMap: [],
	});

	const showResultDialog = useCallback(
		(approve: boolean, failed: boolean, orderNumbers: string[]) => {
			const orderNumberList = orderNumbers.join(', ');

			const message = failed
				? approve
					? t('approveOrders.failed', {
							orderNumbers: orderNumberList,
						})
					: t('rejectOrders.failed', {
							orderNumbers: orderNumberList,
						})
				: approve
					? t('approveOrders.success', {
							count: orderNumbers.length,
						})
					: t('rejectOrders.success', {
							count: orderNumbers.length,
						});
			modals.open({
				title: (
					<Title order={5}>
						{approve ? t('approveOrders.title') : t('rejectOrders.title')}
					</Title>
				),
				children: (
					<Center py='md'>
						<Text>{message}</Text>
					</Center>
				),
			});
		},
		[t],
	);

	const approveOrdersMutation = useMutation({
		mutationFn: approveOrders,
		onSuccess: async response => {
			const failed = response.failedOrderNumbers.length > 0;
			const orderNumbers = failed
				? response.failedOrderNumbers
				: response.successfulOrderNumbers;
			showResultDialog(true, failed, orderNumbers);
			selectedOrderNumbers.clear();

			await ordersQuery.refetch();
		},
		onError: () => {
			selectedOrderNumbers.clear();
			notifications.show({
				color: 'red',
				message: t('common:error.generic'),
				icon: <icons.ErrorIcon />,
			});
		},
	});

	const rejectOrdersMutation = useMutation({
		mutationFn: rejectOrders,
		onSuccess: async response => {
			const failed = response.failedOrderNumbers.length > 0;
			const orderNumbers = failed
				? response.failedOrderNumbers
				: response.successfulOrderNumbers;
			showResultDialog(false, failed, orderNumbers);
			selectedOrderNumbers.clear();

			await ordersQuery.refetch();
		},
		onError: () => {
			selectedOrderNumbers.clear();
			notifications.show({
				color: 'red',
				message: t('common:error.generic'),
				icon: <icons.ErrorIcon />,
			});
		},
	});

	const handleApproveOrders = useCallback(() => {
		const items = getSelectedItems(ordersQuery.data, selectedOrderNumbers);
		const request = {
			orderNumbers: items.map(x => ({
				purchaseOrderNumber: x.purchaseOrderNumber,
				salesOrderNumber: x.salesOrderNumber,
			})),
			purchaseOrderUpdate: null,
		} satisfies ApproveOrdersRequest;

		modals.openConfirmModal({
			title: <Title order={5}>{t('approveOrders.title')}</Title>,
			children: (
				<Center py='md'>
					<Text>
						{t('approveOrders.message', {
							count: items.length,
						})}
					</Text>
				</Center>
			),
			labels: {
				confirm: t('common:buttons.confirm'),
				cancel: t('common:buttons.cancel'),
			},
			onConfirm: () => approveOrdersMutation.mutate(request),
			closeOnClickOutside: false,
		});
	}, [approveOrdersMutation, ordersQuery.data, selectedOrderNumbers, t]);

	const handleRejectOrders = useCallback(() => {
		const items = getSelectedItems(ordersQuery.data, selectedOrderNumbers);
		const request = {
			orderNumbers: items.map(x => ({
				purchaseOrderNumber: x.purchaseOrderNumber,
				salesOrderNumber: x.salesOrderNumber,
			})),
		};

		modals.openConfirmModal({
			title: <Title order={5}>{t('rejectOrders.title')}</Title>,
			children: (
				<Center py='md'>
					<Text>
						{t('rejectOrders.message', {
							count: items.length,
						})}
					</Text>
				</Center>
			),
			labels: {
				confirm: t('common:buttons.confirm'),
				cancel: t('common:buttons.cancel'),
			},
			onConfirm: () => rejectOrdersMutation.mutate(request),
			closeOnClickOutside: false,
		});
	}, [ordersQuery.data, rejectOrdersMutation, selectedOrderNumbers, t]);

	const table = useReactTable({
		data: ordersQuery.data,
		...tableState,
		enableFilters: true,
		enableColumnFilters: true,
		enableSorting: true,
		enableRowSelection: true,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: (table, columnId) => {
			switch (columnId) {
				case 'orderDateRangeInDays':
					return () =>
						new Map([
							['30', 1],
							['60', 1],
							['90', 1],
							['custom', 1],
						]);
				case 'commentStatus':
					return () =>
						new Map([
							['1', 1],
							['2', 1],
						]);
				case 'approvalReason':
					return () =>
						new Map([
							['ApprovalReasonItemQuantityLimit', 1],
							['RequiresAdminApproval', 1],
							['ApprovalReasonExpeditedShipping', 1],
							['ApprovalReasonOrderValueLimit', 1],
							['ApprovalReasonRequiresReview', 1],
						] as [OrderApprovalReason, number][]);
				case 'countryCode':
					return () => new Map(countriesQuery.data?.map(x => [x.Key, 1]));
				default:
					return getFacetedUniqueValues<OrderApprovalListing>()(table, columnId);
			}
		},
		getFacetedMinMaxValues: getFacetedMinMaxValues(),
		columnResizeMode: 'onChange',
		columns: [
			{
				id: 'selectRowCheckbox',
				size: 50,
				enableColumnFilter: false,
				enableGlobalFilter: false,
				enableSorting: false,
				enableMultiSort: false,
				enableHiding: false,
				enableGrouping: false,
				header: ({ table }) => {
					const pageRows = table.getPaginationRowModel().rows;
					const allSelected =
						pageRows.length > 0 &&
						pageRows.every(row =>
							selectedOrderNumbers.has(row.original.purchaseOrderNumber),
						);
					const someSelected = pageRows.some(row =>
						selectedOrderNumbers.has(row.original.purchaseOrderNumber),
					);

					return (
						<Checkbox
							aria-label={t('tooltip.selectAllRows')}
							checked={allSelected}
							indeterminate={!allSelected && someSelected}
							onChange={event => {
								if (event.currentTarget.checked) {
									pageRows.forEach(row => {
										selectedOrderNumbers.add(row.original.purchaseOrderNumber);
									});
									return;
								}
								pageRows.forEach(row => {
									selectedOrderNumbers.delete(row.original.purchaseOrderNumber);
								});
							}}
						/>
					);
				},
				cell: ({ row }) => (
					<Checkbox
						aria-label={t('tooltip.selectRow')}
						checked={selectedOrderNumbers.has(row.original.purchaseOrderNumber)}
						onChange={event =>
							event.currentTarget.checked
								? selectedOrderNumbers.add(row.original.purchaseOrderNumber)
								: selectedOrderNumbers.delete(row.original.purchaseOrderNumber)
						}
					/>
				),
			},
			{
				accessorKey: 'approvalReason',
				header: '',
				enableColumnFilter: true,
				filter: {
					group: t('filters.groups.labels.allFilters'),
					label: t('headers.reasonsForApproval'),
					variant: 'select',
					getFilterDisplayValue: value =>
						t(`approvalReasons.${value as OrderApprovalReason}`),
				},
				filterFn: (row, _columnId, filterValue) => {
					if (!filterValue) return true;

					return row.original.approvalReasons.some(x => String(filterValue).includes(x));
				},
				cell: ({ row }) => (
					<Group justify='flex-start' wrap='nowrap' gap={0}>
						{getApprovalReasonIcon(
							'ApprovalReasonExpeditedShipping',
							row.original.approvalReasons,
							row.original.reasonsForApprovalList,
						)}

						{getApprovalReasonIcon(
							'ApprovalReasonOrderValueLimit',
							row.original.approvalReasons,
							row.original.reasonsForApprovalList,
						)}

						{getApprovalReasonIcon(
							'ApprovalReasonItemQuantityLimit',
							row.original.approvalReasons,
							row.original.reasonsForApprovalList,
						)}

						{getApprovalReasonIcon(
							'RequiresAdminApproval',
							row.original.approvalReasons,
							row.original.reasonsForApprovalList,
						)}

						{row.original.hasComments && (
							<Tooltip.Group>
								<Tooltip
									transitionProps={{ transition: 'fade-up', duration: 200 }}
									label={
										row.original.orderCommentStatus === OrderCommentStatus.Sent
											? t('tooltip.awaitingResponse')
											: t('tooltip.responseReceived')
									}
								>
									<Box component='span'>
										{row.original.orderCommentStatus ===
										OrderCommentStatus.Sent ? (
											<icons.AdminOpenQuestion
												c='blue'
												data-testid={`has-comment-${row.original.purchaseOrderNumber}`}
											/>
										) : (
											<icons.UserRepliedQuestion
												c='bright'
												data-testid={`has-comment-${row.original.purchaseOrderNumber}`}
											/>
										)}
									</Box>
								</Tooltip>
							</Tooltip.Group>
						)}
					</Group>
				),
			},
			{
				accessorKey: 'purchaseOrderNumber',
				header: t('headers.orderNumber'),
				filter: {
					group: t('filters.groups.labels.allFilters'),
					variant: 'text',
				},
				cell: ({ row }) => (
					<Link
						to='/approvals/orders/$poNumber'
						params={{ poNumber: row.original.purchaseOrderNumber }}
						title={t('order:searchResults.table.links.viewOrderDetails')}
						children={row.original.purchaseOrderNumber}
					/>
				),
				filterFn: (row, _columnId, filterValue) => {
					if (!filterValue) return true;

					return (
						row.original.purchaseOrderNumber.includes(filterValue as string) ||
						row.original.salesOrderNumber.includes(filterValue as string)
					);
				},
			},
			{
				accessorKey: 'salesOrderNumber',
				header: t('headers.salesOrderNumber'),
				enableColumnFilter: false,
			},
			{
				id: 'orderDateRangeInDays',
				accessorKey: 'orderDateRangeInDays',
				header: t('headers.orderDate'),
				enableColumnFilter: true,
				filterFn: (row, _columnId, filterValue) => {
					setIsCustomDateRange(filterValue === 'custom');

					if (!filterValue || tableState.currentFilterData.purchaseOrderNumber)
						return true;

					let minDate: dayjs.Dayjs;
					switch (String(filterValue)) {
						case '30':
						case '60':
						case '90':
							minDate = dayjs().subtract(Number(filterValue), 'days');
							break;
						case 'custom':
							return true;
						default:
							return true;
					}

					const orderDate = dayjs(row.original.orderDate);
					return orderDate >= minDate;
				},
				filter: {
					group: t('filters.groups.labels.allFilters'),
					variant: 'select',
					getFilterDisplayValue: value =>
						value === 'custom'
							? t('controls:dataTable.filters.options.dateRange.custom')
							: t('controls:dataTable.filters.options.dateRange.lastNumberOfDays', {
									days: value,
								}),
				},
				meta: {
					hideColumn: true,
				},
			},

			{
				id: 'startDate',
				accessorKey: 'startDate',
				header: t('headers.startDate'),
				enableColumnFilter: isCustomDateRange,
				filterFn: (row, _columnId, filterValue) => {
					if (!filterValue || tableState.currentFilterData.purchaseOrderNumber)
						return true;

					const minDate = dayjs(filterValue as string);
					const orderDate = dayjs(row.original.orderDate);
					return orderDate >= minDate;
				},
				filter: {
					group: t('filters.groups.labels.allFilters'),
					variant: 'date',
				},
				meta: {
					hideColumn: true,
				},
			},
			{
				id: 'endDate',
				accessorKey: 'endDate',
				header: t('headers.endDate'),
				enableColumnFilter: isCustomDateRange,
				filterFn: (row, _columnId, filterValue) => {
					if (!filterValue || tableState.currentFilterData.purchaseOrderNumber)
						return true;

					const maxDate = dayjs(filterValue as string);
					const orderDate = dayjs(row.original.orderDate);
					return orderDate < maxDate;
				},
				filter: {
					group: t('filters.groups.labels.allFilters'),
					variant: 'date',
				},
				meta: {
					hideColumn: true,
				},
			},
			{
				accessorKey: 'orderDate',
				header: t('headers.orderDate'),
				enableColumnFilter: false,
				enableSorting: true,
				sortingFn: 'datetime',
				cell: ({ row }) => <FormattedDate value={row.original.orderDate} humanize />,
			},
			{
				accessorKey: 'customerGroupName',
				header: t('headers.program'),
				filter: {
					group: t('filters.groups.labels.allFilters'),
					variant: 'select',
				},
			},
			{
				accessorKey: 'plantName',
				header: t('headers.plant'),
				filter: {
					group: t('filters.groups.labels.allFilters'),
					variant: 'select',
				},
			},
			{
				accessorKey: 'countryCode',
				header: t('headers.country'),
				filter: {
					group: t('filters.groups.labels.allFilters'),
					variant: 'multi-select',
					getFilterDisplayValue: value =>
						countriesQuery.data?.find(x => x.Key === value)?.Value ?? String(value),
				},
				filterFn: (row, _columnId, filterValue: Array<string>) => {
					return (
						filterValue.length === 0 || filterValue.includes(row.original.countryCode)
					);
				},
				cell: ({ row }) => {
					return (
						countriesQuery.data?.find(x => x.Key === row.original.countryCode)?.Value ??
						row.original.countryCode
					);
				},
			},
			{
				accessorKey: 'appleId',
				header: t('headers.appleId'),
				filter: {
					group: t('filters.groups.labels.allFilters'),
					variant: 'text',
				},
			},
			{
				accessorKey: 'orderCommentStatus',
				header: t('headers.orderCommentStatus'),
				filter: {
					group: t('filters.groups.labels.allFilters'),
					variant: 'select',
					getFilterDisplayValue: value =>
						t(`order:orderCommentStatus.${value as string}`),
				},
				filterFn: (row, _columnId, filterValue) => {
					if (!filterValue) return true;

					if (
						Number(filterValue as string) ===
							row.original.orderCommentStatus.valueOf() &&
						row.original.hasComments
					)
						return true;

					return false;
				},
				meta: {
					hideColumn: true,
				},
			},
			{
				accessorKey: 'totalPrice',
				header: t('headers.totalPrice'),
				enableColumnFilter: false,
				// 	align: 'right',
				cell: ({ row }) => (
					<FormattedPrice
						value={row.original.totalPrice}
						currency={row.original.usersCurrencyCode as CurrencyCode}
					/>
				),
			},
			{
				accessorKey: 'totalPriceUSD',
				header: t('headers.totalPriceUSD'),
				enableColumnFilter: false,
				// align: 'right',
				cell: ({ row }) => (
					<FormattedPrice value={row.original.totalPriceUSD} currency={'USD'} />
				),
			},
		],
	});

	// TODO: Populate the filter unique values from the server (plantsQuery, countriesWithCulturesQuery, programsQuery)
	return (
		<TableLayout
			title={t('title')}
			table={table}
			toolbarButtons={[
				<ToolbarButton
					key='approve'
					tooltip={t('buttons.approveSelected')}
					icon={icons.Success}
					color='green'
					onClick={handleApproveOrders}
					disabled={selectedOrderNumbers.size <= 0}
				/>,
				<ToolbarButton
					key='reject'
					tooltip={t('buttons.rejectSelected')}
					icon={icons.Close}
					color='red'
					onClick={handleRejectOrders}
					disabled={selectedOrderNumbers.size <= 0}
				/>,
			]}
		>
			<DataTable
				table={table}
				loading={ordersQuery.isFetching}
				hideItemCount={false}
				itemCountLabel={t('dataTable.itemCountLabel.orders')}
			/>
		</TableLayout>
	);
}
